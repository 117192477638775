<template>
    <div>
        <div class="form-group mb-4 row">
            <label class="form-label col-3 col-form-label">Enable CTA</label>
            <div class="col">
                <label class="form-check form-switch mt-2">
                    <input class="form-check-input" type="checkbox" :checked="isCtaEnabled"
                        @change="handleCtaEnabledChange" :disabled="isDisabled" />
                </label>
            </div>
        </div>

        <div class="form-group mb-4 row" v-if="isCtaEnabled">
            <label class="form-label col-3 col-form-label">
                CTA Text <span class="text-red">*</span>
            </label>
            <div class="col">
                <input class="form-control" :class="{ 'is-invalid': formattedErrors?.cta_text }" type="text" :value="ctaText"
                    @input="emit('update:ctaText', $event.target.value)" :disabled="isDisabled" />
                <small class="invalid-feedback" v-if="formattedErrors?.cta_text">
                    {{ formattedErrors.cta_text }}
                </small>
            </div>
        </div>

        <div class="form-group mb-4 row" v-if="isCtaEnabled">
            <label class="form-label col-3 col-form-label">CTA External Link</label>
            <div class="col">
                <label class="form-check form-switch mt-2">
                    <input class="form-check-input" type="checkbox" :checked="isCtaExternal"
                        @change="handleCtaExternalChange" :disabled="isDisabled" />
                </label>
            </div>
        </div>

        <div class="form-group mb-4 row" v-if="isCtaEnabled && isCtaExternal">
            <label class="form-label col-3 col-form-label">
                CTA URL <span class="text-red">*</span>
            </label>
            <div class="col">
                <input class="form-control" :class="{ 'is-invalid': formattedErrors?.cta_url }" type="text" :value="ctaUrl"
                    @input="emit('update:ctaUrl', $event.target.value)" :disabled="isDisabled" />
                <small class="invalid-feedback" v-if="formattedErrors?.cta_url">
                    {{ formattedErrors.cta_url }}
                </small>
            </div>
        </div>

        <div class="form-group mb-4 row" v-if="isCtaEnabled && !isCtaExternal">
            <label class="form-label col-3 col-form-label">Navigate To:</label>
            <div class="col">
                <select class="form-control" :value="navigateType"
                    @change="emit('update:navigateType', $event.target.value)" :disabled="isDisabled">
                    <option value="">Select Navigation Type</option>
                    <option v-for="type in navigationTypes" :key="type" :value="type">
                        {{ navigationLabels[type] }}
                    </option>
                </select>
                <small class="text-red" v-if="formattedErrors?.navigate_type">
                    {{ formattedErrors.navigate_type }}
                </small>
            </div>
        </div>

        <div class="form-group mb-4 row"
            v-if="isCtaEnabled && !isCtaExternal && navigateType === NavigationTypeEnum.PRODUCT">
            <label class="form-label col-3 col-form-label">
                {{ navigationLabels[navigateType] }}
            </label>
            <div class="col">
                <ProductOptions :modelValue="navigateId"
                    @update:modelValue="(value) => emit('update:navigateId', value)" :error="formattedErrors?.navigate_id" :disabled="isDisabled" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed } from "vue";
import ProductOptions from "@/components/products/ProductOptions.vue";
import { NavigationTypeEnum, NavigationTypeLabels } from "@/enums/AnnouncementNavigationEnum";

const props = defineProps({
    recipient: Number,
    isCtaEnabled: Boolean,
    ctaText: String,
    isCtaExternal: Boolean,
    ctaUrl: String,
    navigateType: String,
    navigateId: String,
    errors: Array as () => Array<{ $property: string; $message: string } | any>,
    isDisabled: { type: Boolean, default: false }
});

const emit = defineEmits([
    "update:isCtaEnabled",
    "update:ctaText",
    "update:isCtaExternal",
    "update:ctaUrl",
    "update:navigateType",
    "update:navigateId"
]);

const formattedErrors = computed(() => {
    return props.errors?.reduce((acc, error) => {
        acc[error.$property] = error.$message;
        return acc;
    }, {} as Record<string, string>) || {};
});

const handleCtaEnabledChange = (event: Event) => {
    const isEnabled = (event.target as HTMLInputElement).checked;
    emit("update:isCtaEnabled", isEnabled);

    if (!isEnabled) {
        emit("update:ctaText", "");
        emit("update:isCtaExternal", true);
        emit("update:ctaUrl", "");
        emit("update:navigateType", "");
        emit("update:navigateId", "");
    }
};

const handleCtaExternalChange = (event: Event) => {
    const isExternal = (event.target as HTMLInputElement).checked;
    emit("update:isCtaExternal", isExternal);
    emit("update:ctaUrl", "");
    emit("update:navigateType", "");
    emit("update:navigateId", "");
};

const navigationTypes = Object.values(NavigationTypeEnum);
const navigationLabels = NavigationTypeLabels;
</script>

<style>

</style>