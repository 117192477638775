<template>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <form id="update-brands-form" class="rounded" @submit.prevent="updateRows">
                    <table class="table table-vcenter text-nowrap datatable">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th class="text-end">Display To Public</th>
                            </tr>
                        </thead>
                        <tbody v-if="brands.length > 0">
                            <tr v-for="brand in brands" :key="brand.id">
                                <td>{{ brand.name }}</td>
                                <td class="d-flex justify-content-end">
                                    <label class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" v-model="brand.isPublicDisplayed">
                                        <span class="form-check-label">{{ brand.isPublicDisplayed ? 'Display' :  'Hidden' }}</span>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="2" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <tfoot v-if="brands.length > 0">
                            <tr>
                                <td colspan="2" class="text-end">
                                    <button class="btn btn-primary" form="update-brands-form">Save Changes</button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </form>
                <overlay-spinner :is-showing="isLoading"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ShopBrandService from '@/services/ShopBrandService'
import {useNotificationsStore} from '@/stores/notifications'
import Brand from '@/types/Brand'
import {ref} from 'vue'

const {addToastNotification} = useNotificationsStore()

const isLoading = ref<boolean>(false)
const brands = ref<Array<Brand>>([])

const fetchBrands = () => {
    isLoading.value = true

    ShopBrandService.index().then(({data: {data, meta}}) => {
        brands.value = data
    }).catch((error) => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger'
        })
    }).finally(() => isLoading.value = false)
}

const updateRows = async () => {
    isLoading.value = true

    try {
        await ShopBrandService.updateRows(formattedData())

        addToastNotification({
            message: 'Brands is successfully updated.',
            type: 'success',
        })

        fetchBrands()
    } catch {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    } finally {
        isLoading.value = false
    }
}

const formattedData = () => {
    return {
        rows: brands.value.map((brand: Brand, index: number) => ({
            id: brand.id,
            is_public_displayed: brand.isPublicDisplayed
        }))
    }
}

fetchBrands()
</script>