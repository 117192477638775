<template>
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
                <form id="update-brands-form" class="rounded" @submit.prevent="updateRows">
                    <table class="table table-vcenter text-nowrap datatable">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Email CC</th>
                                <th>Email BCC</th>
                                <th class="text-end">Notify Fulfillment</th>
                                <th class="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="vendors.length > 0">
                            <tr v-for="vendor in vendors" :key="vendor.id">
                                <td>{{ vendor.name }}</td>
                                <td>{{ emailDisplay(vendor) }}</td>
                                <td>{{ emailArrayDisplay(vendor.emailCc) }}</td>
                                <td>{{ emailArrayDisplay(vendor.emailBcc) }}</td>
                                <td>
                                    <span class="d-flex justify-content-end">
                                        <label class="form-check form-switch my-auto">
                                            <input class="form-check-input" type="checkbox" v-model="vendor.isNotifyFulfillment">
                                            <span class="form-check-label">{{ vendor.isNotifyFulfillment ? 'Yes' :  'No' }}</span>
                                        </label>
                                    </span>
                                </td>
                                <td class="text-end">
                                    <div class="btn-group">
                                        <button class="btn btn-icon btn-primary" data-bs-toggle="modal" data-bs-target="#vendor-form-modal" @click.prevent="selectedVendor = vendor">
                                            <icon name="pencil" class="text-white" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <tfoot v-if="vendors.length > 0">
                            <tr>
                                <td colspan="5" class="text-end">
                                    <button class="btn btn-primary" form="update-brands-form">Save Changes</button>
                                </td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </form>
                <overlay-spinner :is-showing="isLoading"/>
            </div>
        </div>
    </div>
    <VendorForm :vendor="selectedVendor" @update="fetchVendors"/>
</template>

<script setup lang="ts">
import ShopVendorService from '@/services/ShopVendorService'
import {useNotificationsStore} from '@/stores/notifications'
import Vendor from '@/types/Vendor'
import {ref} from 'vue'
import VendorForm from './VendorForm.vue'

const {addToastNotification} = useNotificationsStore()

const isLoading = ref<boolean>(false)
const vendors = ref<Array<Vendor>>([])
const selectedVendor = ref<Vendor>({
    id: 0,
    name: '',
    isNotifyFulfillment: false,
    createdAt: ''
})

const fetchVendors = () => {
    isLoading.value = true

    ShopVendorService.index().then(({data: {data, meta}}) => {
        vendors.value = data
    }).catch((error) => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger'
        })
    }).finally(() => isLoading.value = false)
}

const emailDisplay = (vendor: Vendor) => {
    if(vendor.email && vendor.emailName) {
        return `${vendor.emailName} (${vendor.email})`
    }

    return 'N/A'
}

const emailArrayDisplay = (emails: Array<string>) => {
    if(emails.length > 0) {
        return emails.join(', ')
    }

    return 'N/A'
}

const updateRows = async () => {
    isLoading.value = true

    let errorVendor = <Vendor|null>null
    vendors.value.forEach((vendor: Vendor) => {
        if(vendor.isNotifyFulfillment && vendor.email == null) {
            errorVendor = vendor
        }
    })

    try {
        if(errorVendor) {
            addToastNotification({
                message: `${errorVendor.name} vendor email must be valid email address for notification.`,
                type: 'danger',
            })
        } else {
            await ShopVendorService.updateRows(formattedData())

            addToastNotification({
                message: 'Vendors is successfully updated.',
                type: 'success',
            })
        }

        fetchVendors()
    } catch {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    } finally {
        isLoading.value = false
    }
}

const formattedData = () => {
    return {
        rows: vendors.value.map((vendor: Vendor, index: number) => ({
            id: vendor.id,
            is_notify_fulfillment: vendor.isNotifyFulfillment
        }))
    }
}

fetchVendors()
</script>