<template>
    <vue-select
      :options="options"
      :reduce="(option:KeyLabel) => option.key"
      label="label"
      :model-value="modelValue"
      @update:modelValue="emit('update:modelValue', $event)"
    />
  </template>
  
<script setup lang="ts">
import { ref, onMounted, defineProps, defineEmits } from "vue";
import NotificationTemplateService from "@/services/NotificationTemplateService";
import KeyLabel from "@/types/KeyLabel";

const props = defineProps({
  modelValue: String,
});

const emit = defineEmits(["update:modelValue"]);
const options = ref<KeyLabel[]>([]);

const fetchOptions = async () => {
  try {
    const res = await NotificationTemplateService.showRecipientCategories();
    options.value = res.data;
  } catch (error) {  
  }
};

onMounted(fetchOptions);
</script>