import {computed, ref} from 'vue'
import MetaPage from '@/types/MetaPage'

export default function useMetaPage() {
    const metaPage = ref<MetaPage>({
        currentPage: 1,
        perPage: 10,
        lastPage: 1,
        from: 1,
        to: 1,
        total: 0
    })
    const metaPageTriggered = ref<number>(0)

    function updateMetaPage(meta: any) {
        // Map snake_case to camelCase and ensure all values are numbers
        metaPage.value = {
            currentPage: Number(meta.current_page || meta.currentPage),
            perPage: Number(meta.per_page || meta.perPage),
            lastPage: Number(meta.last_page || meta.lastPage),
            from: Number(meta.from),
            to: Number(meta.to),
            total: Number(meta.total)
        }
    }

    const updatePerPage = () => {
        metaPage.value.currentPage = 1
        ++metaPageTriggered.value
    }

    function prevPage() {
        if (metaPage.value.currentPage > 1) {
            metaPage.value.currentPage = Number(metaPage.value.currentPage) - 1
            ++metaPageTriggered.value
        }
    }

    function nextPage() {
        if (metaPage.value.currentPage < metaPage.value.lastPage) {
            metaPage.value.currentPage = Number(metaPage.value.currentPage) + 1
            ++metaPageTriggered.value
        }
    }

    function gotoPage(page: number) {
        if (page >= 1 && page <= metaPage.value.lastPage && metaPage.value.currentPage !== page) {
            metaPage.value.currentPage = Number(page)
            ++metaPageTriggered.value
        }
    }

    const hasNextPage = computed(() => metaPage.value.currentPage < metaPage.value.lastPage)
    const hasPrevPage = computed(() => metaPage.value.currentPage > 1)

    return {
        metaPage,
        metaPageTriggered,
        hasNextPage,
        hasPrevPage,
        updateMetaPage,
        updatePerPage,
        prevPage,
        nextPage,
        gotoPage,
    }
}