<template>
    <div class="d-flex flex-wrap gap-3 mb-3">
        <stats-card v-for="(menu, index) in menus" :key="index"
                    :title="menu.name"
                    :stats="menu.count ?? 0"
                    class="cursor-pointer"
                    :card-class="`flex-grow-1`"
                    :is-highlight="isMenuActive(menu.name)"
                    @click="changeMenu(menu.name)">
            <template v-slot:icon>
                <span class="text-center rounded-circle pt-2"
                      :class="[ isMenuActive(menu.name) ? menu.activeBgColor : 'bg-light']"
                      style="height:48px; width: 48px"
                >
                    <icon name="headset" :size="32" :stroke-width="1.2" :class="[ isMenuActive(menu.name) ? menu.activeTextColor : 'text-black-50']" />
                </span>
            </template>
        </stats-card>
    </div>

    <ul class="nav row">
        <li class="nav-item col-3 py-1 border-bottom " v-for="subMenu in filteredSubMenus" :key="subMenu.name">
            <a class="nav-link d-flex justify-content-between"
               :class="[ isSubMenuActive(subMenu.name) ? 'bg-orange-lt' : '']"
               role="button" @click="changeSubMenu(subMenu.name)"
            >
                <span class="text-capitalize">{{ subMenuLabel(subMenu.name) }} </span>
                <small class="badge badge-pill bg-danger-lt ms-2">{{ subMenu.count ?? 0 }}</small>
            </a>
        </li>
    </ul>

    <div class="py-4 space-y-3">
        <div class="btn-group" role="group" v-if="filteredTicketTypes.length > 1">
            <template v-for="ticketType in filteredTicketTypes" :key="ticketType.name">
                <input type="radio" class="btn-check"
                       name="channel-type"
                       :id="`ticket-type-${ticketType.name}`" autocomplete="off"
                       @click="setTicketType(ticketType.name)">
                <label :for="`ticket-type-${ticketType.name}`" type="button" class="btn" :class="{ 'btn-primary': currentTicketType.name === ticketType.name }">
                    <span class="fw-normal">{{ getTitle(ticketType.name) }}</span>
                    <span class="badge badge-pill bg-red ms-2">{{ ticketType.count.new }}</span>
                </label>
            </template>
        </div>
        <ticket-index :ticket-type="currentTicketType" />
    </div>
</template>

<script setup lang="ts">
import StatsCard from '@/components/StatsCard.vue'
import TicketService from '@/services/TicketService'
import { computed, provide, ref, watch } from 'vue'
import TicketIndex from '@/pages/tickets/TicketIndex.vue'
import { storeToRefs } from 'pinia'
import { useTicketStore } from '@/stores/ticket'
import { getTitle } from '@/composable/useTicket'
import {TicketType} from "@/enums/TicketTypeEnum";


const ticketStore = useTicketStore()
const { currentTicketType, ticketTypes } = storeToRefs(ticketStore)

const currentMenu = ref<string>('customer support')
const menus = ref<any[]>([
    { name: 'customer support', activeBgColor: 'bg-orange-lt', activeTextColor: 'text-orange' },
    { name: 'carer support', activeBgColor: 'bg-cyan-lt', activeTextColor: 'text-cyan' },
    { name: 'recruitment', activeBgColor: 'bg-pink-lt', activeTextColor: 'text-pink' },
])

const currentSubMenu = ref<string>('customer_support')
const subMenus = ref<any>({
    'customer support': [
        { name: 'customer_support', ticketTypes: ['customer_support'] },
        { name: 'carer_support', ticketTypes: ['carer_support'] },
        { name: TicketType.REQUEST_FOR_CALL, ticketTypes: [TicketType.REQUEST_FOR_CALL] },
        { name: 'after_payment', ticketTypes: ['job_carer_cancelled', TicketType.SESSION_NO_REPLACEMENT_CARER, 'job_replacement_carer'] },
        { name: 'after_service', ticketTypes: ['session_review'] },
        { name: TicketType.CUSTOMER_DECLINED_SESSION_DOCUMENT, ticketTypes: [TicketType.CUSTOMER_DECLINED_SESSION_DOCUMENT] },
    ],
    'carer support': [
        { name: TicketType.CARER_LOG_INCIDENT, ticketTypes: [TicketType.CARER_LOG_INCIDENT] },
    ],
    'recruitment': [
        { name: 'registration', ticketTypes: ['carer_registration'] },
        { name: 'training_almost_completed', ticketTypes: [TicketType.CARER_TRAINING_ALMOST_COMPLETED] },
        { name: 'profile_update', ticketTypes: ['carer_profile_update'] },
        { name: 'video_upload', ticketTypes: ['carer_video_upload_success', 'carer_video_upload_error'] },
    ]
})

const filteredSubMenus = computed(() => {
    return subMenus.value[currentMenu.value]
})

const filteredTicketTypes = computed(() => {
    return ticketTypes.value.filter((ticketType: any) => filteredSubMenus.value
        .find((subMenu: any) => subMenu.name === currentSubMenu.value).ticketTypes
        .includes(ticketType.name)
    )
})

const subMenuLabel = (name: string) => {
    return name.replaceAll('_', ' ')
}

const isMenuActive = (menu: string) => {
    return menu === currentMenu.value
}

const isSubMenuActive = (subMenu: string) => {
    return subMenu === currentSubMenu.value
}

watch(
    () => ticketTypes.value,
    () => {
        if (!currentTicketType.value.name) {
            setTicketType()
        }
        calculateNewTickets()
    }
)

fetchCount()

function fetchCount() {
    TicketService.count()
        .then(({ data: { data } }) => {
            ticketTypes.value = Object.entries(data).map((ticketType) => ({
                name: ticketType[0],
                count: ticketType[1]
            }))
        })
        .catch()
        .finally()
}

function changeMenu(menu: string) {
    if (menu === currentMenu.value) return

    currentMenu.value = menu
    changeSubMenu(filteredSubMenus.value[0].name)
}

function changeSubMenu(subMenu: string) {
    if (subMenu === currentSubMenu.value) return

    currentSubMenu.value = subMenu
    currentTicketType.value = filteredTicketTypes.value[0]
}

function setTicketType(name = null) {
    currentTicketType.value = name ? ticketTypes.value.find(ticketType => ticketType.name === name) : filteredTicketTypes.value[0]
}

function calculateNewTickets() {
    // submenu count
    Object.keys(subMenus.value).forEach((menu: any) => {
        subMenus.value[menu] = subMenus.value[menu].map((subMenu: any) => {
            const newCount = ticketTypes.value.filter((ticketType: any) => subMenu.ticketTypes.includes(ticketType.name))
                .flatMap((data:any) => data.count.new)
                .reduce((acc: number, curr: number) => acc + curr)

            return { ...subMenu, count: newCount }
        })
    })

    // menu count
    menus.value = menus.value.map((menu: any) => {
        const newCount = subMenus.value[menu.name].flatMap((ticketType: any) => ticketType.count)
            .reduce((acc: number, curr: number) => acc + curr)

        return { ...menu, count: newCount }
    })
}

provide('fetchCount', fetchCount)
provide('calculateNewTickets', calculateNewTickets)
</script>
