<template>

<div class="modal fade" id="category-form-modal" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="card">
                <div class="modal-header">
                    <h5 class="modal-title m-0">{{ category.name }} Category</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label class="form-label">Image</label>
                        <div class="upload-container">
                            <ImageInput name="category_image" label="Upload File" v-model="selectedImage"
                                class="d-inline-block" />
                        </div>
                        <ErrorMessage :has-error="!!errors.category_image" :message="errors.category_image || ''" />
                    </div>
                    <!-- Uploaded photo preview -->
                    <div v-if="selectedImage.fileUrl" class="mt-3">
                        <label class="form-label">Preview</label>
                        <img :src="selectedImage.fileUrl" alt="Preview" class="img-fluid mb-3 rounded" />
                    </div>
                    <small v-else class="text-muted">
                        Preview not available
                    </small>

                    <div class="mb-3 text-end">
                        <button class="btn btn-primary" type="button" @click.prevent="submit">Upload</button>
                    </div>

                    <div class="hr-text">Sub-categories</div>

                    <div v-if="category.descendants.length > 0">
                        <RootCategoryList :category="category" />

                        <div class="mb-3 text-end">
                            <button class="btn btn-primary" @click.prevent="emit('updateRows')">Save</button>
                        </div>
                    </div>
                    <div class="text-center w-full" v-else> No data</div>
                </div>
            </div>
        </div>
    </div>
</div>
    
</template>

<script setup lang="ts">
import RootCategoryList from '@/components/Categories/RootCategoryList.vue';
import Category from '@/types/Category'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import ImageInput from '@/components/form/ImageInput.vue'
import { SelectedUploadImage } from '@/services/SelectedUploadImage'
import { ref, watch } from 'vue';
import ShopCategoryService from '@/services/ShopCategoryService'
import { useNotificationsStore } from '@/stores/notifications'

const { addToastNotification } = useNotificationsStore()

const props = defineProps({
    category: {
        type: Object as () => Category,
        required: true
    }
})

interface Errors {
    category_image: string | null
}

const errors = ref<Errors>({
    category_image: null
})

const selectedImage = ref<SelectedUploadImage>({
    file: null,
    fileUrl: null
})

watch(() => props.category, () => {
    selectedImage.value.file = null
    selectedImage.value.fileUrl = props.category.imageUrl ?? ''
})

const emit = defineEmits(['updateRows'])

const submit = async () => {
    if (selectedImage.value.file) {
        let payload = {
            file: selectedImage.value.file
        }

        try {
            const response = await ShopCategoryService.update(props.category.id, payload)
                .then(() => {
                    addToastNotification({
                        message: `${props.category.name} category's image succesfully uploaded.`,
                        type: 'success',
                    })
                })
                .catch(({ response: { data, status } }) => {
                    if (status === 422) {
                        addToastNotification({
                            message: data.message,
                            type: 'danger',
                        })
                    } else {
                        addToastNotification({
                            message:
                                'Internal server error. Please contact tech team.',
                            type: 'danger',
                        })
                    }
                })
        } catch (error) {
            errors.value.category_image = 'Failed to upload image. Please try again.'
        }
    } else {
        addToastNotification({
            message: 'No image selected.',
            type: 'danger',
        })
    }
}
</script>
