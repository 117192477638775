import httpClientV2 from '@/services/HttpClientV2'
import CommonQuery from '@/types/query/CommonQuery'
import { getQueryString } from '@/composable/http'

const endpoint = '/backoffice/shop/categories'

export default {
    index(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClientV2.get(`${endpoint}?${getQueryString(query)}`)
    },

    update(id: number, data: object = {}) {
        const formData = new FormData()

        formData.append('_method', 'PATCH')

        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                if (typeof value === 'string' || typeof value === 'number') {
                    formData.append(key, value.toString())
                } else if (value instanceof File) {
                    formData.append(key, value)
                }
            }
        })

        return httpClientV2.post(`${endpoint}/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    updateRows(data: object = {}) {
        return httpClientV2.patch(`${endpoint}/update-rows`, data)
    },

    publish() {
        return httpClientV2.post(`${endpoint}/publish`)
    }
}