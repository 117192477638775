import httpClientV2 from '@/services/HttpClientV2'
import CommonQuery from '@/types/query/CommonQuery'
import { getQueryString } from '@/composable/http'

export default {
    index(query: CommonQuery = {}) {
        const queryString = getQueryString(query)

        return httpClientV2.get(`backoffice/shop/brands?${queryString}`)
    },

    updateRows(data: object = {}) {
        return httpClientV2.patch('/backoffice/shop/brands/update-rows', data)
    },
}