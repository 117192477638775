<template>
    <button class="btn btn-light" @click.prevent="goBack">{{ label }}</button>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'

defineProps({
    label: {
        type: String,
        default: 'Back',
        validator: (value: string) => ['Back', 'Cancel'].includes(value)
    }
})

const router = useRouter()

const goBack = () => {
    router.go(-1)
}
</script>
