<template>
    <div class="py-3 px-3">
        <h4>Ticket Details</h4>
        <ul v-if="ticket" class="list-unstyled space-y-2">
            <li class="d-flex justify-content-between">
                <span><icon name="hash" stroke-width="1.2" class="text-muted flex-shrink-0 me-1"/> Ticket ID</span>
                <span class="flex-grow-0">
                    <a :href="viewTicketLink" target="_blank">
                        #{{ ticket?.id }}
                        <icon name="external-link" :stroke-width="1.2" class="text-muted"/>
                    </a>
                </span>
            </li>
            <li class="d-flex justify-content-between">
                <span><icon name="tag" stroke-width="1.2" class="text-muted flex-shrink-0 me-1"/> Type</span>
                <span class="flex-grow-0">
                    <span>{{ getTitle(ticket?.type) }}</span>
                </span>
            </li>
            <li class="d-flex justify-content-between">
                <span><icon name="rosette" stroke-width="1.2" class="text-muted flex-shrink-0 me-1"/> Status</span>
                <template v-if="ticket?.status === 'resolved'">
                    <span class="badge flex-shrink-0 text-capitalize" :class="statusBadge" style="letter-spacing: 0;">
                        {{ ticket?.status }}
                    </span>
                </template>
                <template v-else>
                    <button class="btn badge btn-primary btn-sm text-capitalize rounded" type="button"
                            data-bs-toggle="dropdown" :class="statusBadge" style="letter-spacing: 0">
                        {{ getStatusLabel(ticket?.status) }}
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#" @click.prevent="updateTicketStatus('follow_up')">Follow Up</a>
                        <a class="dropdown-item" href="#" @click.prevent="updateTicketStatus('resolved')">Resolved</a>
                    </div>
                </template>
            </li>
            <li class="d-flex justify-content-between">
                <span>
                    <icon name="user" stroke-width="1.2" class="text-muted flex-shrink-0 me-1"/> Assignee
                </span>
                <span class="flex-grow-0 text-reset">{{ ticket?.assignee?.name ?? 'N/A' }} -
                    <a class="link-text" role="button" @click="updateAssignee(ticket, ticket?.assignee == null)">{{ ticket?.assignee == null ? '' : 'un'}}assign user</a>
                </span>
                
            </li>
            <li class="d-flex justify-content-between">
                <span class="flex-grow-1"><icon name="calendar" stroke-width="1.2"
                                                class="text-muted flex-shrink-0 me-1"/>  Created At</span>
                <span>{{ formatDate(ticket?.createdAt, 'DD MMM YYYY hh:mm A') }}</span>
            </li>
        </ul>
        <ul v-else class="list-unstyled space-y-2">
            <li>Error: Unable to display details</li>
        </ul>
        <h4>Issue</h4>
        <div class="mb-3">{{ ticket?.issue }}</div>
        <h4>Description</h4>
        <div class="mb-3">{{ ticket?.issueDescription }}</div>
        <h4>Remarks <span class="cursor-pointer" data-bs-toggle="modal" :data-bs-target="`#modal-edit-remark-${ticket?.id}`"><icon name="pencil" stroke-width="1.2"/></span></h4>
        <div style="white-space: pre-wrap">{{ ticket?.remark }}</div>
        <hr class="my-3">

        <h4>Upcoming Sessions</h4>
        <template v-if="upcomingSessions.length">
            <ul v-for="session in upcomingSessions">
                <li>{{ session.no }}</li>
            </ul>
        </template>
        <template v-else>-</template>
        <hr class="my-3">

        <ChatDetailAccordion id="accordion-admin"
                             title="Admins"
                             :counter="invitedAdmins.length"
        >
            <ul class="list-group mt-3">
                <template v-if="invitedAdmins.length">
                    <li v-for="admin in invitedAdmins" :key="admin.userId"
                        class="d-flex justify-content-between space-x-2 px-3 list-group-item bg-light text-capitalize text-dark"
                        style="padding-top: 12px; padding-bottom: 12px;">
                        <p class="my-0">{{ admin.name }}</p>
                        <ChatRemoveUserModal :user-id="admin.id"
                                             :user-name="admin.name"
                                             :channel-url="currentChannel?.url"
                                             :channel-name="currentChannel?.name"
                        />
                    </li>
                </template>
            </ul>
            <ChatInviteAdminModal
                :admins="admins"
                :participants="participants"
                :channel-url="currentChannel?.url"
                @admin-invited="emits('adminInvited')"
            />
        </ChatDetailAccordion>
        <Teleport to="body">
            <ticket-edit-remark-modal @update-remark="handleUpdateRemark" :ticket-id="ticket?.id"
                                      :remark="ticket?.remark"></ticket-edit-remark-modal>
        </Teleport>
    </div>
</template>

<script setup lang="ts">
import TicketEditRemarkModal from '@/pages/tickets/TicketEditRemarkModal.vue'
import TicketService from '@/services/TicketService'
import Session from '@/types/Session'
import {computed, inject, onMounted, ref, watch} from 'vue'
import {formatDate} from '@/composable/useDate'
import {useNotificationsStore} from '@/stores/notifications'
import {SENDBIRD_CHANNEL_CUSTOM_TYPE_SUPPORT} from '@/constants'
import ChatDetailAccordion from '@/components/chats/ChatDetailAccordion.vue'
import ChatInviteAdminModal from '@/components/chats/ChatInviteAdminModal.vue'
import User from '@/types/User'
import {Member} from '@sendbird/chat/groupChannel'
import ChatRemoveUserModal from '@/components/chats/ChatRemoveUserModal.vue'
import { TicketStatusEnum } from '@/enums/TicketStatusEnum'
import { SendbirdCustomTypeEnum } from '@/enums/SendbirdCustomTypeEnum'
import { getTitle } from '@/composable/useTicket'

const props = defineProps({
    ticketId: {type: Number, required: true},
    userId: {type: Number, required: true},
    participants: {type: Array as () => Member[], required: true},
    admins: {type: Array as () => User[], required: true},
    upcomingSessions: {type: Array as () => Session[], default: []}
})

const {addToastNotification} = useNotificationsStore()

const emits = defineEmits(['adminInvited'])

const ticket = ref<any>()
const statusBadge = computed(() => {
    switch (ticket.value?.status) {
        case 'new':
            return 'bg-primary'
        case 'attending':
            return 'bg-cyan'
        case 'follow_up':
            return 'bg-yellow'
        case 'resolved':
            return 'bg-success'
        default:
            return 'bg-primary'
    }
})

const currentChannel = inject<any>('currentChannel')

const updateTicketStatus = (status: string) => {
    const data = {
        id: props.ticketId,
        status,
        userId: props.userId,
        customTypeId: SENDBIRD_CHANNEL_CUSTOM_TYPE_SUPPORT
    }

    TicketService.updateStatus(props.ticketId.toString(), data)
        .then(() => {
            ticket.value.status = status

            addToastNotification({
                message: 'This ticket has been resolved',
                type: 'success'
            })
        }).catch(() => {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger'
        })
    })
}

watch(
    () => props.ticketId,
    (newVal) => {
        if (newVal) {
            fetchTicket(newVal)
        } else {
            ticket.value = null
        }
    }
)

onMounted(() => {
    fetchTicket(props.ticketId)
})

function fetchTicket(ticketId: number) {
    if (!isNaN(ticketId)) {
        TicketService.show(ticketId)
            .then(({data}) => {
                ticket.value = data
            })
    }
}

function getStatusLabel(ticketStatus: string) {
    return ticketStatus?.replace('_', ' ')
}

const invitedAdmins = computed(() => {
    return props.admins?.filter((admin: User) => {
        return props.participants?.some((participant: Member) => {
            return parseInt(participant.userId) === admin.id
        })
    })
})

const viewTicketLink = computed(() => {
    return (!isNaN(props.ticketId)) ? `tickets/${props.ticketId}` : ''
})

const updateAssignee = async (ticket: any, isAssign: boolean) => {
    const currentStatus: TicketStatusEnum = isAssign ? TicketStatusEnum.ATTENDING : TicketStatusEnum.NEW

    const customTypeId: SendbirdCustomTypeEnum = SendbirdCustomTypeEnum.ID_SUPPORT

    const payload = {
        customTypeId,
        isAssign,
        status: currentStatus
    }

    try {
        const {data} = await TicketService.updateAssignee(ticket.id, payload)

        if(ticket) {
            ticket.status = data.status
            ticket.assignee = data.assignee
        }
            
        addToastNotification({message: `Ticket assignee has been updated`, type: 'success'})
    } catch (e) {
        console.log(e)
        addToastNotification({
            message: 'Internal server error. Please contact tech team if error persists.',
            type: 'danger'
        })
    }
}

const handleUpdateRemark = (remark: string, id: number) => {
    if (ticket.value.id === id) {
        ticket.value.remark = remark
    }
}
</script>

<style scoped>
hr {
    margin-left: -16px;
    margin-right: -16px;
}
</style>
