<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-end mb-4">
                <button @click="confirmPublish" class="btn btn-primary text-end">
                    <icon name="cloud-up" />
                    Publish Changes
                </button>
            </div>
            <div class="table-responsive">
                <form id="update-categories-form" class="rounded" @submit.prevent="updateRows">
                    <table class="table table-vcenter text-nowrap datatable">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Name</th>
                                <th class="text-end">Display To Public</th>
                                <th class="text-end" width="10%">Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="categories.length > 0">
                            <template v-for="(category,index) in categories" :key="category.id">
                                <tr>
                                    <td>
                                        <ReorderControl  :index="index" 
                                        :totalItems="categories.length" 
                                        @move-up="moveToTop" 
                                        @move-down="moveToBottom" />
                                    </td>
                                    <td>
                                        <div :style="{ marginLeft: `${category.level * 20}px` }">
                                            {{ category.name }}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-end">
                                            <label class="form-check form-switch my-auto">
                                                <input class="form-check-input" type="checkbox" v-model="category.isPublicDisplayed">
                                                <span class="form-check-label">{{ category.isPublicDisplayed ? 'Display' : 'Hidden' }}</span>
                                            </label>
                                        </div>
                                    </td>
                                    <td class="text-end">
                                        <button class="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#category-form-modal" @click.prevent="selectedCategory = category">
                                            <icon name="pencil" class="mx-auto"/>
                                        </button>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="3" class="text-center">No data</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="categories.length > 0" class="d-flex justify-content-end mt-4">
                        <button class="btn btn-primary" form="update-categories-form">Save Changes</button>
                    </div>
                </form>
                <overlay-spinner :is-showing="isLoading"/>
            </div>
        </div>
    </div>
    <CategoryForm :category="selectedCategory" @update-rows="updateRows"/>
</template>

<script setup lang="ts">
import ShopCategoryService from '@/services/ShopCategoryService'
import {useNotificationsStore} from '@/stores/notifications'
import Category from '@/types/Category'
import {ref, computed} from 'vue'
import CategoryForm from './CategoryForm.vue'
import { AxiosError } from 'axios';
import ReorderControl from '@/components/ReorderControl.vue'

const {addToastNotification} = useNotificationsStore()

const isLoading = ref<boolean>(false)
const categories = ref<Array<Category>>([])
const selectedCategory = ref<Category>({
    id: 0,
    name: '',
    imageUrl: '',
    level: 0,
    isPublicDisplayed: false,
    sequence: null,
    createdAt: '',
    updatedAt: '',
    descendants: []
})

const fetchCategories = () => {
    isLoading.value = true

    ShopCategoryService.index().then(({data: {data, meta}}) => {
        categories.value = data
    }).catch((error) => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger'
        })
    }).finally(() => isLoading.value = false)
}

const updateRows = async () => {
    isLoading.value = true

    try {
        await ShopCategoryService.updateRows(formattedData())

        addToastNotification({
            message: 'Categories is successfully updated.',
            type: 'success',
        })

        fetchCategories()
    } catch {
        addToastNotification({
            message: 'Internal server error. Please contact tech team.',
            type: 'danger',
        })
    } finally {
        isLoading.value = false
    }
}

interface FlatCategory extends Category {
    level: number;
}

const formattedCategories = computed(() => {
    const flattenCategories = (categories: Category[], level = 0): FlatCategory[] => {
        return categories.reduce((acc: FlatCategory[], category: Category) => {
            const flatCategory = {
                ...category,
                level
            }
            return [
                ...acc,
                flatCategory,
                ...flattenCategories(category.descendants || [], level + 1)
            ]
        }, [])
    }
    
    return flattenCategories(categories.value)
})


const formattedData = () => {
    let parentOrder = 0;
    return {
        rows: formattedCategories.value.map((category: FlatCategory) => {
            category.level == 0 ? parentOrder++ : parentOrder
            return (
                {
                    id: category.id,
                    is_public_displayed: category.isPublicDisplayed,
                    sequence: category.level == 0 ? parentOrder : null 
                }
            )    
        })
    }
}


const moveToTop = (index: number) => {
    if (index === 0) return null

    let prevItem = categories.value[index - 1]
    let currentItem = categories.value[index]


    categories.value[index - 1] = currentItem
    categories.value[index] = prevItem
}
    
const moveToBottom = (index: number) => {
    if (index === categories.value.length - 1) return null

    let nextItem = categories.value[index + 1]
    let currentItem = categories.value[index]

    categories.value[index + 1] = currentItem
    categories.value[index] = nextItem
}

const publish = async () => {
    try {
        isLoading.value = true
        await ShopCategoryService.publish()

        await window.Swal.fire({
            text: 'Changes have been published successfully',
            showConfirmButton: false,
            timer: 1500
        })

        await fetchCategories()
    } catch (err) {
        let errorMessage = 'Failed to publish changes'

        const error = err as AxiosError | any

        if (error.response?.data?.message) {
            errorMessage = error.response.data.message
        }

        await window.Swal.fire({
            text: errorMessage,
        })
    } finally {
        isLoading.value = false
    }
}

const confirmPublish = async () => {
    const result = await window.Swal.fire({
        text: 'Are you sure you want to publish changes?',
        showCancelButton: true,
        confirmButtonText: 'Yes, publish',
        cancelButtonText: 'Cancel',
    })

    if (result.isConfirmed) {
        await publish()
    }
}

fetchCategories()
</script>