<template>
    <div>
        <div class="row mb-3 align-items-end gap-3 gap-md-0">
            <div class="col-12">
                <h3>Handholding</h3>
            </div>
        </div>
        <div class="stats-container">
            <StatsCard title="Total Kiddocarers" :stats="handholdingStatuses?.totalCount" />
            <StatsCard title="Active Kiddocarers" :stats="handholdingStatuses?.statusCounts.active" />
            <StatsCard title="On Hold Handholding" :stats="handholdingStatuses?.statusCounts.on_hold" />
            <StatsCard title="Inactive Handholding" :stats="handholdingStatuses?.statusCounts.inactive" />
            <StatsCard title="Completed Handholding" :stats="handholdingStatuses?.statusCounts.completed" />
        </div>
    </div>
    <div class="mt-4">
        <h3>Today's Sessions</h3>
        <div class="stats-container">
            <StatsCard title="Upcoming" :stats="todaySessionHanholdingStats?.sessionUpcoming" @click="filterTodaySession(SessionStatusEnum.ID_PAID)" card-class="cursor-pointer" />
            <StatsCard title="In Session" :stats="todaySessionHanholdingStats?.sessionInProgress" @click="filterTodaySession(SessionStatusEnum.ID_IN_SESSION)" card-class="cursor-pointer" />
            <StatsCard title="Completed" :stats="todaySessionHanholdingStats?.sessionCompleted" @click="filterTodaySession(SessionStatusEnum.ID_COMPLETE, SessionStatusEnum.ID_COMPLETE_REVIEW)" card-class="cursor-pointer" />
        </div>
    </div>
    <div class="mt-4">
        <h3>Completed Sessions</h3>
        <div class="stats-container">
            <StatsCard v-for="(sessionCount, key) in todaySessionHanholdingStats?.completedSessionsCount" :key="key" :title="`${key} session`" :stats="sessionCount" @click="filterCompletedCount(key)" card-class="cursor-pointer" />
        </div>
    </div>
    <BasicCard class="mt-4 me-2">
        <overlay-spinner :is-showing="isFetching"></overlay-spinner>
        <div class="row align-items-end mb-4 gap-3 gap-md-0 gy-md-4">
            <div class="col-12 col-md-4">
                <FormLabel label="Carer Online ID" />
                <input class="form-control" type="text" v-model="inputFilter.online_id" />
            </div>
            <div class="col-12 col-md-4">
                <FormLabel label="Carer Name" />
                <input class="form-control" type="text" v-model="inputFilter.name" />
            </div>
            <div class="col-12 col-md-4">
                <FormLabel label="Category" />
                <vue-select
                    :options="categoryOption"
                    :reduce="reduceKey"
                    class="text-capitalize"
                    label="label"
                    v-model="inputFilter.category"
                />
            </div>
            <div class="col-12 col-md-4">
                <FormLabel label="Status" />
                <vue-select
                    :options="statusOptions"
                    :reduce="reduceKey"
                    class="text-capitalize"
                    label="label"
                    v-model="inputFilter.status"    
                />
            </div>
            <div class="col-12 col-md-4">
                <FormLabel label="Start Initiate Handholding Date" />
                <DatePicker id="start_from_date" v-model="inputFilter.start_from_date" :includeTime="false" @on-update="onUpdateStartDate"  />
            </div>
            <div class="col-12 col-md-4">
                <FormLabel label="End Initiate Handholding Date" />
                <DatePicker id="start_to_date" v-model="inputFilter.start_to_date" :includeTime="false" />
            </div>
            <div class="col-12 col-md-4">
                <FormLabel label="Start Completed Handholding Date" />
                <DatePicker id="completed_from_date" v-model="inputFilter.completed_from_date" :includeTime="false" @on-update="onUpdateCompletedDate" />
                
            </div>
            <div class="col-12 col-md-4">
                <FormLabel label="End Completed Handholding Date"/>
                <DatePicker id="completed_to_date" v-model="inputFilter.completed_to_date" :includeTime="false" />
            </div>
            <div class="col-12 col-md-auto ms-auto">
                <button-filter @reset="resetFilter" @filter="fetchData" :is-loading="isFetching"></button-filter>
            </div>
        </div>
        <div class="table-responsive">
            <table-pagination
                :meta-page="metaPage"
                @prev-page="prevPage"
                @next-page="nextPage"
                @goto-page="gotoPage"
                @per-page-updated="updatePerPage"
                :is-showing-per-page="true"
            />
            <table class="table card-table table-vcenter">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Online ID</th>
                        <th>Category</th>
                        <th>Status</th>
                        <th>Town & State</th>
                        <th>Onboarded Date</th>
                        <th>Total Job Applied</th>
                        <th>Total Job Decline</th>
                        <th>Completed Sessions</th>
                        <th>Start Date</th>
                        <th>Completed Date</th>
                        <th>Weeks of Handholding</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody v-if="handholdings.length > 0">
                    <tr v-for="handholding in handholdings" :key="`handholding-${handholding.id}`">
                        <td><router-link :to="{ name: 'carer-show', params: { userId: handholding.user.id } }">{{handholding.user?.name || 'N/A'}}</router-link></td>
                        <td>{{ handholding.user?.mainProfile?.onlineId || 'N/A' }}</td>
                        <td>{{ handholding.previousCarerState }}</td>
                        <td> 
                            <StatusBadge :class="getHandholdingStatusBadge(handholding.status)"
                            :label="getHandholdingStatusLabel(handholding.status)"
                            show
                            />
                        </td>
                        <td>{{ getShortAddress(handholding.user?.primaryAddress) }}</td>
                        <td>{{ formatDate(handholding.user.carer.onboardedAt, 'DD-MMM-YYYY hh:mm A') }}</td>
                        <td>{{ handholding.sessionCarerApplicationsCount }}</td>
                        <td>{{ handholding.sessionCarerRejectedApplicationsCount }}</td>
                        <td>{{ handholding.completedSessionsCount }}</td>
                        <td>{{ formatDate(handholding.createdAt, 'DD-MMM-YYYY hh:mm A') }}</td>
                        <td>{{handholding.completedAt ? formatDate(handholding.completedAt, 'DD-MMM-YYYY hh:mm A') : '-' }}</td>
                        <td>{{ handholding.weekCount ?? '-' }}</td>
                        <td>
                            <router-link
                                :to="{ name: 'carer-handholdings-show', params: { id: handholding.id } }"
                                class="btn btn-icon btn-primary"
                            >
                                <icon name="eye" />
                            </router-link>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td class="text-center" colspan="9">No data</td>
                    </tr>
                </tbody>
            </table>
            <table-pagination
                :meta-page="metaPage"
                @prev-page="prevPage"
                @next-page="nextPage"
                @goto-page="gotoPage"
                @per-page-updated="updatePerPage"
                :is-showing-per-page="false"
            />
        </div>
    </BasicCard>
</template>

<script lang="ts" setup>
import BasicCard from '@/components/BasicCard.vue'
import DatePicker from '@/components/form/DatePicker.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import StatsCard from '@/components/StatsCard.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import { getShortAddress } from '@/composable/useAddress'
import { getHandholdingStatusBadge, getHandholdingStatusLabel } from '@/composable/useCarerHandholding'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import { SessionStatusEnum } from '@/enums/SessionStatusEnum'
import CarerHandholdingService from '@/services/CarerHandholdingService'
import { useNotificationsStore } from '@/stores/notifications'
import CarerHandholding from '@/types/CarerHandholding'
import UserFilter from '@/types/Filter'
import KeyLabel from '@/types/KeyLabel'
import { onMounted, ref, watch } from 'vue'

const {addToastNotification} = useNotificationsStore()

const handholdingStatuses = ref()
const todaySessionHanholdingStats = ref()

const handholdings = ref<CarerHandholding[]>([])

const inputFilter = ref<UserFilter>({
    online_id: null,
    category_id: null,
    name: null,
    category: null,
    status: null,
    start_from_date: null,
    start_to_date: null,
    completed_from_date: null,
    completed_to_date: null,
    today_session_status_ids: null,
    completed_sessions_count: null,
})

const isFetching = ref<boolean>(false)

const fetchTodaySessionStats = async () => {
    try {
        const res = await CarerHandholdingService.todaySessionStats()
        todaySessionHanholdingStats.value = res.data

    } catch (e) {
        addToastNotification({
            message: `Failed to fetch today session. Please try again.`,
            type: 'danger'
        })
    }
}

const fetchStatusStats = async () => {
    try {
        const res = await CarerHandholdingService.statusStats(inputFilter.value)
        handholdingStatuses.value = res.data
    } catch (e) {
        addToastNotification({
            message: `Failed to fetch status stats. Please try again.`,
            type: 'danger'
        })
    }
}

const fetchHandholdings = async () => {
    try {
        const query = {
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
            ...inputFilter.value,
        }
        const {
            data: { data, meta },
        } = await CarerHandholdingService.index(query)
        handholdings.value = data
        updateMetaPage(meta)
    } catch (e) {
        addToastNotification({
            message: `Failed to fetch handholding data. Please try again.`,
            type: 'danger'
        })
    }
}

const fetchData = async () => {
    isFetching.value = true
    try {
        await fetchTodaySessionStats()
        await fetchHandholdings()
    } catch (e) {
        
    } finally {
        isFetching.value = false
    }
}

const statusOptions = [
    {
        key: 'active',
        label: 'Active',
    },
    {
        key: 'on_hold',
        label: 'On Hold',
    },
    {
        key: 'inactive',
        label: 'Inactive',
    },
    {
        key: 'completed',
        label: 'Completed',
    },
]

// Hard code it for now, Maybe can store it in CDN/retrieve from DB
const categoryOption = [
    {
        key: 'new',
        label: 'New',
    },
    {
        key: 'reactivated',
        label: 'Reactivated',
    },
    {
        key: 'reinstated',
        label: 'Reinstated',
    },
    {
        key: 'others',
        label: 'Other',
    },
]

const onUpdateStartDate = (date:string) => {
    inputFilter.value.start_to_date = date
}

const onUpdateCompletedDate = (date:string) => {
    inputFilter.value.completed_to_date = date
}

const reduceKey = (type: KeyLabel): number | string => type.key

const { metaPage, metaPageTriggered, updateMetaPage, updatePerPage, prevPage, nextPage, gotoPage } = useMetaPage()

const filterTodaySession = async (...argsSessionStatusId: []) => {
    isFetching.value = true

    inputFilter.value.today_session_status_ids = argsSessionStatusId
    await fetchData()

    isFetching.value = false
}

const filterCompletedCount = async (key: null|number) => {
    isFetching.value = true

    inputFilter.value.completed_sessions_count = key,
    await fetchData()

    isFetching.value = false
}

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = null
        }
    }

    fetchData()
}

watch(metaPageTriggered, fetchData)

onMounted(() => {
    fetchStatusStats()
    fetchData()
})
</script>

<style scoped>
.stats-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}
</style>
