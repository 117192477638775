import { TriggerType } from "@/enums/LeadTriggerTypeEnum";
import KeyLabel from '@/types/KeyLabel';

export const triggerLeadNotificationOptions: KeyLabel[] = [
    {
        key: TriggerType.LEAD_FIRST_OPEN_APP,
        label: "When user opens the app for the first time",
    },
    {
        key: TriggerType.LEAD_BY_DAY_DURATION,
        label: "After a certain number of days",
    },
]