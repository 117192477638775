export enum RoleIdEnum {
    ID_SUPER = 1,
    ID_USER = 2,
    ID_CARER = 3,
    ID_MDEC = 4,
    ID_KHAZANAH = 5,
    ID_CORPORATE = 6,
    ID_CUSTOMER_SUPPORT_MANAGER = 8,
    ID_CUSTOMER_SUPPORT_LEAD = 9,
    ID_CUSTOMER_SUPPORT_MEMBER = 10,
    ID_LEAD = 11,
}

export enum RoleNameEnum {
    SUPER = 'super',
    USER = 'user',
    CARER = 'carer',
    MDEC = 'mdec',
    KHAZANAH = 'khazanah',
    CORPORATE = 'corporate',
    CUSTOMER_SUPPORT_MANAGER = 'customer_support_manager',
    CUSTOMER_SUPPORT_LEAD = 'customer_support_lead',
    CUSTOMER_SUPPORT_MEMBER = 'customer_support_member',
    LEAD = 'lead'
}
