import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'
import {getQueryString} from '@/composable/http'

const endpoint = '/backoffice/products'

export default {
    index(query: {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    show(id: number | string, byChannel: boolean = false) {
        const queryParam = byChannel ? `?by_channel=true` : ''
        return httpClientV2.get(`${endpoint}/${id}${queryParam}`)
    },
    getUniqueSaleChannel(query: {}) {
            const queryString = getQueryString(query)
            return httpClientV2.get(`${endpoint}/unique-sale-channel?${queryString}`)
    }
}