import { OrderTypeEnum } from '@/enums/OrderTypeEnum'
import { OrderStatusEnum } from '@/enums/OrderStatusEnum'
import KeyLabel from '@/types/KeyLabel'
import { RefundMethodEnum } from '@/enums/RefundMethodEnum'
import RefundDetails from '@/types/RefundDetails'
import Bank from '@/types/Bank'

export const getStatusBadge = (type: number) => {
    const prefix = 'bg'
    let postfix

    switch (type) {
        case OrderStatusEnum.STATUS_PAID:
            postfix = 'success'
            break
        case OrderStatusEnum.STATUS_DUE:
            postfix = 'warning'
            break
        case OrderStatusEnum.STATUS_CANCELLED_UNPAID:
        case OrderStatusEnum.STATUS_CANCELLED_NO_REFUND:
        case OrderStatusEnum.STATUS_CANCELLED_REFUND:
            postfix = 'dark'
            break
        case OrderStatusEnum.STATUS_VOID:
            postfix = 'danger'
            break;
        default:
            postfix = 'secondary'
            break
    }

    return `${prefix}-${postfix}`
}

export const getTypeLabel = (type: number) => {
    let label

    switch (type) {
        case OrderTypeEnum.TYPE_CHILDCARE:
            label = 'Childcare'
            break
        case OrderTypeEnum.TYPE_ELDERCARE:
            label = 'Eldercare'
            break
        case OrderTypeEnum.TYPE_CORPORATE:
            label = 'Corporate'
            break
        case OrderTypeEnum.TYPE_SHOP:
            label = 'Shop'
            break
        default:
            label = 'Others'
            break
    }

    return label
}

export const getStatusLabel = (status: number, statuses: Array<KeyLabel>) => {
    return statuses.find((findStatus: KeyLabel) => findStatus.key === status)?.label ?? 'N/A'
}

export const filterSessionRefundDetails = (refunds: Array<RefundDetails>, session_id: number) => {
    return refunds.filter((details: RefundDetails) => {
        return details.session_id == session_id
    })
}

export const getRefundMethodLabel = (refund_method: string) => {
    if(refund_method == RefundMethodEnum.BANK_TRANSFER)
        return 'Bank Transfer'

    if(refund_method == RefundMethodEnum.POINT_CONVERSION)
        return 'Point Conversion'

    return 'N/A'
}

export const getRefundDetails = (refundDetails: RefundDetails, banks: Array<Bank>) => {
    if(refundDetails.refund_method == RefundMethodEnum.BANK_TRANSFER)
        return `Account No: ${refundDetails.details.account_no} (${banks.find((findBank: Bank) => findBank.id === refundDetails.details.bank_id)?.name})`

    if(refundDetails.refund_method == RefundMethodEnum.POINT_CONVERSION)
        return `Amount: ${refundDetails.details.amount}`

    return 'N/A'
}

export const getFormattedShopAddressInfo = (contact: any) => {
    return contact.name + ", " + contact.email + ", " + contact.phoneNo
}