<template>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">
                1. Select Customer's Sessions
            </h4>
        </div>
        <div class="card-body">
            <form>
                <div class="form-group">
                    <FormLabel label="Customer" is-required/>
                    <SearchSelectUser v-model="selectedCustomer" :user-role="RoleNameEnum.USER"/>
                </div>
                <template v-if="selectedCustomer">
                    <div class="mt-4 border-top pt-3">
                        <!--                <div class="mt-4">-->
                        <div class="d-flex flex-row justify-content-between align-text-bottom">

                            <div>
                                <FormLabel label="Sessions"/>
                                <ErrorMessage :has-error="!!errors.session_ids"
                                              :message="errors.session_ids?.[0] ?? 'There is an error with the selected sessions.'"
                                />
                            </div>


                            <button @click.prevent="openModal"
                                    class="btn btn-sm btn-outline-secondary col-"
                            >
                                Create New Session
                            </button>
                        </div>
                        <div class="mt-2 ">
                            <OverlaySpinner :is-showing="isFetchingSessions"/>
                            <div v-if="!isFetchingSessions">
                                <div v-if="availableSessions.length">
                                    <div class="table-responsive">
                                        <table class="table table-vcenter">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Session No</th>
                                                <th class="text-center">Session Type</th>
                                                <th class="text-center">Service Type</th>
                                                <th>Status</th>
                                                <th class="text-center">Date</th>
                                                <th class="text-center">Time</th>
                                                <th>Address</th>
                                                <th>Carer</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="session in availableSessions">
                                                <td>
                                                    <input v-model="form.session_ids"
                                                           :value="session.id"
                                                           type="checkbox"
                                                           name="selected_sessions"
                                                    >
                                                </td>
                                                <td>{{ session.no }}</td>
                                                <td>
                                                    <SessionTypeBadge
                                                        :session-type-id="session.sessionTypeId"
                                                        :session-types="sessionTypes"
                                                    />
                                                </td>
                                                <td>
                                                    <SessionServiceTypeBadge
                                                        :session-service-type-id="session.sessionServiceTypeId"
                                                        :session-service-types="sessionServiceTypes"
                                                    />
                                                </td>
                                                <td>
                                                    <SessionChangeStatus :session="session" display-only/>
                                                </td>
                                                <td class="text-center">
                                                    <div>{{ session.startedAtDate }}</div>
                                                    <div>{{ session.startedAtDay }}</div>
                                                </td>
                                                <td class="text-center">
                                                    <div>{{ session.startedAtTime }} - {{ session.endedAtTime }}</div>
                                                    <div>{{ `${ session.totalHours } hours` }}</div>
                                                </td>
                                                <td>
                                                    {{
                                                        session.address ? getShortAddress(session.address) : null
                                                    }}
                                                </td>
                                                <td>
                                                    <UserNameId v-if="session.carer" :user="session.carer"
                                                                :role="RoleNameEnum.CARER"/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <TablePagination
                                        :meta-page="metaPage"
                                        @prev-page="prevPage"
                                        @next-page="nextPage"
                                        @goto-page="gotoPage"
                                    />
                                </div>
                                <div v-else>
                                    <div v-if="mode === 'primary'">
                                        <p class="text-center fst-italic">
                                            There are currently no sessions available for ordering.
                                        </p>
                                        <div class="d-flex justify-content-center ">
                                            <div>
                                                <a type="button"

                                                   @click="toggleShowOrderableSessionExplanation"
                                                   class="text-xs fst-italic"
                                                >
                                                    What does "available for ordering" means?
                                                </a>
                                                <ul v-if="showOrderableSessionExplanation" class="mt-1">
                                                    <li>The Session does not belong to an Order</li>
                                                    <li>The session has an assigned Carer</li>
                                                    <li>The session's status is Carer Approved</li>
                                                    <li>The session starts after 3 hours from now.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p class="text-center fst-italic">
                                            There are no available sessions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </template>
            </form>
        </div>
    </div>


    <kc-modal :modal-id="modalId"
              :modal-title="`Create Sessions for Customer ${selectedCustomer?.mainProfile?.fullName ?? ''}`"
              modal-size="xl"
              full-height
    >
        <SessionCreate :customer-id="selectedCustomer?.id"
                       :redirect-on-success="false"
                       @submitted="handleSessionsCreated"
        />
    </kc-modal>
</template>

<script setup lang="ts">

import UserNameId from '@/components/UserNameId.vue'
import SessionTypeBadge from '@/pages/sessions/components/SessionTypeBadge.vue'
import SessionServiceTypeBadge from '@/pages/sessions/components/SessionServiceTypeBadge.vue'
import SessionChangeStatus from '@/pages/sessions/components/SessionChangeStatus.vue'
import {RoleNameEnum} from '@/enums/RoleEnum'
import {getShortAddress} from '@/composable/useAddress'
import FormLabel from '@/components/form/FormLabel.vue'
import SearchSelectUser from '@/components/SearchSelectUser.vue'
import {useCreateOrderStore} from '@/stores/createOrder'
import {storeToRefs} from 'pinia'
import {useModal} from '@/composable/useModal'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import {useResourcesStore} from '@/stores/resources'
import KcModal from '@/components/Modal.vue'
import TablePagination from '@/components/TablePagination.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import SessionCreate from '@/pages/sessions/SessionCreate.vue'
import {ref, watch} from 'vue'
import SessionQuery from '@/types/query/SessionQuery'
import SessionService from '@/services/SessionService'
import useMetaPage from '@/composable/useMetaPage'
import {useNotificationsStore} from '@/stores/notifications'
import Session from '@/types/Session'

const {addToastNotification} = useNotificationsStore()

const {
    sessionTypes,
    sessionServiceTypes
} = storeToRefs(useResourcesStore())

const {
    metaPage,
    metaPageTriggered,
    prevPage,
    nextPage,
    gotoPage,
    updateMetaPage
} = useMetaPage()


const {
    modalId,
    openModal
} = useModal('create-sessions-for-new-order')

const createOrderStore = useCreateOrderStore()

const {
    form,
    mode,
    errors,
    selectedCustomer
} = storeToRefs(createOrderStore)

const availableSessions = ref<Session[]>([])

const isFetchingSessions = ref(false)
const setIsFetchingSessions = (value = true) => isFetchingSessions.value = value

const handleSessionsCreated = async () => {
    await fetchSessions()
    openModal(false)
}

const fetchSessions = async () => {
    if (!selectedCustomer.value) {
        return
    }

    const query: SessionQuery = {
        customer_user_id: selectedCustomer.value.id,
        page: metaPage.value.currentPage,
        perPage: metaPage.value.perPage
    }

    // If primary, fetch only ready-for-order Sessions
    if (mode.value === 'primary') {
        query.ready_for_order = true
    }

    try {
        setIsFetchingSessions()
        const {data: {data, meta}} = await SessionService.index(query)

        availableSessions.value = data
        updateMetaPage(meta)
    } catch (e) {
        addToastNotification({
            message: 'Failed to fetch sessions for customer due to internal server error. Please contact tech team.',
            type: 'danger'
        })
    }

    setIsFetchingSessions(false)
}

watch(() => mode.value, () => {
    selectedCustomer.value = null
    availableSessions.value = []
})
watch(() => selectedCustomer.value, fetchSessions)
watch(() => metaPageTriggered.value, fetchSessions)

const showOrderableSessionExplanation = ref(false)
const toggleShowOrderableSessionExplanation = () => showOrderableSessionExplanation.value = !showOrderableSessionExplanation.value

</script>