import { getQueryString } from '@/composable/http'
import httpClientV2 from '@/services/HttpClientV2'
import CommonQuery from '@/types/query/CommonQuery'
import httpClient from './HttpClient'

export default {
    index(query: CommonQuery = {}) {
        return httpClientV2.get(`backoffice/carer-payouts?${getQueryString(query)}`)
    },

    show(id: number) {
        return httpClientV2.get(`backoffice/carer-payouts/${id}`)
    },

    store(data: Object) {
        return httpClientV2.post(`backoffice/carer-payouts`, data)
    },

    export(id: number) {
        return httpClientV2.post(`backoffice/carer-payouts/${id}/export`)
    },

    confirm(id: number) {
        return httpClientV2.post(`backoffice/carer-payouts/${id}/confirm`)
    },

    destroy(id: number) {
        return httpClientV2.delete(`backoffice/carer-payouts/${id}`)
    },

    getRecipientByUserId(carerPayoutId: number, userId: number) {
        return httpClientV2.get(`backoffice/carer-payouts/${carerPayoutId}/recipients/${userId}`)
    },
}