export default function useFormError (vuelidate: any) {
    function hasError(name: string) {
        return (
            vuelidate.value.inputUser.hasOwnProperty(name) &&
            vuelidate.value.inputUser[name].$errors.length > 0
        )
    }

    function getError(name: string) {
        return hasError(name) ? vuelidate.value.inputUser[name].$errors[0]?.$message : null
    }

    function clearError(name: string) {
        if (hasError(name)) vuelidate.value.inputUser[name].$reset()
    }

    function clearAllErrors() {
        vuelidate.value.$reset()
        vuelidate.value.$clearExternalResults()
    }
    
    return {
        hasError,
        getError,
        clearError,
        clearAllErrors
    }
}