import httpClientV2 from '@/services/HttpClientV2'
import { getQueryString } from '@/composable/http'
import VoucherQuery from '@/types/query/VoucherQuery'
import StoreVoucherFormData from '@/types/formData/StoreVoucherFormData'
import UpdateVoucherFormData from '@/types/formData/UpdateVoucherFormData'
import DistributeVoucherFormData from '@/types/formData/DistributeVoucherFormData'
import axios from 'axios'

const endpoint = '/backoffice/vouchers'

function transformVoucherData(data: any) {
    // For direct voucher creation/update
    if (data.voucher) {
        // Move validity period from extra_attributes to main voucher object
        const { started_at, expired_at, ...otherExtraAttributes } = data.voucher.extra_attributes

        return {
            ...data,
            voucher: {
                ...data.voucher,
                started_at,
                expired_at,
                extra_attributes: otherExtraAttributes
            }
        }
    }

    // For template/distribution creation
    return data
}

export default {
    index(query: VoucherQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },
    store(data: any) {
        return httpClientV2.post(endpoint, transformVoucherData(data))
    },
    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },
    update(id: number, data: UpdateVoucherFormData) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },
    destroy(id: number) {
        return httpClientV2.delete(`${endpoint}/${id}`)
    },
    bulkDestroy(ids: number[]) {
        return httpClientV2.post(`${endpoint}/bulk-delete`, { ids })
    },
    sendEmailCode(id: number, data: any) {
        return httpClientV2.post(`${endpoint}/${id}/email`, data)
    },
    generate(data: any) {
        return httpClientV2.post(`${endpoint}/bulk-generate`, data)
    },
    generateReport(data: any) {
        return httpClientV2.post(`${endpoint}/generate-report`, data)
    }
}
