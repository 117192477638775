<template>
    <div class="text-end mb-3">
        <button @click="confirmPublish" class="btn btn-primary">
            <icon name="cloud-up" />
            Publish Changes
        </button>
    </div>

    <div class="card">
        <div class="card-body">
            <template v-if="activeComponent">
                <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

                <div class="py-4">
                    <KeepAlive>
                        <component :is="activeComponent.component" v-bind="activeComponent.props"
                            :key="`${activeComponent.slug}-${Date.now()}`">
                        </component>
                    </KeepAlive>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSessionRateAmountStore } from '@/stores/sessionRateAmountStore';
import { storeToRefs } from 'pinia';
import { onMounted, provide } from 'vue';


const sessionRateAmountStore = useSessionRateAmountStore()
const {
    activeComponent,
    subMenus
} = storeToRefs(sessionRateAmountStore)

const { setActiveComponent, configureSubMenu, confirmPublish } = sessionRateAmountStore

provide('setActiveComponent', setActiveComponent)

onMounted(() => {
    configureSubMenu()
    setActiveComponent('')
})
</script>