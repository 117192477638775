import { SessionServiceTypeEnum } from "@/enums/SessionServiceTypeEnum"
import SessionRateAmountService from "@/services/settings/SessionRateAmountService"
import MetaPage from "@/types/MetaPage"
import UserQuery from "@/types/query/UserQuery"
import SessionRateAmount from "@/types/SessionRateAmount"
import { AxiosError } from "axios"
import { defineStore } from "pinia"
import { defineAsyncComponent, ref } from "vue"

export const useSessionRateAmountStore = defineStore(
    'sessionRateAmounts',
    () => {
        const currentSessionServiceType = ref<SessionServiceTypeEnum>(SessionServiceTypeEnum.ID_ON_DEMAND)

        const sessionRateAmounts = ref<SessionRateAmount[]>([])

        const isFetchingList = ref(false)
        
        const error = ref<string | null>(null)

        const activeComponent = ref<object>({})
        const subMenus = ref<Array<any>>([])

        const pagination = ref<MetaPage>({
            currentPage: 1,
            lastPage: 1,
            perPage: 10,
            total: 0,
            from: 0,
            to: 0
        })

        const fetchSessionRateAmounts = async (query: UserQuery = {}) => {
            isFetchingList.value = true

            try {
                const { data } = await SessionRateAmountService.index({session_service_type: currentSessionServiceType.value, ...query})

                sessionRateAmounts.value = data.data.map((item: SessionRateAmount) => {
                    return {
                        ...item,
                        amountInCurrency: (item.amount / 100).toFixed(2),
                        payAmountInCurrency: (item.payAmount / 100).toFixed(2),
                    }
                })

                pagination.value = {
                    currentPage: data.meta?.current_page,
                    lastPage: data.meta?.last_page,
                    perPage: data.meta?.per_page,
                    total: data.meta?.total,
                    from: data.meta?.from,
                    to: data.meta?.to
                }
            } catch (err) {
                error.value = err instanceof Error ? err.message : 'Failed to load rate details'
                console.error('Error fetching rate:', err)
            } finally {
                isFetchingList.value = false
            }
        }

        const getRateDescription = (rate: SessionRateAmount) => {
            switch (rate.sessionServiceTypeId) {
                case SessionServiceTypeEnum.ID_ON_DEMAND:
                    return `${rate.metadata.hour} hour(s)`
                default:
                    return rate.name
            }
        }

        const publish = async () => {
            try {
                isFetchingList.value = true
                await SessionRateAmountService.publish()
        
                await window.Swal.fire({
                    text: 'Changes have been published successfully',
                    showConfirmButton: false,
                    timer: 1500
                })
        
                await fetchSessionRateAmounts()
            } catch (err) {
                let errorMessage = 'Failed to publish changes'
        
                const error = err as AxiosError | any
        
                if (error.response?.data?.message) {
                    errorMessage = error.response.data.message
                }
        
                await window.Swal.fire({
                    text: errorMessage,
                })
            } finally {
                isFetchingList.value = false
            }
        }
        
        const confirmPublish = async () => {
            const result = await window.Swal.fire({
                text: 'Are you sure you want to publish changes?',
                showCancelButton: true,
                confirmButtonText: 'Yes, publish',
                cancelButtonText: 'Cancel',
            })
        
            if (result.isConfirmed) {
                await publish()
            }
        }
        
        // Pagination handlers
        const handleGotoPage = (page: number) => {
            fetchSessionRateAmounts({
                page
            })
        }
        
        const handlePrevPage = () => {
            if (pagination.value.currentPage > 1) {
                fetchSessionRateAmounts({
                    page: pagination.value.currentPage - 1
                })
            }
        }

        const handleNextPage = () => {
            if (pagination.value.currentPage < pagination.value.lastPage) {
                fetchSessionRateAmounts({
                    page: pagination.value.currentPage + 1
                })
            }
        }


        const setActiveComponent = (menu: any = null) => {
            if (typeof menu === 'string' && menu.length !== 0) {
                activeComponent.value = subMenus.value.find(
                    (subMenu) => subMenu.slug === menu
                )
            } else if (typeof menu === 'object') {
                activeComponent.value = menu
            } else {
                activeComponent.value = subMenus.value[0]
            }
        }

        const configureSubMenu = () => {
            subMenus.value = [
                {
                    title: 'On-Demand',
                    component: defineAsyncComponent(
                        () => import('@/pages/settings/session-rate-amounts/SessionRateAmountList.vue')
                    ),
                    slug: 'session-rate-amount-on-demand',
                    props: {
                        sessionServiceType: SessionServiceTypeEnum.ID_ON_DEMAND
                    }
                },
                {
                    title: 'Long Term',
                    component: defineAsyncComponent(
                        () => import('@/pages/settings/session-rate-amounts/SessionRateAmountList.vue')
                    ),
                    slug: 'session-rate-amount-long-term',
                    props: {
                        sessionServiceType: SessionServiceTypeEnum.ID_LONG_TERM
                    }
                },
                {
                    title: 'Drop Off',
                    component: defineAsyncComponent(
                        () => import('@/pages/settings/session-rate-amounts/SessionRateAmountList.vue')
                    ),
                    slug: 'session-rate-amount-drop-off',
                    props: {
                        sessionServiceType: SessionServiceTypeEnum.ID_DROP_OFF
                    }
                },
                {
                    title: 'Extra',
                    component: defineAsyncComponent(
                        () => import('@/pages/settings/session-rate-amounts/SessionRateAmountList.vue')
                    ),
                    slug: 'session-rate-amount-extra',
                    props: {
                        sessionServiceType: null
                    }
                },
            ]
        }

        return {
            // State
            isFetchingList,
            pagination,

            currentSessionServiceType,
            sessionRateAmounts,

            subMenus,
            activeComponent,

            // Actions
            setActiveComponent,
            fetchSessionRateAmounts,
            handleGotoPage,
            handlePrevPage,
            handleNextPage,
            configureSubMenu,
            getRateDescription,
            confirmPublish,
        }
    }
)