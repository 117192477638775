<template>
    <Transition name="slide-fade">
        <div v-if="count > 0" class="selection-panel shadow-lg rounded-3 bg-slate-800 text-white p-3"
            :class="{ 'has-actions': Boolean(hasSlot) }">
            <div class="d-flex align-items-center gap-3">
                <div class="selection-info">
                    <strong>{{ count }}</strong> item{{ count > 1 ? 's' : '' }} selected
                </div>

                <button class="btn btn-sm btn-outline-light" @click="onClear">
                    <icon name="x" />
                    Clear
                </button>

                <slot></slot>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useSlots } from 'vue'

const slots = useSlots()
const hasSlot = Boolean(slots.default)

interface Props {
    count: number
}

interface Emits {
    (e: 'clear'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const onClear = () => {
    emit('clear')
}
</script>

<style scoped>
.selection-panel {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
    min-width: 200px;
}

.selection-panel.has-actions {
    min-width: 300px;
}

/* Transition animations */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
}
</style>