import { getQueryString } from '@/composable/http'
import httpClientV2 from '@/services/HttpClientV2'

const endpoint = '/backoffice/countries'

export default {
    index(query: object = {}) {
        return httpClientV2.get(`${endpoint}?${getQueryString(query)}`)
    },
    update(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },
    publish() {
        return httpClientV2.post(`${endpoint}/publish`)
    }
}