<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <a class="btn btn-primary float-right" @click="openEditLeadNotificationForm()" aria-label="Add leadNotification Notification">
                        <icon name="plus" />
                        Add Automated Lead Notification
                    </a>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Type</label>
                    <vue-select :options="triggerLeadNotificationOptions" :reduce="(type: KeyLabel) => type.key" label="label"
                        v-model="inputFilter.triggerType" />
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Notification Day</label>
                    <input class="form-control" type="number" v-model="inputFilter.numberOfDay">
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetch"
                        :is-loading="isFetchingleadNotificationNotifications"></button-filter>
                </div>
            </div>
            <div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Day</th>
                                <th>Time</th>
                                <th>Title</th>
                                <th>Preview</th>
                                <th>Message</th>
                                <th>Status</th>
                                <th>Created At</th> 
                                <th>Action</th> 
                            </tr>
                        </thead>
                        <tbody v-if="leadNotifications.length > 0">
                            <tr v-for="leadNotification in leadNotifications" :key="leadNotification.id">
                                <td>{{ leadNotification.triggerLabel }}</td>
                                <td>{{ leadNotification.numberOfDay ?? '-' }}</td>
                                <td>{{ leadNotification.timeOfDay ? convertTo12HourFormat(leadNotification.timeOfDay) :'-'}}</td>
                                <td>{{ leadNotification.title }}</td>
                                <td>{{ leadNotification.preview }}</td>
                                <td>{{ limitStringLength(leadNotification.message,20) }}</td>
                                <td>
                                    <StatusBadge :class="leadNotification.isActive ? 'bg-green' : 'bg-red'"
                                        :label="leadNotification.isActive ? 'Active' : 'Inactive'" show />
                                </td>
                                <td>{{ leadNotification.createdAt ? formatDate(leadNotification.createdAt , 'DD-MMM-YYYY hh:mm A') :'-' }}</td>
                                <td class="text-end">
                                    <div class="btn-group">
                                        <button class="btn btn-icon btn-primary" @click="(openEditLeadNotificationForm(leadNotification))">
                                            <icon name="edit" />
                                        </button>
                                        <button class="btn btn-icon btn-danger" @click.prevent="deleteNotification(leadNotification)">
                                            <icon name="trash" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="8" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <overlay-spinner :is-showing="isFetchingleadNotificationNotifications" />
                    </table>
                </div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import useMetaPage from '@/composable/useMetaPage'
import { ref, watch, onMounted, markRaw, defineAsyncComponent } from 'vue'
import { formatDate, convertTo12HourFormat } from '@/composable/useDate'
import {limitStringLength} from '@/composable/useGeneral'
import { useNotificationsStore } from '@/stores/notifications'
import LeadNotificationForm from './LeadNotificationForm.vue'
import LeadNotificationService from '@/services/LeadNotificationService'
import { triggerLeadNotificationOptions } from '@/composable/useLeadNotification'
import KeyLabel from '@/types/KeyLabel'
import LeadNotification from '@/types/LeadNotification'
import { useModalStore } from '@/stores/modalStore'
import StatusBadge from '@/components/StatusBadge.vue'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const { addToastNotification } = useNotificationsStore()
const modalStore = useModalStore()
const { showModal, hideModal } = modalStore

const leadNotifications = ref<Array<LeadNotification>>([])

const inputFilter = ref({
    triggerType: null,
    numberOfDay: null,
})

const selectedLeadNotification = ref<LeadNotification>();

const isFetchingleadNotificationNotifications = ref<boolean>(false)

onMounted(() => {
})



const fetch = () => {
    isFetchingleadNotificationNotifications.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    } 

    LeadNotificationService.index(query).then(({ data: { data, meta } }) => {
        leadNotifications.value = data
        updateMetaPage(meta)
    }).finally(() => {
        isFetchingleadNotificationNotifications.value = false
    })
}

const deleteNotification = (notification: LeadNotification) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete ${notification.title} notification?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            LeadNotificationService.destroy(notification.id).then(() => {
                addToastNotification({
                    message: `${notification.title} deleted`,
                    type: 'success',
                })
                fetch();
            }).catch(() => {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            })
        }
    })
}

const openEditLeadNotificationForm = (leadNotification: LeadNotification  | null = null) => {

showModal({
    component: markRaw(defineAsyncComponent(() =>
        import('@/pages/notifications/leads/LeadNotificationForm.vue')
    )),
    size: 'xl',
    props: {
        leadNotification: leadNotification,
        mode:leadNotification ? 'update' : 'create',
        onUpdatedOrCreated: () => {
            fetch()
        }
    }
})
}


const resetFilter = () => {
    inputFilter.value.triggerType = null
    inputFilter.value.numberOfDay = null

    fetch()
}

fetch()

watch(
    () => metaPageTriggered.value,
    () => fetch()
)
</script>