export enum NavigationTypeEnum {
    REGISTER = "register",
    BOOK_NOW = "book_now",
    KIDDOSHOP = "kiddo_shop",
    PRODUCT = "product",
    // PRODUCT_CATEGORY = "product_category",
    // PRODUCT_COLLECTION = "product_collection",
}

export const NavigationTypeLabels: Record<NavigationTypeEnum, string> = {
    [NavigationTypeEnum.REGISTER]: "Register",
    [NavigationTypeEnum.BOOK_NOW]: "Book Now",
    [NavigationTypeEnum.KIDDOSHOP]: "KiddoShop",
    [NavigationTypeEnum.PRODUCT]: "Product",
    // [NavigationTypeEnum.PRODUCT_CATEGORY]: "Product Category",
    // [NavigationTypeEnum.PRODUCT_COLLECTION]: "Product Collection",
};