export enum OrderTypeEnum {
    TYPE_CHILDCARE = 1,
    TYPE_ELDERCARE = 2,
    TYPE_CORPORATE = 3,
    TYPE_SHOP = 4
}

export enum OrderClassificationEnum {
    TYPE_SERVICE = 1,
    TYPE_SHOP = 2
}

export const OrderClassificationMap = {
    [OrderClassificationEnum.TYPE_SERVICE]: [
        OrderTypeEnum.TYPE_CHILDCARE,
        OrderTypeEnum.TYPE_ELDERCARE,
        OrderTypeEnum.TYPE_CORPORATE
    ],
    [OrderClassificationEnum.TYPE_SHOP]: [OrderTypeEnum.TYPE_SHOP],
};