import { ref, computed } from 'vue'
import type { Ref } from 'vue'

export interface Selectable {
    id: number | string
}

export function useTableSelection<T extends Selectable>(initialItems: T[] = []) {
    const selectedItems = ref<Set<T['id']>>(new Set())
    const items = ref(initialItems) as Ref<T[]>

    const isAllSelected = computed(() => {
        if (!items.value?.length) return false
        return items.value.every(item => selectedItems.value.has(item.id))
    })

    function setItems(newItems: T[]) {
        items.value = newItems
    }

    function toggleSelectAll() {
        if (isAllSelected.value) {
            selectedItems.value.clear()
        } else {
            selectedItems.value = new Set(items.value.map(item => item.id))
        }
    }

    function toggleSelect(id: T['id']) {
        if (selectedItems.value.has(id)) {
            selectedItems.value.delete(id)
        } else {
            selectedItems.value.add(id)
        }
    }

    function isSelected(id: T['id']) {
        return selectedItems.value.has(id)
    }

    function clearSelection() {
        selectedItems.value.clear()
    }

    return {
        items,
        selectedItems,
        isAllSelected,
        setItems,
        toggleSelectAll,
        toggleSelect,
        isSelected,
        clearSelection
    }
}