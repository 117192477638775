import httpClientV2 from "@/services/HttpClientV2";
import { getQueryString } from "@/composable/http";
import { NotificationTemplate } from "@/types/NotificationTemplate";

const endpoint = "/backoffice/notification-templates";

/**
 * Convert an object into FormData
 * @param data - Object to be converted
 * @returns FormData
 */
const toFormData = (data: Partial<NotificationTemplate>): FormData => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
        if (value === null || value === undefined) return;

        if (value instanceof File) {
            formData.append(key, value);
        } else if (typeof value === "object") {
            // Handle nested object (e.g., handholding_trigger_notification)
            Object.entries(value).forEach(([subKey, subValue]) => {
                if (subValue !== null && subValue !== undefined) {
                    formData.append(`${key}[${subKey}]`, String(subValue));
                }
            });
        } else {
            formData.append(key, String(value));
        }
    });

    return formData;
};

export default {
    index(query: Record<string, any> = {}) {
        return httpClientV2.get(`${endpoint}?${getQueryString(query)}`);
    },

    store(data: Partial<NotificationTemplate>) {
        return httpClientV2.post(endpoint, toFormData(data), {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    update(id: number, data: Partial<NotificationTemplate>) {
        return httpClientV2.post(`${endpoint}/${id}`, toFormData(data), {
            headers: { "Content-Type": "multipart/form-data" },
        });
    },

    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`);
    },

    showRecipientCategories() {
        return httpClientV2.get(`${endpoint}/recipient-categories`);
    },

    showTriggerTypes(recipientCategory: number) {
        return httpClientV2.get(`${endpoint}/trigger-types/${recipientCategory}`);
    },
};
