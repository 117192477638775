<template>
    <div class="px-3 pb-5 d-flex justify-content-between">
        <span>Chat is currently <span class="badge badge-pill" :class="props.isActive ? 'bg-success' : 'bg-danger'">{{ statusLabel }}</span></span>
        <a href="#" @click.prevent="toggleChannel">{{ props.isActive ? 'Set to Inactive' : 'Set to Active' }}</a>
    </div>
</template>

<script setup lang="ts">
import ChatService from '@/services/ChatService'
import { useNotificationsStore } from '@/stores/notifications'
import { computed } from 'vue'

const props = defineProps({
    isActive: { type: Boolean, default: true },
    channelUrl: { type: String },
    channelType: { type: String },
    ticketId: { type: Number, required: true },
    currentType: { type: String }
})

const { addToastNotification } = useNotificationsStore()

const statusLabel = computed<string>(() => props.isActive ? ' Active' : 'Inactive')

const toggleChannel = () => {
    const isActive = ! props.isActive
    ChatService.updateActiveStatus({
        isActive: isActive,
        channelUrl: props.channelUrl ?? '',
        channelType: `${props.channelType}_channels`,
    }).then(() => {
        const activeText = isActive ? 'activated' : 'inactivated'
        addToastNotification({ message: `Channel has been ${activeText}`, type: 'success' })
    })
}
</script>
