import { toMYR } from '@/composable/useCurrency'
import VoucherService from '@/services/settings/VoucherService'
import Voucher, { VoucherExtraAttributes } from '@/types/Voucher'
import { SweetAlertResult } from 'sweetalert2'

export const EMAIL_CANCEL = 0
export const EMAIL_SUCCESS = 1
export const EMAIL_FAILED = 2

export const getDiscountAmountDisplay = (discountAmount: number, isFixedDiscount: boolean): string => {
    return isFixedDiscount ? toMYR(discountAmount) : `${discountAmount}%`
}

export const getUsageDisplay = (extraAttributes: VoucherExtraAttributes): string => {
    const usesCount = extraAttributes.totalUses
    const maxUses = extraAttributes.maxUses ?? '\u221E' // Infinity symbol

    return `${usesCount} / ${maxUses}`
}

export const sendEmailCode = async (voucher: Voucher): Promise<number> => {
    const result: SweetAlertResult = await window.Swal.fire({
        input: 'email',
        inputValue: voucher.wooCouponOrder?.email,
        confirmButtonText: 'Send',
        showCancelButton: true,
        inputLabel: 'Please input customer email address',
    })

    if (result.isConfirmed) {
        try {
            await VoucherService.sendEmailCode(voucher.id, {
                email: result.value,
            })

            return EMAIL_SUCCESS
        } catch {
            return EMAIL_FAILED
        }
    }

    return EMAIL_CANCEL
}



export function getWooCommerceOrderUrl(orderNo: string): string {
    const wpAdminUrl = import.meta.env.VITE_WP_ADMIN_URL

    return `${wpAdminUrl}/post.php?post=${orderNo}&action=edit`
}

export function getSalesChannelLabel(salesChannel ?: string): string {
    switch (salesChannel) {
        case 'shopify':
            return 'KiddoShop (App)'
        case 'woocommerce':
            return 'KiddoShop (Website)'
        default:
            return '-'
    }
}
