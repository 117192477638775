<template>
    <div class="card">
        <div class="card-header">
            <h5 class="mb-0">Create Voucher</h5>
        </div>
        <div class="card-body">
            <form @submit.prevent="submit" ref="createVoucherForm">

                <!-- Basic Information -->
                <h5 class="mb-3">Basic Information</h5>
                <div class="row g-3 mb-4">
                    <div class="col-md-6">
                        <FormLabel label="Code" :isRequired="true" />
                        <input v-model="voucher.code" type="text" class="form-control">
                        <HelperText text="Code that will be used by customer to apply the discount." />
                        <ErrorMessage :hasError="!!errors.code" :message="errors.code" />
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Name" :isRequired="true" />
                        <input v-model="voucher.name" type="text" class="form-control">
                        <HelperText
                            text="General name for discount. Will be displayed in customer booking confirmation details." />
                        <ErrorMessage :hasError="!!errors.name" :message="errors.name" />
                    </div>
                    <div class="col-12">
                        <FormLabel label="Description" />
                        <textarea v-model="voucher.description" class="form-control" rows="3"></textarea>
                        <HelperText text="Description of the discount." />
                    </div>
                </div>

                <hr>

                <!-- Discount Details -->
                <h5 class="mb-3">Discount Details</h5>
                <div class="row g-3 mb-4">
                    <div class="col-md-6">
                        <FormLabel label="Discount Amount" :isRequired="true" />
                        <div class="input-group">
                            <input v-model="voucher.discount_amount" type="number" class="form-control" step="0.01">
                            <select v-model="voucher.is_fixed_discount" class="form-select">
                                <option :value="false" :selected="voucher.is_fixed_discount === false">%</option>
                                <option :value="true">RM (Fixed Amount)</option>
                            </select>
                        </div>
                        <ErrorMessage :hasError="!!errors.discount_amount" :message="errors.discount_amount" />
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Discount Application Type" is-required />
                        <select v-model="voucher.extra_attributes.discount_application_type" class="form-select">
                            <option :value="VoucherDiscountApplicationTypeEnum.ONCE_PER_ORDER">
                                {{
                                    VoucherDiscountApplicationTypeLabels[VoucherDiscountApplicationTypeEnum.ONCE_PER_ORDER]
                                }}
                            </option>
                            <option :value="VoucherDiscountApplicationTypeEnum.EACH_SESSION_IN_ORDER">
                                {{
                                    VoucherDiscountApplicationTypeLabels[VoucherDiscountApplicationTypeEnum.EACH_SESSION_IN_ORDER]
                                }}
                            </option>
                        </select>
                    </div>
                </div>

                <hr>

                <!-- Validity and Usage -->
                <h5 class="mb-3">Validity and Usage</h5>
                <div class="row g-3 mb-4">
                    <div class="col-md-6">
                        <FormLabel label="Start Date" />
                        <input v-model="voucher.started_at" type="date" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Expiry Date" />
                        <input v-model="voucher.expired_at" type="date" class="form-control">
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Max Total Uses" />
                        <input v-model="voucher.extra_attributes.max_uses" type="number" class="form-control" min="1">
                        <HelperText text="Leave blank for unlimited uses." />
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Max Uses Per User" />
                        <input v-model="voucher.extra_attributes.max_uses_per_user" type="number" class="form-control"
                            min="1">
                        <HelperText text="Leave blank for unlimited uses per user." />
                    </div>
                </div>

                <hr>

                <!-- Applicability Conditions -->
                <h5 class="mb-3">Applicability Conditions</h5>
                <div class="row g-3 mb-4">
                    <div class="col-md-6">
                        <FormLabel label="Minimum Sessions Per Order" />
                        <input v-model="voucher.extra_attributes.min_sessions" type="number" class="form-control"
                            min="1">
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Minimum Hours Per Session" />
                        <input v-model="voucher.extra_attributes.min_hours_per_session" type="number"
                            class="form-control" step="0.5" min="0">
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Valid on Public Holidays" />
                        <select v-model="voucher.extra_attributes.is_available_on_holidays" class="form-select">
                            <option :value="true">Yes</option>
                            <option :value="false"
                                :selected="voucher.extra_attributes.is_available_on_holidays === false">No</option>
                        </select>
                    </div>
                </div>

                <hr>

                <!-- Additional Settings -->
                <h5 class="mb-3">Additional Settings</h5>
                <div class="row g-3 mb-4">
                    <div class="col-md-6">
                        <div class="form-group mb-3 row">
                            <FormLabel label="Customer" />
                            <div class="col">
                                <vue-select :options="userOptions" @search="handleSearch" label="name"
                                    v-model="voucher.user_id" :reduce="(user: any) => user.id"></vue-select>
                                <ErrorMessage :has-error="!!errors.user_id" :message="errors.user_id" />
                                <HelperText text="Leave empty if voucher is available for many users" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Enable Voucher Ownership" />
                        <select v-model="voucher.extra_attributes.is_voucher_ownership" class="form-select">
                            <option :value="false">No</option>
                            <option :value="true">Yes</option>
                        </select>
                        <ErrorMessage :hasError="!!errors.is_voucher_ownership" :message="!!errors.is_voucher_ownership" />
                        <HelperText text="Select Yes to enable voucher ownership to user" />
                    </div>
                    <div class="col-md-6">
                        <FormLabel label="Use Extension Hours Rate for Extra Hours" />
                        <select v-model="voucher.extra_attributes.apply_extension_rate_to_extra_hours"
                            class="form-select">
                            <option :value="true"
                                :selected="voucher.extra_attributes.apply_extension_rate_to_extra_hours === true">Yes
                            </option>
                            <option :value="false"
                                :selected="voucher.extra_attributes.apply_extension_rate_to_extra_hours === false">No
                            </option>
                        </select>
                        <HelperText text="If yes, hours beyond the minimum will be charged at the extension rate." />
                    </div>
                </div>

                <div class="d-flex justify-content-end gap-3">
                    <BackButton label="Cancel" />
                    <SaveButton :isSaving="isSubmitting" />
                </div>
            </form>
        </div>
    </div>
</template>


<script setup lang="ts">
import _ from 'lodash/fp'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { AxiosError } from 'axios'
import { useNotificationsStore } from '@/stores/notifications'
import User from '@/types/User'
import FormLabel from '@/components/form/FormLabel.vue'
import HelperText from '@/components/form/HelperText.vue'
import CustomerService from '@/services/users/CustomerService'
import VoucherService from '@/services/settings/VoucherService'
import StoreVoucherFormData from '@/types/formData/StoreVoucherFormData'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import SaveButton from '@/components/form/SaveButton.vue'
import BackButton from '@/components/BackButton.vue'
import { VoucherDiscountApplicationTypeEnum, VoucherDiscountApplicationTypeLabels } from '@/enums/VoucherDiscountApplicationTypeEnum'

const { addToastNotification } = useNotificationsStore()
const router = useRouter()

const voucher = ref<StoreVoucherFormData>({
    code: '',
    name: '',
    description: '',
    user_id: null,
    discount_amount: 0,
    is_fixed_discount: true,
    started_at: null,
    expired_at: null,
    extra_attributes: {
        is_available_on_holidays: false,
        is_voucher_ownership: false,
        increment_discount_each_bundle: false,
        max_uses: null,
        max_uses_per_user: null,
        min_sessions: null,
        min_hours_per_session: null,
        apply_extension_rate_to_extra_hours: false,
        discount_application_type: VoucherDiscountApplicationTypeEnum.ONCE_PER_ORDER
    }
})

const errors = ref<Record<string, string>>({})

const userOptions = ref<User[]>([])
const createVoucherForm = ref<HTMLElement>()

const isSubmitting = ref(false)
const setIsSubmitting = (value = true) => isSubmitting.value = value
const scrollIntoViewForm = () => createVoucherForm.value?.scrollIntoView()

const submit = async () => {

    setIsSubmitting()
    try {

        const response = await VoucherService.store(getFormattedData())
        addToastNotification({
            message: `Voucher ${voucher.value.name} created`,
            type: 'success'
        })

        if (response?.data?.data?.id) {
            await router.push({ name: 'voucher-show', params: { voucherId: response.data.data.id } })
        } else {
            router.push({ name: 'voucher-index' })
        }
    } catch (error: any) {
        if (error.response && error.response.status === 422) {
            errors.value = Object.entries(error.response.data.errors).reduce((acc, [key, value]) => {
                acc[key] = Array.isArray(value) ? value[0] : value
                return acc
            }, {} as Record<string, string>)

            scrollIntoViewForm()
        } else {
            addToastNotification({
                message: 'An error occurred while saving the voucher',
                type: 'danger'
            })
        }
    }
    setIsSubmitting(false)
}

const getFormattedData = () => {

    const inputs = JSON.parse(JSON.stringify(voucher.value))

    inputs.extra_attributes.is_available_on_holidays = typeof inputs.extra_attributes.is_available_on_holidays === 'boolean' ? inputs.extra_attributes.is_available_on_holidays : inputs.extra_attributes.is_available_on_holidays === 'true'
    inputs.extra_attributes.is_voucher_ownership = typeof inputs.extra_attributes.is_voucher_ownership === 'boolean' ? inputs.extra_attributes.is_voucher_ownership : inputs.extra_attributes.is_voucher_ownership === 'true'
    inputs.extra_attributes.increment_discount_each_bundle = typeof inputs.extra_attributes.increment_discount_each_bundle === 'boolean' ? inputs.extra_attributes.increment_discount_each_bundle : inputs.extra_attributes.increment_discount_each_bundle === 'true'
    inputs.extra_attributes.apply_extension_rate_to_extra_hours = inputs.extra_attributes.apply_extension_rate_to_extra_hours === 'true' || inputs.extra_attributes.apply_extension_rate_to_extra_hours === true

    const discount_amount: number = voucher.value.is_fixed_discount ? (voucher.value.discount_amount * 100) : voucher.value.discount_amount

    return {
        ...inputs,
        discount_amount: discount_amount
    }
}

const handleSearch = (search: string, loading: Function) => {
    if (search.length) {
        searchUser(search, loading)
    }
}

const searchUser = _.debounce(350, async (search, loading) => {
    loading(true)

    try {

        const query: { name?: string } = {
            name: search
        }

        const response = await CustomerService.index(query)

        userOptions.value = response.data.data.map((user: { id: number, name: string }) => {
            return {
                id: user.id,
                name: user.name
            }
        })
    } catch (ex: any) {
        addToastNotification({
            message: 'Error while getting available users. Please try again.',
            type: 'danger'
        })
    }

    loading(false)
})


</script>