<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-end">
                <div class=" ms-auto me-2">
                    <router-link class="btn btn-primary " :to="{ name: 'voucher-create' }">
                        <icon name="plus" />
                        Add New Voucher
                    </router-link>
                </div>
                <div class="dropdown position-static">
                    <button type="button" class="btn dropdown-toggle text-muted" data-bs-toggle="dropdown">
                        <icon name="dots" class="text-secondary" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" style="width: 164px;">
                        <router-link class="dropdown-item text-primary " :to="{ name: 'voucher-generate' }">
                            <icon name="mail-fast" />
                            <span class="ps-1">Generate Vouchers</span>
                        </router-link>
                        <router-link class="dropdown-item text-primary " :to="{ name: 'voucher-distribution-index' }">
                            <icon name="mail-fast" />
                            <span class="ps-1">Distribute Vouchers</span>
                        </router-link>
                        <router-link class="dropdown-item text-primary " :to="{ name: 'shop-voucher-template-index' }">
                            <icon name="plus" />
                            Manage Shop Vouchers
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="border rounded-3 p-4 p-md-4 my-3">
                <div class="row g-3">
                    <!-- Voucher Name -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Name" />
                        <input id="voucherName" class="form-control" type="text" v-model="inputFilter.name"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- Voucher Description -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Description" />
                        <input id="voucherDesc" class="form-control" type="text" v-model="inputFilter.description"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- Voucher Code -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Code" />
                        <input id="voucherCode" class="form-control" type="text" v-model="inputFilter.code"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- Email -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Owner's Email" />
                        <input id="voucherEmail" class="form-control" type="email" v-model="inputFilter.email"
                            @keyup.enter="fetchVouchers">
                    </div>

                    <!-- isViewable filter -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Is Viewable" />
                        <select class="form-select" v-model="inputFilter.is_viewable">
                            <option value="">All</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>

                    <div class="col-12 col-md-3">
                        <FormLabel label="Batch No" />
                        <input class="form-control" type="text" v-model="inputFilter.batch">
                    </div>

                    <!-- Distribution -->
                    <div class="col-12 col-md-3">
                        <FormLabel label="Distribution No/Name" />
                        <input id="voucherDistribution" class="form-control" type="text"
                            v-model="inputFilter.distribution">
                    </div>

                    <!-- Start Date Range -->
                    <div class="col-12 col-md-4">
                        <label class="form-label">Start Date Range</label>
                        <DateRangePicker input-id="startDateRange" placeholder="Select start date range"
                            :initial-from-date="inputFilter.start_date_from"
                            :initial-to-date="inputFilter.start_date_to" @update:date-range="updateStartDateRange" />
                    </div>

                    <!-- Expiry Date Range -->
                    <div class="col-12 col-md-4">
                        <label class="form-label">Expiry Date Range</label>
                        <DateRangePicker input-id="expiryDateRange" placeholder="Select expiry date range"
                            :initial-from-date="inputFilter.expiry_date_from"
                            :initial-to-date="inputFilter.expiry_date_to" @update:date-range="updateExpiryDateRange" />
                    </div>

                    <!-- Created Date Range -->
                    <div class="col-12 col-md-4">
                        <label class="form-label">Created Date Range</label>
                        <DateRangePicker input-id="createdDateRange" placeholder="Select created date range"
                            :initial-from-date="inputFilter.created_at_from"
                            :initial-to-date="inputFilter.created_at_to" @update:date-range="updateCreatedDateRange" />
                    </div>

                    <!-- Action Buttons -->
                    <div class="d-inline-flex justify-content-between gap-2 mt-4 ms-auto">

                        <div>
                            <button class="btn btn-primary" @click.prevent="generateReport(false)"
                                :disabled="isFetching">
                                <span v-if="isFetching" class="spinner-border spinner-border-sm me-2"
                                    role="status"></span>
                                <icon v-else name="file-spreadsheet" />
                                <span>Download</span>
                            </button>
                        </div>
                        <div>
                            <ButtonFilter @reset="resetFilter" @filter="fetchVouchers" :is-loading="isFetching" />
                        </div>
                    </div>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox" class="form-check-input" :checked="isAllSelected"
                                    @change="toggleSelectAll">
                            </th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Discount</th>
                            <th>User</th>
                            <th>Started Date</th>
                            <th>Expired Date</th>
                            <th>Created At</th>
                            <th>Used</th>
                            <th>Is Viewable</th>
                            <th>Batch No</th>
                            <th>Distribution</th>
                            <th>Source</th>
                            <th class="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="vouchers.length > 0">
                        <tr v-for="voucher in vouchers" :key="voucher.id">
                            <td>
                                <input type="checkbox" class="form-check-input" :checked="isSelected(voucher.id)"
                                    @change="toggleSelect(voucher.id)">
                            </td>
                            <td>
                                <p class="mb-0">{{ voucher.name }}</p>
                                <p class="text-secondary mb-0" v-if="voucher.description">{{ voucher.description }}</p>
                            </td>
                            <td>{{ voucher.code }}</td>
                            <td>{{ getDiscountAmountDisplay(voucher.discountAmount, voucher.isFixedDiscount) }}</td>
                            <td>{{ voucher.user?.email }}</td>
                            <td>{{ voucher.startedAt ? formatDate(voucher.startedAt, 'DD-MMM-YYYY') : '-' }}</td>
                            <td>{{ voucher.expiredAt ? formatDate(voucher.expiredAt, 'DD-MMM-YYYY') : '-' }}</td>
                            <td>{{ formatDate(voucher.createdAt, 'DD-MMM-YYYY') }}</td>
                            <td>{{ getUsageDisplay(voucher.extraAttributes) }}</td>
                            <td>{{ voucher.isViewable ? 'Yes' : 'No' }}</td>
                            <td>{{ voucher.batch }}</td>
                            <td>
                                <router-link v-if="voucher.distribution" :to="{
                                    name: 'voucher-distribution-show',
                                    params: { voucherDistributionId: voucher.distribution?.id }
                                }" class="text-primary">
                                    <div class="d-flex flex-column">
                                        <span>{{ voucher.distribution?.name }}</span>
                                        <span v-if="voucher.distribution?.no">({{ voucher.distribution?.no }})</span>
                                        <span
                                            v-if="!voucher.distribution?.name && !voucher.distribution?.no">View</span>
                                    </div>
                                </router-link>
                                <span v-else>-</span>
                            </td>
                            <td>
                                <template v-if="voucher.sourceable?.type === 'WooCouponOrder'">
                                    <div class="d-flex flex-column">
                                        <a :href="getWooCommerceOrderUrl(voucher.sourceable.orderNo)" target="_blank"
                                            class="text-decoration-none">
                                            #{{ voucher.sourceable.orderNo }}
                                        </a>
                                        <small class="text-muted">Kiddoshop (Website)</small>
                                    </div>
                                </template>
                                <template v-else-if="voucher.sourceable?.type === 'Order'">
                                    <div class="d-flex flex-column">
                                        <router-link
                                            :to="{ name: 'order-show', params: { orderId: voucher.sourceable.orderId } }"
                                            class="text-decoration-none">
                                            {{ voucher.sourceable.orderNo }}
                                        </router-link>
                                        <small class="text-muted">
                                            {{ getSalesChannelLabel(voucher.sourceable.salesChannel) }}
                                        </small>
                                    </div>
                                </template>
                                <template v-else>
                                    -
                                </template>
                            </td>
                            <td class="text-center">
                                <div class="btn-group">
                                    <button class="btn btn-icon btn-info"
                                        @click.prevent="openSendVoucherCodeModal(voucher)"
                                        :disabled="!voucher.adminCanResend">
                                        <icon name="mail" />
                                    </button>
                                    <router-link class="btn btn-icon btn-primary"
                                        :to="{ name: 'voucher-show', params: { voucherId: voucher.id } }">
                                        <icon name="eye" />
                                    </router-link>
                                    <router-link class="btn btn-icon btn-success"
                                        :to="{ name: 'voucher-edit', params: { voucherId: voucher.id } }">
                                        <icon name="pencil" />
                                    </router-link>
                                    <button class="btn btn-icon btn-danger" @click="deleteVoucher(voucher)">
                                        <icon name="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="9" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetching" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
            <SelectionFloatingPanel :count="selectedItems.size" @clear="clearSelection">
                <button class="btn btn-primary" @click="generateReport(true)" :disabled="isFetching">
                    <icon name="file-spreadsheet" />
                    <span>Download Selected</span>
                </button>
            </SelectionFloatingPanel>
        </div>
    </div>
</template>

<script setup lang="ts">
import ButtonFilter from '@/components/ButtonFilter.vue'
import DateRangePicker from '@/components/form/DateRangePicker.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import TablePagination from '@/components/TablePagination.vue'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import {
    getDiscountAmountDisplay,
    getUsageDisplay,
    getWooCommerceOrderUrl,
    getSalesChannelLabel
} from '@/composable/useVoucher'
import VoucherService from '@/services/settings/VoucherService'
import { useModalStore } from '@/stores/modalStore'
import { useNotificationsStore } from '@/stores/notifications'
import VoucherQuery from '@/types/query/VoucherQuery'
import Voucher from '@/types/Voucher'
import { defineAsyncComponent, markRaw, onMounted, ref, watch } from 'vue'
import { useTableSelection } from '@/composable/useTableSelection'
import SelectionFloatingPanel from '@/components/SelectionFloatingPanel.vue'

const { addToastNotification } = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()


const modelStore = useModalStore()
const { showModal, hideModal } = modelStore


const vouchers = ref<Array<Voucher>>([])


const inputFilter = ref({
    name: '',
    description: '',
    distribution: '',
    code: '',
    batch: '',
    email: '',
    start_date_from: '',
    start_date_to: '',
    expiry_date_from: '',
    expiry_date_to: '',
    created_at_from: '',
    created_at_to: '',
    is_viewable: '',
})


const isFetching = ref<boolean>(false)
const setIsFetching = (value = true) => isFetching.value = value

const {
    items: tableItems,
    selectedItems,
    isAllSelected,
    setItems,
    toggleSelectAll,
    toggleSelect,
    isSelected,
    clearSelection
} = useTableSelection<Voucher>()

onMounted(() => {
    fetchVouchers()
})

watch(
    () => metaPageTriggered.value,
    () => {
        fetchVouchers()
    }
)

const updateStartDateRange = (dateRange: { from_date: string; to_date: string }) => {
    inputFilter.value.start_date_from = dateRange.from_date
    inputFilter.value.start_date_to = dateRange.to_date
}

const updateExpiryDateRange = (dateRange: { from_date: string; to_date: string }) => {
    inputFilter.value.expiry_date_from = dateRange.from_date
    inputFilter.value.expiry_date_to = dateRange.to_date
}

const updateCreatedDateRange = (dateRange: { from_date: string; to_date: string }) => {
    inputFilter.value.created_at_from = dateRange.from_date
    inputFilter.value.created_at_to = dateRange.to_date
}


const resetFilter = () => {
    inputFilter.value = {
        name: '',
        description: '',
        distribution: '',
        code: '',
        batch: '',
        email: '',
        start_date_from: '',
        start_date_to: '',
        expiry_date_from: '',
        expiry_date_to: '',
        created_at_from: '',
        created_at_to: '',
        is_viewable: '',
    }

    fetchVouchers()
}

const fetchVouchers = async () => {
    setIsFetching()

    const query: VoucherQuery = {
        perPage: metaPage.value.perPage,
        page: metaPage.value.currentPage,
        name: inputFilter.value?.name,
        description: inputFilter.value?.description,
        code: inputFilter.value?.code,
        user_email: inputFilter.value?.email,
        distribution: inputFilter.value?.distribution,
        batch: inputFilter.value?.batch,
        started_at_from: inputFilter.value?.start_date_from,
        started_at_to: inputFilter.value?.start_date_to,
        expired_at_from: inputFilter.value?.expiry_date_from,
        expired_at_to: inputFilter.value?.expiry_date_to,
        created_at_from: inputFilter.value?.created_at_from,
        created_at_to: inputFilter.value?.created_at_to,
        is_viewable: inputFilter.value?.is_viewable,
    }


    try {
        const { data: { data, meta } } = await VoucherService.index(query)
        vouchers.value = data
        setItems(data)
        updateMetaPage(meta)
    } catch (e) {
        addToastNotification({
            message: 'Failed to fetch vouchers due to internal server error. Please contact tech team.',
            type: 'danger'
        })
    }

    setIsFetching(false)
}

const openSendVoucherCodeModal = async (voucher: Voucher) => {

    showModal({
        component: markRaw(defineAsyncComponent(() =>
            import('@/components/SendVoucherCode.vue')
        )),
        title: "Send Voucher Code",
        size: 'xl',
        props: {
            voucher: voucher,
            email: voucher.sourceable?.customerEmail || voucher.wooCouponOrder?.email || '',
            orderNo: voucher.sourceable?.orderNo || voucher.wooCouponOrder?.data?.items?.order_id || '',
            customerName: voucher.sourceable?.customerName || voucher.wooCouponOrder?.name || '',
            onSent: () => hideModal()
        }
    })
}

const deleteVoucher = (voucher: Voucher) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete voucher ${voucher.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then(async (result: any) => {

        if (!result.isConfirmed) {
            return
        }

        try {
            setIsFetching()
            await VoucherService.destroy(voucher.id)
            setIsFetching(false)

            addToastNotification({
                message: `Voucher ${voucher.name} is successfully deleted.`,
                type: 'success'
            })

            fetchVouchers()
        } catch {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    })
}

const generateReport = async (useSelectedOnly = false) => {
    const result = await window.Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to download vouchers?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    })

    // Only proceed if confirmed
    if (!result.isConfirmed) {
        return
    }

    // Set loading state after confirmation
    setIsFetching()

    try {
        let response

        if (useSelectedOnly) {
            // Use selected IDs for report generation
            const selectedIds = Array.from(selectedItems.value)
            response = await VoucherService.generateReport({ ids: selectedIds })
        } else {
            // Use filter parameters for report generation
            response = await VoucherService.generateReport({ ...inputFilter.value })
        }

        addToastNotification({
            message: response.data.message,
            type: 'success'
        })
    } catch (error) {
        addToastNotification({
            message: 'Failed to generate report. Please contact tech team.',
            type: 'danger'
        })
    } finally {
        setIsFetching(false)
    }
}

</script>
