<template>
    <div class="form-group mb-4 col">
        <label v-if="label" class="form-label col-3 col-form-label">
            {{ label }}<span v-if="required" class="text-red">*</span>
        </label>
        <div class="col">
            <vue-select :options="productOptions" @search="onSearchProduct" :model-value="modelValue"
                @update:model-value="updateModelValue" :reduce="(product: Product) => product.sales_channel_product_id" label="title" />
            <small class="text-red" v-if="error">{{ error }}</small>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, onMounted, PropType } from 'vue';
import ProductService from '@/services/ProductService';
import Product from '@/types/Product';
import _ from 'lodash/fp';

const props = defineProps({
    modelValue: [Number, String] as PropType<number | string>,
    label: {
        type: String,
    },
    required: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
    }
});

const emit = defineEmits(['update:modelValue']);
const productOptions = ref<Product[]>([]);

const updateModelValue = (value: number) => {
    emit('update:modelValue', value);
};

const onSearchProduct = (search: string, loading: Function) => {
    if (search.length) {
        searchProduct(search, loading);
    }
};

const searchProduct = _.debounce(350, (search, loading) => {
    loading(true);
    ProductService.getUniqueSaleChannel({ search })
        .then((data) => {
            productOptions.value = data.data
        })
        .finally(() => loading(false))
});

onMounted(() => {
    if (!props.modelValue) return;
    ProductService.show(props.modelValue,true).then((res) => {
        productOptions.value= [res.data]
        updateModelValue(res.data.sales_channel_product_id);

    })
})
</script>

<style scoped></style>
