<template>
    <div class="card">
        <div class="card-header border-0">
            <div class="d-flex flex-row align-items-center gap-4">
                <h4 class="text-uppercase bg-light m-0 py-2 px-3"
                    :class="[ ticketPlatform === 'customer' ? 'text-orange' : (ticketPlatform === 'carer' ? 'text-cyan' : 'text-pink') ]"
                >
                    {{ ticketTitle }}</h4>
                <ticket-type-stat :ticket-type="currentTicketType" @filter="filterByStatus"></ticket-type-stat>
            </div>
        </div>
        <div class="card-body">
            <!-- Ticket Type Description -->
            <template v-if="ticketDescription">
                <strong>Description</strong>
                <p>{{ ticketDescription }}</p>
            </template>

            <!-- Ticket Type Tasks -->
            <div v-if="ticketTasks.length">
                <strong>Tasks</strong>
                <ol>
                    <li v-for="task in ticketTasks">{{ task }}</li>
                </ol>
            </div>

            <!-- Ticket List -->
            <div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage"
                                  @goto-page="gotoPage"/>
            </div>
            <div class="table-responsive">
                <table class="table card-table text-nowrap">
                    <thead>
                    <tr>
                        <th style="width: 2%">ID</th>
                        <th class="col-1">Date</th>
                        <th class="col-1">Status</th>
                        <th v-for="(col, index) in ticketColumns" :key="index" :class="col.tdClass">{{ col.header }}
                        </th>
                        <th class="col-2">Assignee</th>
                        <th class="text-center">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="ticket in tickets" :key="ticket.id" v-if="tickets.length > 0">
                        <tr>
                            <td :class="{ 'border-bottom-0': ticket.remark }" style="width: 2%">#{{ ticket.id }}</td>
                            <td class="col-1" :class="{ 'border-bottom-0': ticket.remark }">
                                {{ formatDate(ticket.createdAt, 'DD MMM YYYY h:mm A') }}
                            </td>
                            <td class="col-1" :class="{ 'border-bottom-0': ticket.remark }">
                                <status-dropdown
                                    :ticket="ticket"
                                    :is-updating="isUpdatingStatus"
                                    @updated="updateStatus"
                                ></status-dropdown>
                            </td>
                            <td v-for="(col, index) in ticketColumns" :key="index" class="text-wrap"
                                :class="[col.tdClass, { 'border-bottom-0': ticket.remark }]">
                                <ticket-info-slot :col="col">
                                    <template v-slot:user>
                                        <div v-if="ticket.reporter">
                                            <div>[{{ ticket.reporter.mainProfile?.onlineId ?? 'N/A' }}]
                                                <router-link
                                                    :to="{ 'name': getShowReporterRouteName(ticket.type), params: { userId: ticket.reporter.id } }"
                                                    target="_blank" class="fw-bold">
                                                    {{ ticket.reporter.mainProfile?.fullName ?? ticket.reporter.name }}
                                                </router-link>
                                                <status-badge :show="ticket.reporter.isBanned" label="Banned"
                                                              color="red"></status-badge>
                                                <status-badge :show="ticket.reporter.isDeleted" label="Deleted"
                                                              color="gray"></status-badge>
                                            </div>
                                            <a :href="whatsappLink(ticket.reporter.mainProfile?.mobileNo)"
                                               target="_blank"
                                               v-if="isValidMobileNo(ticket.reporter.mainProfile?.mobileNo)"
                                            >{{ ticket.reporter.mainProfile?.mobileNo }}</a>
                                            <div v-else>{{ ticket.reporter.mainProfile?.mobileNo ?? 'N/A' }}</div>
                                        </div>
                                    </template>

                                    <template v-slot:mobile_no>
                                        <div v-if="ticket.reporter">
                                            <a :href="telLink(ticket.reporter.mainProfile?.mobileNo)"
                                               target="_blank"
                                               v-if="isValidMobileNo(ticket.reporter.mainProfile?.mobileNo)"
                                            >{{ ticket.reporter.mainProfile?.mobileNo }}</a>
                                            <div v-else>{{ ticket.reporter.mainProfile?.mobileNo ?? 'N/A' }}</div>
                                        </div>
                                    </template>

                                    <template v-slot:booking>
                                        <div v-if="ticket.booking">
                                            <div>{{ ticket.booking.no }}</div>
                                            <div>{{ ticket.booking.discount_code ?? '' }}</div>
                                        </div>
                                        <div v-else>N/A</div>
                                    </template>

                                    <template v-slot:session>
                                        <template v-if="ticket.session">
                                            <router-link
                                                :to="{ 'name': 'session-show', params: { sessionId: ticket.session.id } }"
                                                target="_blank" class="fw-bold">
                                                {{ ticket.session.no }}
                                            </router-link>
                                            <div>{{ formatDate(ticket.session.startedAt, 'DD MMM YYYY') }}</div>
                                            <div>{{ formatDate(ticket.session.startedAt, 'h:mm A') }} -
                                                {{ formatDate(ticket.session.endedAt, 'h:mm A') }}
                                            </div>
                                            <div v-if="ticket.type === 'session_review'">
                                                [{{ ticket.session.carer.mainProfile?.onlineId }}]
                                                <router-link
                                                    :to="{ 'name': 'carer-show', params: { userId: ticket.session.carer.id } }"
                                                    target="_blank" class="fw-bold">
                                                    {{ ticket.session.carer.mainProfile?.fullName }}
                                                </router-link>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="mr-3">Not specified</div>
                                        </template>
                                    </template>

                                    <template v-slot:reason>
                                        <div>{{ ticket.reason ?? '' }}</div>
                                    </template>

                                    <template v-slot:review>
                                        <div v-if="ticket.session?.carerSessionReview">
                                            <div>Rating: {{ ticket.session.carerSessionReview.rating }}</div>
                                            <div>{{ ticket.session.carerSessionReview.comment }}</div>
                                            <p class="my-2" v-for="answer in ticket.session.carerSessionReview.answers"
                                               :key="answer.sessionReviewId + answer.reviewQuestionId">
                                                {{ getQuestion(answer) }}
                                                <span class="badge"
                                                      :class="[ answer.content === true ? 'bg-green' : 'bg-red' ]">
                                                    {{ answer.content === true ? 'Yes' : 'No' }}
                                                </span>
                                            </p>
                                        </div>
                                    </template>

                                    <template v-slot:invoice>
                                        <div v-if="ticket.invoice">
                                            <div class="d-flex">
                                                <span class="me-2">{{ ticket.invoice.no }}</span>
                                                <span class="text-capitalize">({{ ticket.invoice.status }})</span>
                                            </div>
                                            <div>{{ formatDate(ticket.invoice.created_at, 'DD MMM YYYY') }}</div>
                                        </div>
                                        <div v-else>N/A</div>
                                    </template>

                                    <template v-slot:issue>
                                        <div v-if="ticket.issue">{{ ticket.issue }}</div>
                                        <div>{{ ticket.issueDescription ?? 'Not specified' }}</div>
                                    </template>

                                    <template v-slot:corporateInquiry>
                                        <div>{{ ticket.issueDescription }}</div>
                                    </template>

                                    <template v-slot:sessionCarerLog>
                                        <template v-if="ticket.session?.carerLogs">
                                            <TicketSessionCarerLogSlot :ticket="ticket"/>
                                        </template>
                                    </template>
                                </ticket-info-slot>
                            </td>
                            <td :class="{ 'border-bottom-0': ticket.remark }" class="col-2">
                                <div v-if="ticket.assignee">
                                    <span class="text-capitalize" v-if="ticket.assignee">{{
                                            ticket.assignee.name
                                        }} – </span>
                                    <a class="link-text" role="button" @click="updateAssignee(ticket, false)">unassign
                                        user</a>
                                </div>
                                <div v-else>
                                    <span>No one – </span>
                                    <a class="link-text" role="button" @click="updateAssignee(ticket, true)">assign
                                        yourself</a>
                                </div>
                            </td>
                            <td class="text-center" :class="{ 'border-bottom-0': ticket.remark }"
                                style="min-width: 80px; width: 4%;">
                                <div>
                                    <router-link :to="{ name: 'ticket-show', params: { ticketId: ticket.id } }">
                                        <icon name="eye" class="text-primary me-2"/>
                                    </router-link>
                                    <icon name="clipboard-list" role="button" data-bs-toggle="modal"
                                          data-bs-target="#modal-edit-remark" @click="openModal(ticket)"/>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="ticket.remark">
                            <td :colspan="ticketColumns.length + 3"></td>
                            <td colspan="2" class="col-2 pb-3">
                                <div class="border rounded py-2 px-2">
                                    <strong>Remark:</strong>
                                    <div class="pr-2" style="white-space: pre-wrap">{{ ticket.remark ?? '-' }}</div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <tr v-else>
                        <td :colspan="ticketColumns.length + 5" class="text-center">No data available</td>
                    </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingTickets"/>
            </div>
        </div>
        <kc-modal modal-id="modal-edit-remark" modal-size="lg">
            <div class="modal-header">
                <h4 class="modal-title">Remark</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        id="btnDismissModal"></button>
            </div>
            <form @submit.prevent="updateRemark">
                <div class="modal-body">
                    <div>
                        <textarea v-model="remarkInput" class="form-control" name="remark" rows="10" maxlength="10000" style="resize: none"></textarea>
                    </div>
                    <div class="text-end mt-1">
                        <span>{{ remarkInput?.length }} / 10000</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-light" type="button" data-bs-dismiss="modal">Cancel</button>
                    <button class="btn btn-primary" type="submit" :disabled="isUpdatingRemark">
                        <span class="spinner-border spinner-border-sm me-2" v-if="isUpdatingRemark"></span>
                        <span>{{ isUpdatingRemark ? 'Saving...' : 'Save' }}</span>
                    </button>
                </div>
            </form>
        </kc-modal>
    </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import StatusBadge from '@/components/StatusBadge.vue'
import TablePagination from '@/components/TablePagination.vue'
import {getValidMobileNo, isValidMobileNo} from '@/composable/profile'
import {formatDate} from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import {getColumns, getDescription, getPlatform, getTasks, getTitle} from '@/composable/useTicket'
import {SENDBIRD_CHANNEL_CUSTOM_TYPE_SERVICE, SENDBIRD_CHANNEL_CUSTOM_TYPE_SUPPORT} from '@/constants'
import {TicketType} from '@/enums/TicketTypeEnum'
import StatusDropdown from '@/pages/tickets/components/StatusDropdown.vue'
import TicketInfoSlot from '@/pages/tickets/components/TicketInfoSlot.vue'
import TicketSessionCarerLogSlot from '@/pages/tickets/components/TicketSessionCarerLogSlot.vue'
import TicketTypeStat from '@/pages/tickets/components/TicketTypeStat.vue'
import TicketService from '@/services/TicketService'
import {useGeneralStore} from '@/stores/general'
import {useNotificationsStore} from '@/stores/notifications'
import {useResourcesStore} from '@/stores/resources'
import {useTicketStore} from '@/stores/ticket'
import SessionReviewAnswer from '@/types/SessionReviewAnswer'
import {Ticket, TicketStatus} from '@/types/Ticket'
import {storeToRefs} from 'pinia'
import {computed, inject, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {SendbirdCustomTypeEnum} from '@/enums/SendbirdCustomTypeEnum'
import {TicketStatusEnum} from '@/enums/TicketStatusEnum'

const {isInitCompleted} = storeToRefs(useGeneralStore())
const {addToastNotification} = useNotificationsStore()
const ticketStore = useTicketStore()
const {tickets, currentTicketType, ticketTypes} = storeToRefs(ticketStore)
const {carerReviewQuestions} = storeToRefs(useResourcesStore())

const selectedTicket = ref<any>()
const route = useRoute()
const remarkInput = ref<string>('')
const isFetchingTickets = ref<boolean>(false)
const isUpdatingAssignee = ref<boolean>(false)
const isUpdatingRemark = ref<boolean>(false)
const isUpdatingStatus = ref<boolean>(false)
const statusFilter = ref<string>('')

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()

const ticketPlatform = computed(() => getPlatform(currentTicketType.value.name))
const ticketTitle = computed(() => getTitle(currentTicketType.value.name))
const ticketDescription = computed(() => getDescription(currentTicketType.value.name))
const ticketTasks = computed(() => getTasks(currentTicketType.value.name))
const ticketColumns = computed(() => getColumns(currentTicketType.value.name))

const calculateNewTickets: any = inject('calculateNewTickets')
const fetchCount: any = inject('fetchCount')

const listenChannel = () => {
    window.Echo.private('backoffice')
        .listen('.TicketCreated', ({model: ticket}: any) => {
            if (ticket.type === currentTicketType.value.name) {
                ++metaPage.value.total
                const isNewFilter = !statusFilter.value || statusFilter.value === 'new'
                const isLastPageFull = Math.ceil(metaPage.value.total / metaPage.value.perPage) !== metaPage.value.lastPage
                if (isNewFilter && isLastPageFull) {
                    ++metaPage.value.lastPage
                }
                if (isNewFilter && (metaPage.value.lastPage === metaPage.value.currentPage)) {
                    ++metaPage.value.to
                    tickets.value.push(ticket)
                }
            }

            updateCount(ticket.type, '', 'new')
        })
        .listen('.TicketUpdated', ({model: updatedTicket}: any) => {
            const ticketIndex = tickets.value.findIndex(ticket => ticket.id === updatedTicket.id)

            // update menu, submenu, ticketType count
            if (ticketIndex >= 0) {
                const oldStatus = tickets.value[ticketIndex].status
                const currentStatus = updatedTicket.status
                updateCount(updatedTicket.type, oldStatus, currentStatus)
            } else {
                fetchCount()
            }

            // update tickets
            if (currentTicketType.value.name === updatedTicket.type) {
                tickets.value.splice(ticketIndex, 1, updatedTicket)
                if (statusFilter.value && (updatedTicket.status !== statusFilter.value)) {
                    tickets.value = tickets.value.filter(ticket => ticket.id !== updatedTicket.id)
                }
            }
        })
}

const fetchTickets = (status: string = '', page: number = metaPage.value.currentPage) => {
    isFetchingTickets.value = true
    metaPage.value.perPage = 10
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page
        },
        status: statusFilter.value,
        type: currentTicketType.value.name
    }

    TicketService.index(query)
        .then(({data: {data, meta}}) => {
            tickets.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingTickets.value = false
        })
}

const filterByStatus = (status: string) => {
    statusFilter.value = status
    fetchTickets(status, 1)
}

const updateStatus = (row: any, selectedStatus: keyof TicketStatus) => {
    isUpdatingStatus.value = true
    const customTypeId = row.type === 'booking_payment_made' ? SENDBIRD_CHANNEL_CUSTOM_TYPE_SERVICE : SENDBIRD_CHANNEL_CUSTOM_TYPE_SUPPORT

    TicketService.updateStatus(row.id, {
        userId: row.reporter.id,
        customTypeId,
        status: selectedStatus
    })
        .then(() => {
            addToastNotification({message: `Ticket status has been updated`, type: 'success'})
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team if error persists.',
                type: 'danger'
            })
        })
        .finally(() => {
            isUpdatingStatus.value = false
        })
}

const updateAssignee = async (row: any, isAssign: boolean) => {
    isUpdatingAssignee.value = true

    const currentStatus: TicketStatusEnum = isAssign ? TicketStatusEnum.ATTENDING : TicketStatusEnum.NEW

    const customTypeId: SendbirdCustomTypeEnum = row.type === TicketType.BOOKING_PAYMENT_MADE
        ? SendbirdCustomTypeEnum.ID_SERVICE
        : SendbirdCustomTypeEnum.ID_SUPPORT

    const payload = {
        customTypeId,
        isAssign,
        status: currentStatus
    }

    try {
        const {data} = await TicketService.updateAssignee(row.id, payload)

        // Update ticket details
        const index = tickets.value.findIndex(ticket => ticket.id === row.id)
        if (index >= 0 && data.hasOwnProperty('assignee')) {
            tickets.value[index].status = data.status
            tickets.value[index].assignee = data.assignee
        }

        addToastNotification({message: `Ticket assignee has been updated`, type: 'success'})
    } catch (e) {
        addToastNotification({
            message: 'Internal server error. Please contact tech team if error persists.',
            type: 'danger'
        })
    }

    isUpdatingAssignee.value = false
}

const updateRemark = () => {
    isUpdatingRemark.value = true
    TicketService.updateRemark(selectedTicket.value.id, {remark: remarkInput.value})
        .then(() => {
            addToastNotification({message: `Ticket remark has been updated`, type: 'success'})

            setTimeout(() => {
                document.getElementById(`btnDismissModal`)?.click()
            }, 300)
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team if error persists.',
                type: 'danger'
            })
        })
        .finally(() => {
            isUpdatingRemark.value = false
        })
}

const openModal = (ticket: Ticket) => {
    selectedTicket.value = ticket
    remarkInput.value = ticket.remark
}

const telLink = (val: string) => {
    let mobileNo = val.split(/\D/).join('')
    let link = 'tel:+'

    let validMobileNo = getValidMobileNo(mobileNo)

    if (validMobileNo != '')
        return link + validMobileNo
}

const whatsappLink = (val: string) => {
    let mobileNo = val.split(/\D/).join('')
    let link = 'https://api.whatsapp.com/send?phone='

    let validMobileNo = getValidMobileNo(mobileNo)

    if (validMobileNo != '')
        return link + validMobileNo
}

const updateCount = (type: string, oldStatus: string, currentStatus: string) => {
    const ticketTypeIndex = ticketTypes.value.findIndex(ticketType => ticketType.name === type)
    const ticketType = ticketTypes.value[ticketTypeIndex]
    const count = oldStatus ? {
        ...ticketType.count,
        [currentStatus]: ++ticketType.count[currentStatus],
        [oldStatus]: --ticketType.count[oldStatus]
    } : {...ticketType.count, new: ++ticketType.count.new}

    ticketTypes.value.splice(ticketTypeIndex, 1, {...ticketType, count})
    currentTicketType.value.count = count
    calculateNewTickets()
}

const getQuestion = (sessionReviewAnswer: SessionReviewAnswer) => {
    const question = carerReviewQuestions.value.find(question => question.id === sessionReviewAnswer.reviewQuestionId)

    if (question) {
        return question.content
    }
}

const getShowReporterRouteName = (ticketType: string) => {
    let routeName = ''
    switch (ticketType) {
        case TicketType.CARER_SUPPORT:
        case TicketType.BOOKING_PAYMENT_MADE:
        case TicketType.JOB_CARER_CANCELLED:
        case TicketType.CARER_REGISTRATION:
        case TicketType.CARER_PROFILE_UPDATE:
        case TicketType.CARER_VIDEO_UPLOAD_SUCCESS:
        case TicketType.CARER_VIDEO_UPLOAD_ERROR:
        case TicketType.CARER_TRAINING_ALMOST_COMPLETED:
        case TicketType.CORPORATE_INQUIRY:
            routeName = 'carer-show'
            break
        default:
            routeName = 'customer-show'
    }
    return routeName
}

watch(() => [currentTicketType.value.name, metaPageTriggered.value],
    () => fetchTickets()
)

watch(
    () => isInitCompleted.value,
    () => listenChannel()
)

fetchTickets()

</script>

<style scoped>
td {
    font-size: .75rem;
}
</style>

