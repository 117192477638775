import httpClientV2 from '@/services/HttpClientV2'

const endpoint = `backoffice/carer-payout-recipients`

export default {
    show(carerPayoutRecipientId: number) {
        return httpClientV2.get(`${endpoint}/${carerPayoutRecipientId}`)
    },

    store(data: object = {}) {
        return httpClientV2.post(`${endpoint}`, data)
    },

    destroy(carerPayoutRecipientId: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${carerPayoutRecipientId}`, data)
    }
}
