<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex mb-3">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right" :to="{ name: 'session-create' }">
                        <icon name="plus" />
                        Add New Session
                    </router-link>
                </div>
            </div>
            <div class="container mb-3">
                <div class="row row-cols-2 g-3">
                    <div class="col-12 col-md-6 d-flex">
                        <h2 role="button" @click="fetchLiveUpdate(SessionLiveUpdateTypeEnum.TODAY_ALL)">Today's sessions
                            ({{ totalTodaySessions }})</h2>
                    </div>
                    <div class="col-12 col-md-6 d-flex">
                        <h2 role="button" @click="fetchLiveUpdate(SessionLiveUpdateTypeEnum.PREVIOUS_INCOMPLETE)">
                            Previous incomplete sessions ({{ totalPreviousIncomplete }})</h2>
                    </div>
                    <div class=" col-12 col-md-6">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="card bg-blue" role="button"
                                    @click="fetchLiveUpdate(SessionLiveUpdateTypeEnum.TODAY_UPCOMING)">
                                    <div class="card-body">
                                        <h2 class="card-title">Upcoming</h2>
                                        <h2 class="card-subtitle text-white">{{ liveUpdateSessions.today?.[5] }}</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="card" :class="[getSessionStatusBadge(SessionStatusEnum.ID_IN_SESSION)]"
                                    role="button" @click="fetchLiveUpdate(SessionLiveUpdateTypeEnum.TODAY_IN_SESSION)">
                                    <div class="card-body">
                                        <h2 class="card-title">In Session</h2>
                                        <h2 class="card-subtitle text-white">{{ liveUpdateSessions.today?.[6] }}</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="card" :class="[getSessionStatusBadge(SessionStatusEnum.ID_COMPLETE)]"
                                    role="button" @click="fetchLiveUpdate(SessionLiveUpdateTypeEnum.TODAY_COMPLETED)">
                                    <div class="card-body">
                                        <h2 class="card-title">Completed</h2>
                                        <h2 class="card-subtitle text-white">{{ totalTodayCompleted }}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col">
                        <div class="row">
                            <div class="col-12 col-md">
                                <div class="card bg-red" role="button"
                                    @click="fetchLiveUpdate(SessionLiveUpdateTypeEnum.PREVIOUS_PAID)">
                                    <div class="card-body">
                                        <h2 class="card-title">Paid</h2>
                                        <h2 class="card-subtitle text-white">{{ liveUpdateSessions.previous?.[5] }}</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md">
                                <div class="card bg-orange" role="button"
                                    @click="fetchLiveUpdate(SessionLiveUpdateTypeEnum.PREVIOUS_IN_SESSION)">
                                    <div class="card-body">
                                        <h2 class="card-title">In Session</h2>
                                        <h2 class="card-subtitle text-white">{{ liveUpdateSessions.previous?.[6] }}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="progress">
                            <div class="progress-bar bg-blue" role="progressbar"
                                :style="{ width: progressBarWidth(liveUpdateSessions.today?.[5], totalTodaySessions) + '%' }"
                                aria-valuenow="{{ progressBarWidth(liveUpdateSessions.today?.[5], totalTodaySessions) }}"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar" :class="[getSessionStatusBadge(SessionStatusEnum.ID_IN_SESSION)]"
                                role="progressbar"
                                :style="{ width: progressBarWidth(liveUpdateSessions.today?.[6], totalTodaySessions) + '%' }"
                                aria-valuenow="{{ progressBarWidth(liveUpdateSessions.today?.[6], totalTodaySessions) }}"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar" :class="[getSessionStatusBadge(SessionStatusEnum.ID_COMPLETE)]"
                                role="progressbar"
                                :style="{ width: progressBarWidth(totalTodayCompleted, totalTodaySessions) + '%' }"
                                aria-valuenow="{{ progressBarWidth(totalTodayCompleted, totalTodaySessions) }}"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="progress">
                            <div class="progress-bar bg-red" role="progressbar"
                                :style="{ width: progressBarWidth(liveUpdateSessions.previous?.[5], totalPreviousIncomplete) + '%' }"
                                aria-valuenow="{{ progressBarWidth(liveUpdateSessions.previous?.[5], totalPreviousIncomplete) }}"
                                aria-valuemin="0" aria-valuemax="100"></div>
                            <div class="progress-bar bg-orange" role="progressbar"
                                :style="{ width: progressBarWidth(liveUpdateSessions.previous?.[6], totalPreviousIncomplete) + '%' }"
                                aria-valuenow="{{ progressBarWidth(liveUpdateSessions.previous?.[5], totalPreviousIncomplete) }}"
                                aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary mb-3" data-bs-toggle="collapse" href="#filter" aria-expanded="false"
                aria-controls="filter">
                <icon name="filter" />
                Show filter
            </button>

            <SessionFilter id="filter" @filter="filterSession" :is-loading="isFetchingSessions" />

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />

            <div class="table-responsive">
                <table class="table table-borderless table-vcenter">
                    <thead>
                        <tr>
                            <th>Session No</th>
                            <th class="text-center">Session Type</th>
                            <th class="text-center">Service Type</th>
                            <th>Status</th>
                            <th>Event</th>
                            <th class="text-center">Date</th>
                            <th class="text-center">Time</th>
                            <th>Address</th>
                            <th>Customer</th>
                            <th>Carer</th>
                            <th class="text-end">Actions</th>
                        </tr>
                    </thead>
                    <template v-if="sessions.length > 0">
                        <tbody v-for="session in sessions">
                            <tr :key="session.id" :style="[session.tags.isUrgent ? 'background-color: #fbebeb' : '']">
                                <td>
                                    <SessionNo :session-id="session.id" :session-no="session.no" />
                                </td>
                                <td>
                                    <SessionTypeBadge :session-type-id="session.sessionTypeId"
                                        :session-types="sessionTypes" />
                                </td>
                                <td>
                                    <SessionServiceTypeBadge :session-service-type-id="session.sessionServiceTypeId"
                                        :session-service-types="sessionServiceTypes" />
                                </td>
                                <td>
                                    <div class="d-flex space-x-2">
                                        <SessionChangeStatus :session="session" />
                                    </div>
                                </td>
                                <td>
                                    <button @click="openMonitoringModal(session)" class="btn btn-sm w-full"
                                        :class="getSessionMonitoringClass(session.event)">
                                        {{ getSessionMonitoringLabel(session.event) }}
                                    </button>
                                </td>
                                <td class="text-center">
                                    <div>{{ formatDate(session.startedAt, 'DD-MMM-YYYY') }}</div>
                                    <div>{{ formatDate(session.startedAt, '(dddd)') }}</div>
                                </td>
                                <td class="text-center">
                                    <div>{{ formatDate(session.startedAt, 'hh:mm A') }} -
                                        {{ formatDate(session.endedAt, 'hh:mm A') }}
                                    </div>
                                    <div>({{ `${session.totalHours} hours` }})</div>
                                </td>
                                <td>{{
                                    session.address ? getShortAddress(session.address) : null
                                    }}
                                </td>
                                <td>
                                    <div>{{ `[${session.customer.mainProfile?.onlineId ?? 'N/A'}]` }}</div>
                                    <router-link class="fw-bold"
                                        :to="{ name: 'customer-show', params: { userId: session.customerUserId } }">
                                        {{ session.customer.mainProfile?.fullName ?? session.customer.name }}
                                    </router-link>
                                </td>
                                <td v-if="session.carer">
                                    <div>{{ `[${session.carer.mainProfile?.onlineId ?? 'N/A'}]` }}</div>
                                    <router-link class="fw-bold"
                                        :to="{ name: 'carer-show', params: { userId: session.carerUserId } }">
                                        {{ session.carer.mainProfile?.fullName ?? session.carer.name }}
                                    </router-link>
                                </td>
                                <td v-else>N/A</td>
                                <td class="text-end">
                                    <div class="btn-group gap-0.5">

                                        <!-- View Session Details -->
                                        <router-link :to="{ name: 'session-show', params: { sessionId: session.id } }"
                                            class="btn-with-tooltip">
                                            <span class="btn btn-icon btn-outline">
                                                <icon name="eye" />
                                            </span>
                                            <span class="tooltip">View Details</span>
                                        </router-link>

                                        <!-- Edit Session Details Modal -->
                                        <div class="btn-with-tooltip">
                                            <button @click="openEditSessionModal(session)"
                                                class="btn btn-icon btn-outline">
                                                <icon name="pencil" />
                                            </button>
                                            <span class="tooltip">Edit Session</span>
                                        </div>
                                        <CreateOrderFromSessionModal :session="session" :customer="session.customer"
                                            :disabled="!session.isReadyForOrder" />
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="session.tags">
                                <td colspan="11">
                                    <SessionTags :tags="session.tags" />
                                </td>
                            </tr>
                        </tbody>
                    </template>
                    <template v-else>
                        <tbody>
                            <tr>
                                <td colspan="11" class="text-center">No data</td>
                            </tr>
                        </tbody>
                    </template>
                </table>
                <overlay-spinner :is-showing="isFetchingSessions" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
    </div>

    <!-- <SessionEditFormModal :session="modalForm" :fromOrder="false" @session-updated="fetchSession" /> -->
</template>

<script setup lang="ts">
import useBooking from '@/composable/useBooking'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import { getSessionStatusBadge } from '@/composable/useSession'
import { getShortAddress } from '@/composable/useAddress'
import { SessionLiveUpdateTypeEnum } from '@/enums/SessionLiveUpdateTypeEnum'
import { SessionStatusEnum } from '@/enums/SessionStatusEnum'
import SessionEditFormModal from '@/pages/sessions/SessionEditFormModal.vue'
import CreateOrderFromSessionModal from '@/pages/sessions/components/CreateOrderFromSessionModal.vue'
import SessionChangeStatus from '@/pages/sessions/components/SessionChangeStatus.vue'
import SessionTags from '@/pages/sessions/components/SessionTags.vue'
import SessionFilter from '@/pages/sessions/components/SessionFilter.vue'
import SessionMonitoringQuickView from '@/components/SessionMonitoringQuickView.vue'
import SessionServiceTypeBadge from '@/pages/sessions/components/SessionServiceTypeBadge.vue'
import SessionTypeBadge from '@/pages/sessions/components/SessionTypeBadge.vue'
import SessionService from '@/services/SessionService'
import { useGeneralStore } from '@/stores/general'
import { useResourcesStore } from '@/stores/resources'
import UserFilter from '@/types/Filter'
import Session from '@/types/Session'
import SessionLiveUpdate from '@/types/SessionLiveUpdate'
import SessionStatus from '@/types/SessionStatus'
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, markRaw, ref, watch } from 'vue'
import SessionNo from '@/components/SessionNo.vue'
import SessionEvent from '@/types/SessionEvent'
import { useModalStore } from '@/stores/modalStore'
import { useSessionStore } from '@/stores/sessionStore'

const {
    sessionServiceTypes,
    sessionTypes,
    sessionEventTypes,
} = storeToRefs(useResourcesStore())
const sessions = ref<Array<Session>>([])
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()

const generalStore = useGeneralStore()
const { getChildrenString } = useBooking()

const modalStore = useModalStore()
const { showModal, hideModal } = modalStore

const sessionStore = useSessionStore()
const { currentSession } = storeToRefs(sessionStore)

const inputFilter = ref<UserFilter>({
    session_no: '',
    order_no: '',
    customer_id: '',
    customer: '',
    carer: '',
    status: '',
    session_type: '',
    session_service_type: '',
    session_date_from: '',
    session_date_to: '',
    live_update: '',
    address: '',
    address_postcode: '',
    address_state: '',
})

const liveUpdateSessions = ref<SessionLiveUpdate>({
    today: {
        5: 0,
        6: 0,
        7: 0,
        8: 0,
    },
    previous: {
        5: 0,
        6: 0,
    },
})

const totalTodaySessions = ref(0)
const totalTodayCompleted = ref(0)
const totalPreviousIncomplete = ref(0)

const isFetchingSessions = ref<boolean>(false)
const modalForm = ref<any>({})

fetchSession()

watch(
    () => metaPageTriggered.value,
    () => {
        fetchSession()
    }
)

watch(
    () => generalStore.isInitCompleted,
    (isCompleted) => {
        if (isCompleted) {
            initChannelListeners()
        }
    }
)

const resetInputFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }
}

const filterSession = (data: UserFilter) => {
    inputFilter.value = data
    fetchSession()
}

function fetchSession() {
    isFetchingSessions.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage
        },
        ...inputFilter.value
    }

    SessionService.index(query)
        .then(({ data: { data, meta } }) => {
            sessions.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingSessions.value = false
        })
}

const liveUpdate = () => {
    SessionService.liveUpdate()
        .then(({ data }) => {
            liveUpdateSessions.value = data
        })
}

liveUpdate()

const progressBarWidth = (value: number, total: number) => {
    return value / total * 100
}

const fetchLiveUpdate = (liveUpdateType: number) => {
    resetInputFilter()

    inputFilter.value.hasFilter = true
    inputFilter.value.live_update = liveUpdateType

    fetchSession()
}


const getSessionMonitoringLabel = (sessionEvent: SessionEvent | null = null) => {
    if (!sessionEvent) {
        return 'Not Started'
    }
    const status = sessionEventTypes.value.find(type => type.id === sessionEvent?.sessionEventTypeId)
    return status?.adminLabel ?? 'N/A'
}


const getSessionMonitoringClass = (sessionEvent: SessionEvent | null = null) => {
    return !!sessionEvent ? 'bg-success' : 'bg-secondary'
}

const openMonitoringModal = (session: Session) => {

    currentSession.value = session

    showModal({
        component: markRaw(defineAsyncComponent(() =>
            import('@/components/SessionMonitoringQuickView.vue')
        )),
        title: `Session ${session.no}`,
        size: 'xl',
        props: { session }
    })
}

const openEditSessionModal = (session: Session) => {
    currentSession.value = session

    showModal({
        component: markRaw(defineAsyncComponent(() =>
            import('@/pages/sessions/SessionEditFormModal.vue')
        )),
        title: `Session ${session.no}`,
        size: 'xl',
        props: {
            onSessionUpdated: () => hideModal()
        }
    })
}


watch(
    () => liveUpdateSessions.value.today,
    () => {
        const todaySessions = liveUpdateSessions.value.today
        const previousSessions = liveUpdateSessions.value.previous

        totalTodaySessions.value = todaySessions
            ? Object.values(todaySessions).reduce((acc, val) => acc + val, 0)
            : 0

        totalTodayCompleted.value = todaySessions ? todaySessions[7] + todaySessions[8] : 0

        totalPreviousIncomplete.value = previousSessions
            ? previousSessions[5] + previousSessions[6]
            : 0
    }
)

const initChannelListeners = () => {
    window.Echo.private('backoffice')
        .listen('.SessionCreated', ({ model }: any) => {
            if (metaPage.value.currentPage === 1) {
                sessions.value.unshift(model)

                if (sessions.value.length === metaPage.value.perPage) {
                    sessions.value.pop()
                }

                let to = metaPage.value.to + 1
                to = Math.min(to, metaPage.value.perPage)

                const total = metaPage.value.total + 1
                const lastPage = Math.ceil(total / metaPage.value.perPage)

                updateMetaPage({
                    ...metaPage.value,
                    current_page: metaPage.value.currentPage,
                    last_page: lastPage,
                    to: to,
                    total: total,
                })
            }
        })
        .listen('.SessionUpdated', ({ model }: any) => {
            const index = sessions.value.findIndex(session => session.id === model.id)

            if (index !== -1) {
                sessions.value[index] = model
            }

            const startedAtDate = new Date(model.startedAt)
            const today = new Date()

            if (startedAtDate.toDateString() === today.toDateString()) {
                liveUpdate()
            }
        })
}

</script>

<style scoped>
.progress {
    height: 25px;
}
</style>
