<template>
    <router-link :to="{ name: 'payout-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left" />
        <span>Back to Payout</span>
    </router-link>
    <div class="page-header">
        <div class="row align-items-center justify-content-between">
            <div class="col-sm-12 col-md-11 d-flex align-items-center mb-3">
                <h2 class="page-title text-gray-800">
                    Payout Details
                </h2>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <ol class="breadcrumb">
<!--            <li class="breadcrumb-item">-->
<!--                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--                    <span>-->
<!--                        <span v-if="isDraft" class="badge bg-yellow mr-1">Draft</span>-->
<!--                        {{ isJobList ? 'Job List' : 'Recipient List' }} {{ payout.cutOffAt ? `(${payout.cutOffAt})` : '' }}-->
<!--                    </span>-->
<!--                </a>-->
<!--                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">-->
<!--                    <router-link class="dropdown-item"-->
<!--                                 :class="{ active: (route.name === 'payout-recipient' || route.name === 'payout-item') }"-->
<!--                                 :to="{ name: 'payout-recipient', params: { payoutId: payout.id } }">-->
<!--                        Recipient List-->
<!--                    </router-link>-->
<!--                    <router-link class="dropdown-item"-->
<!--                                 :class="{ active: route.name === 'payout-job' }"-->
<!--                                 :to="{ name: 'payout-job', params: { payoutId: payout.id } }">-->
<!--                        Job List-->
<!--                    </router-link>-->
<!--                </div>-->
<!--            </li>-->
<!--            <li class="breadcrumb-item" v-if="route.name.includes('payout-item')">-->
<!--                {{ recipientSummary.carer.onlineId ? `[${recipientSummary.carer.onlineId}]` : '' }} {{ recipientSummary.carer.fullName }}-->
<!--            </li>-->
        </ol>
        
        <div class="btn-list">
            <button class="btn btn-info" @click="exportPayoutJob" :disabled="isExportingPayoutJob" v-if="route.name === 'payout-job'">
                <icon name="file-download" />Download
            </button>
            
            <button class="btn btn-primary" @click="confirmPayout" v-if="isDraft">
                <icon name="check" />Confirm
            </button>
            <button class="btn btn-danger" @click="deletePayout" v-if="isDraft">
                <icon name="trash" />Delete
            </button>
        </div>
    </div>

    <div class="card mt-2 mb-4">
        <div class="table-responsive">
            <table
                class="table table-vcenter table-mobile-md card-table">
                <thead>
                <tr class="text-center">
                    <th class="text-start">Cut Off Date</th>
                    <th>Confirmed Date</th>
                    <th>Carers</th>
                    <th>Sessions</th>
                    <th>Total Payout</th>
                </tr>
                </thead>
                <tbody>
                <tr class="text-center">
                    <td class="text-start">{{ formatDate(payout.cutOffAt, 'DD-MMM-YYYY') }}</td>
                    <td>
                        <div v-if="!isDraft">{{ formatDate(payout.confirmedAt, 'DD-MMM-YYYY') }}</div>
                        <div v-else><span class="badge badge-pill bg-warning">Draft</span></div>
                    </td>
                    <td>{{ payout.totalRecipients }}</td>
                    <td>{{ payout.totalSessions }}</td>
                    <td>{{ toMYR(payout.totalAmount) }}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="openAddCarerModal" v-if="isDraft">
            <icon name="plus" />Add Carer
        </button>
    </div>

    <div class="card my-2">
        <div class="table-responsive">
            <table
                class="table table-vcenter table-mobile-md card-table">
                <thead>
                <tr>
                    <th></th>
                    <th>Carer ID</th>
                    <th>Type</th>
                    <th>Name</th>
                    <th class="text-center">Bank</th>
                    <th class="text-center">Sessions</th>
                    <th class="text-end">Total</th>
                    <th class="w-1"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(recipient, index) in payout.recipients" :key="recipient.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ recipient.carerUser.mainProfile?.onlineId }}</td>
                    <td>{{ carerTypes.find((findCarerType: CarerType) => findCarerType.id === recipient.carerUser.carer.carerTypeId)?.name }}</td>
                    <td>{{ recipient.carerUser.mainProfile?.fullName }}</td>
                    <td class="text-center">
                        <div>{{ banks.find((findBank: Bank) => findBank.id === recipient.bankId)?.name }}</div>
                        <div>({{ recipient.bankAccountNo }})</div>
                    </td>
                    <td class="text-center">{{ recipient.metadata.totalSession }}</td>
                    <td class="text-end">{{ toMYR(recipient.amount) }}</td>
                    <td>
                        <div class="btn-list flex-nowrap">
                            <router-link :to="{ name: 'payout-recipient', params: { carerPayoutId: payout.id, userId: recipient.userId } }" class="btn btn-sm btn-primary">
                                View
                            </router-link>
                            <button class="btn btn-sm btn-danger" @click="removeRecipientFromPayout(recipient.id)" v-if="isDraft">Remove</button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <overlay-spinner :is-showing="isFetchingPayoutDetail" />

</template>

<script setup lang="ts">
import { toMYR } from '@/composable/useCurrency'
import { formatDate } from '@/composable/useDate'
import { CarerPayoutStatusEnum } from '@/enums/CarerPayoutEnum'
import router from '@/router'
import PayoutRecipientService from '@/services/PayoutRecipientService'

import PayoutService from '@/services/PayoutService'
import { useModalStore } from '@/stores/modalStore'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import { PayoutInit } from '@/types/CarerPayout'
import Bank from '@/types/CarerType'
import CarerType from '@/types/CarerType'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, markRaw, ref } from 'vue'
import { useRoute } from 'vue-router'

const { addToastNotification } = useNotificationsStore()
const { carerTypes, banks } = storeToRefs(useResourcesStore())

const route = useRoute()
const payout = ref<any>(PayoutInit)
const isFetchingPayoutDetail = ref<boolean>(false)
const isExportingPayoutJob = ref<boolean>(false)
const isDraft = computed(() => [CarerPayoutStatusEnum.STATUS_DRAFT, CarerPayoutStatusEnum.STATUS_GENERATING].includes(payout.value.status))

const modalStore = useModalStore()
const { showModal, hideModal } = modalStore

const fetchPayoutDetails = () => {
    isFetchingPayoutDetail.value = true

    PayoutService.show(Number(route.params.payoutId))
        .then(({ data: { data } }) => {
            payout.value = data
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team if error persists.',
                type: 'danger'
            })
        })
        .finally(() => isFetchingPayoutDetail.value = false)
}

const exportPayoutJob = () => {

}

const confirmPayout = () => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `All payout details have been confirmed?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result: any) => {
        if (result.isConfirmed) {
            PayoutService.confirm(payout.value.id)
                .then(() => {
                    addToastNotification({ message: `Payout draft has been confirmed.`, type: 'success' })
                })
                .catch(({ response: { data, status } }) => {
                    let errorMessage = 'Internal server error. Please contact tech team if error persists.'

                    if (status === 400) {
                        errorMessage = data.message
                    }

                    addToastNotification({
                        message: errorMessage,
                        type: 'danger'
                    })
                })
                .finally(() => {
                })
        }
    })
}

const deletePayout = () => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Delete this payout draft?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result: any) => {
        if (result.isConfirmed) {
            PayoutService.destroy(payout.value.id)
                .then(() => {
                    addToastNotification({ message: `Payout draft has been deleted.`, type: 'success' })
                    router.push({ name: 'payout-index' })
                })
                .catch(() => {
                    addToastNotification({
                        message: `Internal server error. Please contact tech team if the error persists.`,
                        type: 'danger'
                    })
                })
                .finally(() => {
                })
        }
    })
}

const openAddCarerModal = () => {
    showModal({
        component: markRaw(defineAsyncComponent(() =>
            import('@/components/payouts/AddCarerModal.vue')
        )),
        title: `Add Carer to Payout`,
        size: 'xl',
        props: { payoutId: payout.value.id },
        events: {
            onSaved: () => {
                fetchPayoutDetails()
                hideModal()
            }
        }
    })
}

const removeRecipientFromPayout = (carerPayoutRecipientId: number) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Remove this recipient from draft payout?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result: any) => {
        if (result.isConfirmed) {
            PayoutRecipientService.destroy(carerPayoutRecipientId, { 'payout_id': payout.value.id })
                .then(() => {
                    fetchPayoutDetails()
                    addToastNotification({ message: `Recipient has been removed.`, type: 'success' })
                })
                .catch(({ response: { data, status } }) => {
                    let errorMessage = 'Internal server error. Please contact tech team if error persists.'

                    if (status === 400) {
                        errorMessage = data.message
                    }

                    addToastNotification({
                        message: errorMessage,
                        type: 'danger'
                    })
                })
                .finally(() => {
                })
        }
    })
}

window.Echo.private('backoffice')
    .listen('.CarerPayoutUpdated', ({ model }: any) => payout.value = model)

fetchPayoutDetails()
</script>

<style scoped>
td {
    font-size: 13px;
}
</style>