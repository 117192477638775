<template>
    <router-link :to="{ 'name': 'voucher-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left" />
        <span>Back to Vouchers List</span>
    </router-link>

    <overlay-spinner :is-showing="isFetching" />
    <template v-if="voucher">
        <div class="page-header mb-4">
            <div class="row align-items-center">
                <div class="col">
                    <h2 class="page-title">{{ voucher.name }}</h2>
                    <p class="text-muted">{{ voucher.description }}</p>
                </div>
                <div class="col-auto d-none d-md-flex">
                    <div class="btn-group">
                        <button class="btn btn-icon btn-info" @click.prevent="openSendVoucherCodeModal(voucher)"
                            :disabled="!voucher.adminCanResend">
                            <icon name="mail" />
                        </button>
                        <router-link class="btn btn-icon btn-success"
                            :to="{ name: 'voucher-edit', params: { voucherId: voucher.id } }">
                            <icon name="pencil" />
                        </router-link>
                        <button class="btn btn-icon btn-danger" @click="deleteVoucher(voucher)">
                            <icon name="trash" />
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Code</span>
                        </p>
                        <span class="fw-bold">{{ voucher.code }}</span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Discount</span>
                        </p>
                        <span class="fw-bold text-capitalize">
                            {{ getDiscountAmountDisplay(voucher.discountAmount, voucher.isFixedDiscount) }}
                        </span>
                    </div>
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Owner</span>
                        </p>
                        <UserNameId v-if="voucher.user" :user="voucher.user" :role="RoleNameEnum.USER" inline />
                        <span v-else class="fw-bold text-capitalize"> - </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Started At</span>
                        </p>
                        <span class="fw-bold text-capitalize">
                            {{
                                voucher.startedAt
                                    ? formatDate(voucher.startedAt, 'DD-MMM-YYYY')
                                    : '-'
                            }}
                        </span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Expired At</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{
                            voucher?.expiredAt ? formatDate(voucher?.expiredAt, 'DD-MMM-YYYY') : '-'
                        }}</span>
                    </div>
                    <div v-if="voucher.distribution" class="col-md-6 col-lg-4 mb-4">
                        <p>
                            <span>Distribution</span>
                        </p>
                        <router-link
                            :to="{ name: 'voucher-distribution-show', params: { voucherDistributionId: voucher.distribution?.id } }"
                            class="fw-bold text-capitalize">
                            {{ voucher.distribution?.name ?? '-' }}
                        </router-link>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p>
                            <span>Batch No</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ voucher.batch ?? '-' }}</span>
                    </div>
                </div>
                <hr>
                <h3 class="mb-3">Additional Info</h3>
                <div class="row">
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Number of Max Usage</span>
                        </p>
                        <span class="fw-bold">{{ voucher.extraAttributes.maxUses ?? '&infin;' }}</span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Number of Max Usage Per User</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{
                            voucher.extraAttributes.maxUsesPerUser ?? '&infin;'
                        }}</span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Number of Uses</span>
                        </p>
                        <span class="fw-bold text-capitalize">
                            {{ getUsageDisplay(voucher.extraAttributes) }}
                        </span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Minimum Sessions to Applicable</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{ voucher.extraAttributes.minSessions ?? '-' }}</span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Minimum Hours to Applicable</span>
                        </p>
                        <span class="fw-bold text-capitalize">{{
                            voucher.extraAttributes.minHoursPerSession ?? '-'
                        }}</span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Applicable in Public Holiday</span>
                        </p>
                        <span class="fw-bold text-capitalize">
                            {{ voucher.extraAttributes.isAvailableOnHolidays ? 'Yes' : 'No' }}
                        </span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Application Type</span>
                        </p>
                        <p class="form-control-static">
                            {{ VoucherDiscountApplicationTypeLabels[voucher.extraAttributes.discountApplicationType] }}
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Voucher Ownership</span>
                        </p>
                        <span class="fw-bold text-capitalize">
                            {{ voucher.extraAttributes.isVoucherOwnership ? 'Yes' : 'No' }}
                        </span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Extension Rate used for Extension Hours?</span>
                        </p>
                        <span class="fw-bold text-capitalize">
                            {{ voucher.extraAttributes.applyExtensionRateToExtraHours ? 'Yes' : 'No' }}
                        </span>
                    </div>
                    <div class="col-md-6 col-lg-4 mb-4">
                        <p class="text-muted mb-1">
                            <span>Voucher Owner</span>
                        </p>
                        <UserNameId v-if="voucher.user" :user="voucher.user" :role="RoleNameEnum.USER" inline />
                        <span v-else>-</span>
                    </div>
                </div>
                <template v-if="voucher.sourceable || voucher.wooCouponOrder">
                    <hr>
                    <h3 class="mb-3">Voucher order details</h3>
                    <div class="row">
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Name</span>
                            </p>
                            <span class="fw-bold">
                                <template v-if="voucher.sourceable?.type === 'order'">
                                    <router-link
                                        :to="{ name: 'customer-show', params: { userId: voucher.sourceable.customerId } }">
                                        {{ voucher.sourceable.customerName }}
                                    </router-link>
                                </template>
                                <template v-else>
                                    {{
                                        voucher.sourceable?.customerName
                                        || voucher.wooCouponOrder?.name
                                        || 'Not available' }}
                                </template>
                            </span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Email</span>
                            </p>
                            <span class="fw-bold">
                                {{
                                    voucher.sourceable?.customerEmail
                                    || voucher.wooCouponOrder?.email
                                    || 'Not available'
                                }}
                            </span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Phone No</span>
                            </p>
                            <span class="fw-bold text-capitalize">
                                {{
                                    voucher.wooCouponOrder?.phoneNo
                                    || 'Not available'
                                }}
                            </span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Order No</span>
                            </p>
                            <span class="fw-bold">
                                <template v-if="voucher.sourceable?.type === 'Order'">
                                    <div class="d-flex flex-column">
                                        <router-link
                                            :to="{ name: 'order-show', params: { orderId: voucher.sourceable.orderId } }">
                                            {{ voucher.sourceable.orderNo }}
                                        </router-link>
                                        <small class="text-muted">
                                            {{ getSalesChannelLabel(voucher.sourceable.salesChannel) }}
                                        </small>
                                    </div>
                                </template>
                                <template v-else-if="voucher.sourceable?.type === 'WooCouponOrder'">
                                    <div class="d-flex flex-column">
                                        <a :href="getWooCommerceOrderUrl(voucher.sourceable.orderNo)" target="_blank"
                                            class="text-decoration-none">
                                            #{{ voucher.sourceable.orderNo }}
                                        </a>
                                        <small class="text-muted">Kiddoshop (Website)</small>
                                    </div>
                                </template>
                                <template v-else>
                                    {{ voucher.sourceable?.orderNo || voucher.wooCouponOrder?.data.items.order_id ||
                                        'Not available' }}
                                </template>
                            </span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Package Name</span>
                            </p>
                            <span class="fw-bold">
                                {{
                                    voucher.wooCouponOrder?.data.items.name
                                    || 'Not available'
                                }}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <BasicCard title="Activities">
            <template v-if="voucher.activityLogs?.length > 0">
                <div class="pb-4">
                    <ul class="list list-timeline">
                        <li v-for="(activity, index) in voucher.activityLogs" :key="`activity-item-${index}`"
                            class="py-2">
                            <div class="list-timeline-icon">
                                <icon name="circle-check" />
                            </div>
                            <div class="list-timeline-content pt-0">
                                <div class="list-timeline-time" v-if="activity.createdAt">
                                    {{ formatDate(activity.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}
                                </div>
                                <p class="list-timeline-title">{{ _.startCase(activity.description) }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-else>
                <div> No activity yet </div>
            </template>
        </BasicCard>

        <div class="card mt-4">
            <div class="card-header">
                <h4 class="card-title">Orders made with this voucher</h4>
            </div>
            <div class="card-body">

                <div class="row mb-3 gap-3 gap-md-0">
                    <div class="col-12 col-md-3 mb-3">
                        <label class="form-label">Order No</label>
                        <input type="text" class="form-control" v-model="orderNo" />
                    </div>
                    <div class="col-12 col-md-3 mb-3">
                        <label class="form-label">Customer</label>
                        <SearchSelectUser v-model="selectedUser" :query="{ role: RoleNameEnum.USER }" />
                    </div>
                </div>
                <table class="table" v-if="filteredOrders?.length">
                    <thead>
                        <tr>
                            <th>Order No</th>
                            <th>Customer Name</th>
                            <th>Status</th>
                            <th>Order Created At</th>
                            <th>Voucher Application Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="order in filteredOrders" :key="order.id">
                            <td>
                                <router-link target="_blank"
                                    :to="{ name: 'order-show', params: { orderId: order.id } }">
                                    {{ order.no }}
                                </router-link>
                            </td>
                            <td>
                                <router-link target="_blank"
                                    :to="{ name: 'customer-show', params: { userId: order.userId } }">
                                    {{ order.user.name }}
                                </router-link>
                            </td>
                            <td>
                                <span class="badge badge-pill" :class="getStatusBadge(order.status)">
                                    {{ getStatusLabel(order.status, orderStatuses) }}</span>
                            </td>
                            <td>{{ formatDate(order.createdAt, 'DD-MMM-YYYY') }}</td>
                            <td>{{ order.voucherApplicationCount || 1 }}</td>
                        </tr>
                    </tbody>
                </table>
                <template v-else>
                    <p class="text-center">No Data</p>
                </template>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import BasicCard from '@/components/BasicCard.vue'
import SearchSelectUser from '@/components/SearchSelectUser.vue'
import UserNameId from '@/components/UserNameId.vue'
import { formatDate } from '@/composable/useDate'
import { getStatusBadge, getStatusLabel } from '@/composable/useOrder'
import {
    getDiscountAmountDisplay,
    getUsageDisplay,
    getWooCommerceOrderUrl,
    getSalesChannelLabel
} from '@/composable/useVoucher'
import { RoleNameEnum } from '@/enums/RoleEnum'
import router from '@/router'
import VoucherService from '@/services/settings/VoucherService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import User from '@/types/User'
import Voucher from '@/types/Voucher'
import _ from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, markRaw, ref } from 'vue'
import { useRoute } from 'vue-router'
import { VoucherDiscountApplicationTypeLabels } from '@/enums/VoucherDiscountApplicationTypeEnum'
import { useModalStore } from '@/stores/modalStore'

const { orderStatuses } = storeToRefs(useResourcesStore())
const { addToastNotification } = useNotificationsStore()
const route = useRoute()

const modelStore = useModalStore()
const { showModal, hideModal } = modelStore


const isFetching = ref<boolean>(false)
const setIsFetching = (value = true) => isFetching.value = value

const voucherId = Number(route.params.voucherId)
const voucher = ref<Voucher>()

const orderNo = ref('')
const selectedUser = ref<User>()

const fetchVoucher = async () => {
    setIsFetching()

    try {
        const { data: { data } } = await VoucherService.show(voucherId)
        voucher.value = data
    } catch (e) {
        addToastNotification({
            message: `Failed to fetch voucher #${voucherId} due to internal server error. Please contact tech team.`,
            type: 'danger'
        })
    }

    setIsFetching(false)
}

fetchVoucher()

const filteredOrders = computed(() => {
    return voucher.value?.orders?.filter((order) => {
        const orderNoMatch = orderNo.value === '' || order.no.toLowerCase().includes(orderNo.value.toLowerCase())
        const userMatch = selectedUser.value ? order.userId === selectedUser.value.id : true
        return orderNoMatch && userMatch
    })
})


const openSendVoucherCodeModal = async (voucher: Voucher) => {

    showModal({
        component: markRaw(defineAsyncComponent(() =>
            import('@/components/SendVoucherCode.vue')
        )),
        title: "Send Voucher Code",
        size: 'xl',
        props: {
            voucher: voucher,
            email: voucher.sourceable?.customerEmail || '',
            orderNo: voucher.sourceable?.orderNo || '',
            customerName: voucher.sourceable?.customerName || '',
            onSent: () => hideModal()
        }
    })
}

const deleteVoucher = async (voucher: Voucher) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete coupon ${voucher.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    }).then((result: any) => {
        if (result.isConfirmed) {
            let voucherId = Number(voucher.id)

            VoucherService.destroy(voucherId)
                .then(() => {
                    router.push({ name: 'voucher-index' })
                    addToastNotification({
                        message: `Coupon ${voucher.name} deleted`,
                        type: 'success'
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger'
                    })
                })
        }
    })
}

</script>
