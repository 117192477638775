<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Device UUID</label>
                    <input class="form-control" type="text" v-model="inputFilter.device_uuid">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">App Activated Date</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range"
                            readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Total Automated Announcements</label>
                    <div class="input-group">
                        <input type="number" class="form-control bg-white"
                            v-model="inputFilter.number_of_announcements">
                    </div>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchLead" :is-loading="isFetching"></button-filter>
                </div>
            </div>
            <div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage"
                    @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Device UUID</th>
                                <th class="text-center">Download Date</th>
                                <th class="text-center">Total Automated Announcements</th>
                                <th class="text-center">Register Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="leads.length > 0">
                            <tr v-for="lead in leads" :key="lead.id">
                                <td>
                                    <router-link
                                        :to="{ name: 'lead-show', params: { userId: lead.users[0].id } }">
                                        {{ lead.deviceUuid }}
                                    </router-link>
                                </td>
                                <td class="text-center">{{ lead.createdAt ? formatDate(lead.createdAt, 'DD-MMM-YYYY hh:mm:ss A') : '-' }}</td>
                                <td class="text-center">{{ lead.automatedAnnouncementCount }}</td>
                                <td class="text-center">{{ lead.users[1]?.createdAt ?
                                    formatDate(lead.users[1]?.createdAt, 'DD-MMM-YYYY hh:mm:ss A') : '-'}}</td>
                                <td class="text-end">
                                    <div class="btn-group align-text-top">
                                        <router-link class="btn btn-icon btn-primary"
                                            :to="{ name: 'lead-notification-summary-show', params: { leadId: lead.id } }">
                                            <icon name="eye" />
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="7" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <overlay-spinner :is-showing="isFetching" />
                    </table>
                </div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage"
                    @goto-page="gotoPage" @per-page-updated="updatePerPage" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import { onMounted, ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import useMetaPage from '@/composable/useMetaPage'
import LeadService from '@/services/users/LeadService'
import Lead from '@/types/Lead'
import useDateRangePicker from '@/composable/useDateRangePicker'
import date from '@/utils/date'
import { Core, easepick } from '@easepick/core'

const { easePickDefaultOptions } = useDateRangePicker()

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'


const leads = ref<Array<Lead>>([])

const inputFilter = ref<UserFilter>({
    device_uuid: '',
    number_of_announcements: null,
    from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
})

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const isFetching = ref<boolean>(false)

fetchLead()


watch(
    () => metaPageTriggered.value,
    () => {
        fetchLead()
    }
)

function resetFilter() {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchLead()
}

function fetchLead() {
    isFetching.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
        ...{
            is_lead_notification_summary: true
        }
    }

    LeadService.index(query)
        .then(({ data: { data, meta } }) => {
            leads.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetching.value = false
        })
}

watch(
    () => metaPageTriggered.value,
    () => {
        fetchLead()
    }
)


onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value = {
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT)
                }
                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})
</script>