<template>
    <div class="card mt-4">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Name</label>
                    <input class="form-control" type="text" v-model="inputFilter.name" @keyup.enter="fetchUser">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="email" v-model="inputFilter.email" @keyup.enter="fetchUser">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Phone No.</label>
                    <input class="form-control" type="text" v-model="inputFilter.phoneNo" @keyup.enter="fetchUser">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Online ID</label>
                    <input class="form-control" type="email" v-model="inputFilter.onlineId" @keyup.enter="fetchUser">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchUser"
                        :is-loading="isFetchingUser"></button-filter>
                </div>
            </div>

            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone No</th>
                            <th>Online ID</th>
                            <th>Nationality</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="users.length > 0">
                        <tr v-for="user in users" :key="user.id">
                            <td class="fw-semibold">
                                <router-link v-if="user.roles && user.roles.length" :to="getUserRoute(user) || '#'">
                                    {{ user.mainProfile ? user.mainProfile.fullName : user.name }}
                                </router-link>
                                <div v-else>
                                    {{ user.mainProfile ? user.mainProfile.fullName : user.name }}
                                </div>
                            </td>
                            <td>{{ user.email }} <span v-if="user.emailVerifiedAt" class="text-green">
                                    <icon name="circle-check" />
                                </span></td>
                            <td>{{ (user.mainProfile)?.mobileNo ?? '-' }}</td>
                            <td>{{ user.mainProfile?.onlineId ?? '-' }}</td>
                            <td>{{ (user.mainProfile)?.nationality ?? '-' }}</td>
                            <td class="text-end">
                                <div class="btn-group align-text-top">
                                    <router-link class="btn btn-icon btn-primary" 
                                    v-if="user.roles && user.roles.length" :to="getUserRoute(user) || '#'">
                                        <icon name="eye" />
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingUser" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import UserFilter from '@/types/Filter'
import User from '@/types/User'
import useMetaPage from '@/composable/useMetaPage'
import AnnouncementService from '@/services/AnnouncementService'

const props = defineProps({
    announcementId: { type: Number, required: true },
})

const users = ref<Array<User>>([])
const inputFilter = ref<UserFilter>({
    name: '',
    email: '',
    phoneNo: '',
    onlineId: '',
})
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const isFetchingUser = ref<boolean>(false)

fetchUser()


watch(
    () => metaPageTriggered.value,
    () => {
        fetchUser()
    }
)

function resetFilter() {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchUser()
}

function fetchUser() {
    isFetchingUser.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    AnnouncementService.showAnnouncementUsers(props.announcementId, query)
        .then(({ data: { data, meta } }) => {
            users.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingUser.value = false
        })
}

const getUserRoute = (user: User) => {
    const roleRoutes: Record<number, { name: string; params: Record<string, any> }> = {
        1: { name: "admin-edit", params: { id: user.id } },
        2: { name: "customer-show", params: { userId: user.id } },
        3: { name: "carer-show", params: { userId: user.id } },
        11: { name: "lead-show", params: { userId: user.id } },
    };

    const roleId = user.roles?.[0]?.id;
    return roleId && roleRoutes[roleId] ? roleRoutes[roleId] : null;
};
</script>
