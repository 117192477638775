<template>
    <div>
        <router-link :to="{ name: 'lead-notification-summary-index' }" class="d-inline-flex align-items-center" role="button">
            <icon name="chevron-left" />
            <span>Back to Lead Notification Summary</span>
        </router-link>
        <overlay-spinner :is-showing="isFetchingLeadNotificationStats && isFetchingLeadAnnouncement"
            v-if="isFetchingLeadNotificationStats && isFetchingLeadAnnouncement" />
        <div>
            <div class="page-header mb-4">
                <div class="row align-items-center">
                    <div class="col">
                        <h2 class="page-title">{{ lead.deviceUuid }}</h2>
                    </div>
                </div>
            </div>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Register Date</span>
                            </p>
                            <span class="fw-bold">{{ lead.createdAt }}</span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Number Of Automated Announcements</span>
                            </p>
                            <span class="fw-bold">{{ lead.leadNotificationUsersCount}}</span>
                        </div>
                        <div class="col-4 mb-4">
                            <p class="text-muted mb-1">
                                <span>Total Read Announcement</span>
                            </p>
                            <span class="fw-bold">{{ lead.readAtCount + '/' +lead.leadNotificationUsersCount  }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="card">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h4 class="card-title">Automated Announcements</h4>
                </div>
                <div class="card-body">
                    <div class="row mb-4 align-items-end gap-3 gap-md-0">
                        <div class="col-12 col-md">
                            <label class="form-label">Title</label>
                            <input class="form-control" type="text" v-model="inputFilter.name"
                                @keyup.enter="fetchingLeadAnnouncement">
                        </div>
                        <div class="col-12 col-md-auto ms-auto">
                            <button-filter @reset="resetFilter" @filter="fetchingLeadAnnouncement"
                                :is-loading="isFetchingLeadNotificationStats"></button-filter>
                        </div>
                    </div>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th width="30%">Message</th>
                                <th class="text-center">Send At</th>
                                <th class="text-center">Read At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="leadAnnouncements.length > 0">
                            <tr v-for="announcement in leadAnnouncements" :key="announcement.id">
                                <td>{{ announcement.title }}</td>
                                <td>{{ announcement.body}}</td>
                                <td class="text-center"><AnnouncementScheduledAt :send-at="announcement.sendAt" /></td>
                                <td class="text-center">{{announcement.leadNotificationUsers[0].readAt ? formatDate(announcement.leadNotificationUsers[0].readAt, 'DD-MMM-YYYY hh:mm A'): '-' }}</td>
                                <td>
                                    <button class="btn btn-icon btn-primary"
                                        @click.prevent="viewAnnouncement(announcement.id)">
                                        <icon name="eye" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6" class="text-center">No data</td>
                            </tr>
                        </tbody>
                    </table>
                    <overlay-spinner :is-showing="isFetchingLeadAnnouncement" />
                    <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage"
                        @goto-page="gotoPage" @per-page-updated="updatePerPage" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Cohort from '@/types/Cohort'
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications'
import CohortService from '@/services/CohortService'
import { formatDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import UserFilter from '@/types/Filter'
import LeadService from '@/services/users/LeadService'
import Lead from '@/types/Lead'
import AnnouncementService from '@/services/AnnouncementService'
import Announcement from '@/types/Announcement'
import AnnouncementScheduledAt from '@/pages/marketings/announcements/AnnouncementScheduledAt.vue'

const { addToastNotification } = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const route = useRoute()
const router = useRouter();
const isFetchingLeadNotificationStats = ref<boolean>(false)
const isFetchingLeadAnnouncement = ref<boolean>(false)

const leadId = Number(route.params.leadId)
const lead = ref<Lead>({
    id: 0,
    deviceUuid: '',
    dataCollectionAgreementStatus: '',
    automatedAnnouncementCount: 0,
    leadNotificationUsersCount: 0,
    readAtCount: 0,
    createdAt: '',
    updatedAt: '',
    users: []
});

const inputFilter = ref<UserFilter>({
    name: null,
    onlineId: null,
})

const leadAnnouncements = ref<Announcement[]>([])

function fetchLeadNotificationStats() {
    isFetchingLeadNotificationStats.value = true

    LeadService.announcementStats(leadId)
        .then(({ data: { data } }) => {
            lead.value = data
        }).catch(() => {
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingLeadNotificationStats.value = false)
}

function fetchingLeadAnnouncement() {
    isFetchingLeadAnnouncement.value = true;

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    AnnouncementService.showLeadAutomatedAnnouncement(leadId, query)
        .then(({ data: { data, meta } }) => {
            leadAnnouncements.value = data
            updateMetaPage(meta)
        }).catch(() => {
            console.log('trigger')
            addToastNotification({ message: 'Internal server error. Please contact tech team.', type: 'danger' })
        }).finally(() => isFetchingLeadAnnouncement.value = false)
}

function viewAnnouncement(id: number) {
    router.push({ name: 'announcement-edit', params: { 'announcementId': id } })
}


function resetFilter() {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }   

    fetchingLeadAnnouncement()
}


onMounted(() => {
    fetchLeadNotificationStats();
    fetchingLeadAnnouncement();
})

watch(
() => metaPageTriggered.value,
() => fetchingLeadAnnouncement()
)
</script>