<template>
    <div class="modal fade" id="vendor-form-modal" tabindex="-1">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="card">
                    <div class="modal-header">
                        <h5 class="modal-title m-0">{{ props.vendor.name }}</h5>
                        <button type="button" id="vendor-form-modal-close" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label">Recipient Email</label>
                            <input type="text" :class="{'is-invalid': hasError('email')}" class="form-control" v-model="inputUser.email" @focus="clearError('email')"/>
                            <small class="invalid-feedback" v-if="hasError('email')">{{ getError('email') }}</small>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Recipient Name</label>
                            <input type="text" :class="{'is-invalid': hasError('emailName')}" class="form-control" v-model="inputUser.emailName" @focus="clearError('emailName')"/>
                            <small class="invalid-feedback" v-if="hasError('emailName')">{{ getError('emailName') }}</small>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Email CC</label>
                            <input type="text" name="emailBcc[]" class="form-control" v-model="inputEmailCc" />
                            <small class="text-end text-muted fw-normal" >Support multiple email and separated by coma (,)</small>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Email BCC</label>
                            <input type="text" name="emailBcc[]" class="form-control" v-model="inputEmailBcc" />
                            <small class="text-end text-muted fw-normal" >Support multiple email and separated by coma (,)</small>
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Notify Fulfillment</label>
                            <label class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" v-model="inputUser.isNotifyFulfillment">
                                <span class="form-check-label">{{ inputUser.isNotifyFulfillment ? 'Yes' :  'No' }}</span>
                            </label>
                        </div>
                        
                        <div class="mb-3 text-end">
                            <button class="btn btn-primary" type="button" @click.prevent="submit">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Vendor from '@/types/Vendor'
import UserInput from '@/types/Input'
import useVuelidate from '@vuelidate/core'
import { rules as VendorRule, $externalResults } from '@/rules/VendorRule'
import useFormError from '@/composable/useFormError'
import { onMounted, ref, watch } from 'vue'
import ShopVendorService from '@/services/ShopVendorService'
import { useNotificationsStore } from '@/stores/notifications'

const inputUser = ref<UserInput>({
    email: null,
    emailName: null,
    isNotifyFulfillment: false,
})
const inputEmailCc = ref<string>('')
const inputEmailBcc = ref<string>('')
const v$ = useVuelidate(VendorRule, { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)
const { addToastNotification } = useNotificationsStore()

const props = defineProps({
    vendor: {
        type: Object as () => Vendor,
        required: true
    }
})

watch(
    () => props.vendor,
    (vendor) => {
        if (vendor !== undefined) {
            inputUser.value.email = vendor.email
            inputUser.value.emailName = vendor.emailName
            inputUser.value.isNotifyFulfillment = vendor.isNotifyFulfillment

            inputEmailCc.value = vendor.emailCc?.join(',') ?? ''
            inputEmailBcc.value = vendor.emailBcc?.join(',') ?? ''

            clearAllErrors()
        }
    }
)

onMounted(() => {
    document.getElementById('vendor-form-modal')?.addEventListener('hidden.bs.modal', () => {
        v$.value.$reset()
        clearAllErrors()
    })
})

const emit = defineEmits(['update'])

const resetForm = () => {
    v$.value.$reset()
    delete inputUser.value['emailCc'];
    delete inputUser.value['emailBcc'];
    clearAllErrors()
}

const submit = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        if(inputEmailCc.value != '') inputUser.value.emailCc = inputEmailCc.value.split(',')
        if(inputEmailBcc.value != '') inputUser.value.emailBcc = inputEmailBcc.value.split(',')
        
        const response = await ShopVendorService.update(props.vendor.id, inputUser.value)
            .then(() => {
                addToastNotification({
                    message: `Vendor ${props.vendor.name} has been saved.`,
                    type: 'success',
                })
                
                resetForm()
                document.getElementById('vendor-form-modal-close')?.click()
                
                emit('update')
            })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)

                    addToastNotification({
                        message: data.message,
                        type: 'danger',
                    })

                    clearAllErrors()
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}
</script>
