import { createApp } from 'vue'
import { createPinia } from 'pinia'
import Echo from 'laravel-echo'
import VueApexCharts from 'vue3-apexcharts'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import App from './App.vue'
import router from './router'
import Icon from './components/Icon.vue'
import VueSelect from 'vue-select'
import TablePagination from '@/components/TablePagination.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import ButtonFilter from '@/components/ButtonFilter.vue'
import StarRating from '@/components/StarRating.vue'
import SubMenu from '@/components/SubMenu.vue'
import KcModal from '@/components/Modal.vue'
import { name, version } from '../package.json'

import Swal from 'sweetalert2/dist/sweetalert2.js'
import { SweetAlertOptions } from 'sweetalert2'
import '@/css/index.css'
import 'bootstrap/dist/js/bootstrap'
import '@tabler/core/dist/css/tabler.min.css'
import '@/css/sweetalert2.css'
import '@/css/index.css'
import 'vue-select/dist/vue-select.css'

window.Swal = Swal

declare global {
    interface Window {
        Pusher: any,
        Echo: Echo,
        Swal: any,
        google: any,
    }
}

const app = createApp(App)

if (import.meta.env.PROD && import.meta.env.VITE_API_BASE_URL.includes('.kiddocare.my')) {
    const environment = 'production'

    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `${name}@${version}-${environment}`,
        environment: environment,
        tracesSampleRate: 0.01,
        logErrors: true,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ['backoffice.kiddocare.my', /^\//],
            }),
            new Sentry.Replay(),
        ],
    })
}

if (import.meta.env.VITE_API_BASE_URL.includes('.kiddocare.dev')) {
    document.title = `[TEST] ${document.title}`
} else if (import.meta.env.VITE_API_BASE_URL.includes('.kiddocare.test')) {
    document.title = `[LOCAL] ${document.title}`
}

app.use(router)
    .use(createPinia())
    .use(VueApexCharts)
    // .use(Swal)

app.component('icon', Icon)
    .component('vue-select', VueSelect)
    .component('table-pagination', TablePagination)
    .component('overlay-spinner', OverlaySpinner)
    .component('star-rating', StarRating)
    .component('button-filter', ButtonFilter)
    .component('sub-menu', SubMenu)
    .component('kc-modal', KcModal)

app.mount('#app')
