import { ref } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import { required, numeric, maxLength, helpers, email } from '@vuelidate/validators'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        email: {
            required: helpers.withMessage(
                'This recipient email field is required',
                required
            ),
            email: helpers.withMessage(
                'This recipient email field must be a valid email',
                email
            ),
        },
        emailName: {
            required: helpers.withMessage(
                'This recipient name field is required',
                required
            )
        },
    },
})