<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <a class="btn btn-primary float-right" @click="openEditNotificationForm()" aria-label="Add leadNotification Notification">
                        <icon name="plus" />
                        Add Notification Template
                    </a>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Recipient Category / Type</label>
                    <RecipientCategorySelect v-model="inputFilter.recipientCategory"/>
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Title</label>
                    <input class="form-control"  v-model="inputFilter.title">
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetch"
                        :is-loading="isFetchingleadNotificationNotifications"></button-filter>
                </div>
            </div>
            <div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Trigger Type</th>
                                <th style="width: 10%;">Title</th>
                                <th style="width: 15%;">Preview</th>
                                <th style="width: 30%;">Message</th>
                                <th>Status</th>
                                <th>Created At</th> 
                                <th>Action</th> 
                            </tr>
                        </thead>
                        <tbody v-if="notificationTemplates.length > 0">
                            <tr v-for="template in notificationTemplates" :key="template.id">
                                <td>{{ template.recipientCategoryLabel }}</td>
                                <td>{{ template.triggerEvent?.typeLabel }}</td>
                                <td>{{ template.title }}</td>
                                <td>{{ template.preview }}</td>
                                <td>{{ limitStringLength(template.body,20) }}</td>
                                <td>
                                    <StatusBadge :class="template.isActive ? 'bg-green' : 'bg-red'"
                                        :label="template.isActive ? 'Active' : 'Inactive'" show />
                                </td>
                                <td>{{ template.createdAt ? formatDate(template.createdAt , 'DD-MMM-YYYY hh:mm A') :'-' }}</td>
                                <td class="text-end">
                                    <div class="btn-group">
                                        <button class="btn btn-icon btn-primary" @click="(openEditNotificationForm(template))">
                                            <icon name="edit" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="8" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <overlay-spinner :is-showing="isFetchingleadNotificationNotifications" />
                    </table>
                </div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import useMetaPage from '@/composable/useMetaPage'
import { ref, watch, onMounted, markRaw, defineAsyncComponent } from 'vue'
import { formatDate } from '@/composable/useDate'
import {limitStringLength} from '@/composable/useGeneral'
import { useModalStore } from '@/stores/modalStore'
import StatusBadge from '@/components/StatusBadge.vue'
import NotificationTemplateService from '@/services/NotificationTemplateService'
import { NotificationTemplate } from '@/types/NotificationTemplate'
import RecipientCategorySelect from '@/components/RecipientCategorySelect.vue'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const modalStore = useModalStore()
const { showModal } = modalStore

const notificationTemplates = ref<Array<NotificationTemplate>>([])

const inputFilter = ref({
    recipientCategory: null,
    title: null,
})

const isFetchingleadNotificationNotifications = ref<boolean>(false)

const fetch = () => {
    isFetchingleadNotificationNotifications.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    } 

    NotificationTemplateService.index(query).then(({ data: { data, meta } }) => {
        notificationTemplates.value = data
        updateMetaPage(meta)
    }).finally(() => {
        isFetchingleadNotificationNotifications.value = false
    })
}

const openEditNotificationForm = (notificationTemplate: NotificationTemplate  | null = null) => {

showModal({
    component: markRaw(defineAsyncComponent(() =>
        import('@/pages/notifications/templates/NotificationTemplateForm.vue')
    )),
    size: 'xl',
    props: {
        notificationTemplate: notificationTemplate,
        mode:notificationTemplate ? 'update' : 'create',
        onUpdatedOrCreated: () => {
            fetch()
        }
    }
})
}


const resetFilter = () => {
    inputFilter.value.recipientCategory = null
    inputFilter.value.title = null

    fetch()
}

fetch()

watch(
    () => metaPageTriggered.value,
    () => fetch()
)
</script>