import { ref, computed } from 'vue'
import ErrorBag from '@/types/ErrorBag'
import UserInput from '@/types/Input'
import { required, requiredIf, helpers } from '@vuelidate/validators'
import { NavigationTypeEnum } from '@/enums/AnnouncementNavigationEnum'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

const isImageFile = (value: File | null) => {
    if (!value) return true 
    return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
}

export const rules = (input: UserInput) => {
    return computed(() => {
        return {
            inputUser: {
                recipient: {
                    required: helpers.withMessage(
                        'This recipient field is required',
                        required
                    ),
                },
                title: {
                    required: helpers.withMessage(
                        'This subject field is required',
                        required
                    ),
                },
                preview: {
                    required: helpers.withMessage(
                        'This preview field is required',
                        required
                    ),
                },
                body: {
                    required: helpers.withMessage(
                        'This message field is required',
                        required
                    ),
                },
                send_at: {
                    requiredIf: helpers.withMessage(
                        'This field is required',
                        requiredIf(input.send_now === false)
                    ),
                },
                csv_file: {
                    requiredIf: helpers.withMessage(
                        'CSV file is required',
                        requiredIf(input.recipient === 4)
                    ),
                },
                image_file: {
                    isImageFile: helpers.withMessage(
                        'Only JPG, PNG, and GIF images are allowed',
                        isImageFile
                    ),
                },
                cta_text: {
                    requiredIf: helpers.withMessage(
                        'CTA Text is required',
                        requiredIf(input.is_cta_enabled)
                    ),
                },
                cta_url: {
                    requiredIf: helpers.withMessage(
                        'CTA URL is required',
                        requiredIf(input.is_cta_enabled && input.is_cta_external)
                    ),
                },
                navigate_type: {
                    requiredIf: helpers.withMessage(
                        'Navigation Type is required',
                        requiredIf(input.is_cta_enabled && !input.is_cta_external)
                    ),
                },
                navigate_id: {
                    requiredIf: helpers.withMessage(
                        'This field is required',
                        requiredIf(input.navigate_type === NavigationTypeEnum.PRODUCT)
                    ),
                }
            },
        }
    })
}