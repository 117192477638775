import { useResourcesStore } from '@/stores/resources'
import Address from '@/types/Address'
import AddressState from '@/types/AddressState'
import Postcode from '@/types/Postcode'
import { storeToRefs } from 'pinia'

export function getFormattedAddress(address: Address, states: Array<AddressState>) {
    const state = states.find((findState: AddressState) => findState.id === address.stateId)
    return [address.unitNo, address.floorLevel, address.blockBuilding, address.address1, address.address2, address.postcode, address.town, state?.name].filter(val => val).join(', ')
}



function isCityAndStateEqual(city?: string): boolean {
    return (city === 'Kuala Lumpur' || city === 'Putrajaya' || city === 'Labuan' || city === 'Pulau Pinang' || city === 'Melaka')
}

export function getShortAddress(address: Address): string {
    if (address === null) return 'N/A';

    const { addressStates, postcodes } = storeToRefs(useResourcesStore());

    const postcode = postcodes?.value.find((findPostcode: Postcode) => findPostcode.postcode === address.postcode)

    if (postcode) {
        if (isCityAndStateEqual(postcode.city)) {
            return postcode.city
        }

        const state = addressStates.value.find((findState: AddressState) => findState.id === address?.stateId)

        return `${postcode.city}, ${state?.name}`
    }

    return 'N/A'
}


