import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'
import { getQueryString } from '@/composable/http'

const endpoint = '/backoffice/session-rate-amounts'

export default {
    index(query: UserQuery = {}) {
        const queryString = getQueryString(query)

        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    publish() {
        return httpClientV2.post(`${endpoint}/publish`)
    }
}