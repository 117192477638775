<template>
    <OverlaySpinner :is-showing="isFetching" v-if="isFetching" />
    <template v-else>
        <div class="card">
            <form @submit.prevent="save" ref="updateVoucherForm">
                <div class="card-body">
                    <div class="form-group mb-3 row">
                        <FormLabel label="Code" is-required />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('code') }" type="text"
                                v-model="inputUser.code" @focus="clearError('code')">
                            <ErrorMessage :has-error="hasError('code')" :message="getError('code')" />
                            <HelperText text="Code that will be used by customer to apply the discount." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Name" is-required />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('name') }" type="text"
                                v-model="inputUser.name" @focus="clearError('name')">

                            <ErrorMessage :has-error="hasError('name')" :message="getError('name')" />
                            <HelperText text="General name for discount. Will be displayed in customer booking
                            confirmation details." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Description" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('description') }" type="text"
                                v-model="inputUser.description" @focus="clearError('description')">
                            <ErrorMessage :has-error="hasError('description')" :message="getError('description')" />
                            <HelperText text="Description of the discount." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Customer" />
                        <div class="col">
                            <vue-select :options="userOptions" @search="handleSearch" label="name"
                                v-model="inputUser.user_id" :reduce="(user) => user.id"></vue-select>
                            <ErrorMessage :has-error="hasError('user_id')" :message="getError('user_id')" />
                            <HelperText text="Leave empty if voucher is available for many users" />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Discount Amount" is-required />
                        <div class="col">
                            <div class="input-group">
                                <input class="form-control" :class="{ 'is-invalid': hasError('discount_amount') }"
                                    type="number" step="0.01" v-model="inputUser.discount_amount"
                                    @focus="clearError('discount_amount')">
                                <select class="form-select flex-grow-0" style="width: 120px" name="is_fixed_discount"
                                    v-model="inputUser.is_fixed_discount">
                                    <option :value="true">Flat (RM)</option>
                                    <option :value="false">%</option>
                                </select>
                            </div>
                            <ErrorMessage :has-error="hasError('discount_amount')"
                                :message="getError('discount_amount')" />
                            <HelperText text="Discount amount. Based on type of discount." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Started Date" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('started_at') }" type="date"
                                v-model="inputUser.started_at" @focus="clearError('started_at')">
                            <ErrorMessage :has-error="hasError('started_at')" :message="getError('started_at')" />
                            <HelperText text="Date of voucher start apply to order." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Expiry Date" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('expired_at') }" type="date"
                                v-model="inputUser.expired_at" @focus="clearError('expired_at')">
                            <ErrorMessage :has-error="hasError('expired_at')" :message="getError('expired_at')" />
                            <HelperText text="Date of voucher expires." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="No. of Max Uses" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('max_uses') }" type="number"
                                v-model="inputUser.extra_attributes.max_uses" @focus="clearError('max_uses')">
                            <ErrorMessage :has-error="hasError('max_uses')" :message="getError('max_uses')" />
                            <HelperText
                                text="Maximum number of voucher usages. Leave blank to set allow unlimited usage." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="No. of Max Uses per User" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('max_uses_per_user') }"
                                type="number" v-model="inputUser.extra_attributes.max_uses_per_user"
                                @focus="clearError('max_uses_per_user')">
                            <ErrorMessage :has-error="hasError('max_uses_per_user')"
                                :message="getError('max_uses_per_user')" />
                            <HelperText
                                text="Maximum number of voucher usages per user. Leave blank to set grant every user unlimited usage." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Minimum Sessions to Applicable" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('min_sessions') }"
                                type="number" v-model="inputUser.extra_attributes.min_sessions"
                                @focus="clearError('min_sessions')">
                            <ErrorMessage :has-error="hasError('min_sessions')" :message="getError('min_sessions')" />
                            <HelperText
                                text="Minimum number of sessions per booking. Leave blank to not set minimum number of sessions." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Minimum Hours to Applicable" />
                        <div class="col">
                            <input class="form-control" :class="{ 'is-invalid': hasError('min_hours_per_session') }"
                                type="number" v-model="inputUser.extra_attributes.min_hours_per_session"
                                @focus="clearError('min_hours_per_session')">

                            <ErrorMessage :has-error="hasError('min_hours_per_session')"
                                :message="getError('min_hours_per_session')" />
                            <HelperText
                                text="Minimum number per sessions. Leave blank to not set minimum number of hours per session." />
                        </div>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Valid on Public Holidays" />
                        <div class="col">
                            <RadioInput v-model="inputUser.extra_attributes.is_available_on_holidays" :value="false"
                                :checked="false === inputUser.extra_attributes.is_available_on_holidays"
                                name="is_available_on_holidays" label="No" />
                            <RadioInput v-model="inputUser.extra_attributes.is_available_on_holidays" :value="true"
                                :checked="true === inputUser.extra_attributes.is_available_on_holidays"
                                name="is_available_on_holidays" label="Yes" />
                        </div>
                        <ErrorMessage :has-error="hasError('available_holiday')"
                            :message="getError('available_holiday')" />
                        <HelperText text="Select Yes if voucher is valid for public holidays" />
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Enable Voucher Ownership" />
                        <div class="col">
                            <RadioInput v-model="inputUser.extra_attributes.is_voucher_ownership" :value="false"
                                :checked="false === inputUser.extra_attributes.is_voucher_ownership"
                                name="is_voucher_ownership" label="No" />
                            <RadioInput v-model="inputUser.extra_attributes.is_voucher_ownership" :value="true"
                                :checked="true === inputUser.extra_attributes.is_voucher_ownership"
                                name="is_voucher_ownership" label="Yes" />
                        </div>
                        <ErrorMessage :has-error="hasError('is_voucher_ownership')"
                            :message="getError('is_voucher_ownership')" />
                        <HelperText text="Select Yes to enable voucher ownership to user" />
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Discount Application Type" is-required />
                        <select v-model="inputUser.extra_attributes.discount_application_type" class="form-select">
                            <option :value="VoucherDiscountApplicationTypeEnum.ONCE_PER_ORDER"
                                :selected="inputUser.extra_attributes.discount_application_type === VoucherDiscountApplicationTypeEnum.ONCE_PER_ORDER">
                                {{
                                    VoucherDiscountApplicationTypeLabels[VoucherDiscountApplicationTypeEnum.ONCE_PER_ORDER]
                                }}

                            </option>
                            <option :value="VoucherDiscountApplicationTypeEnum.EACH_SESSION_IN_ORDER"
                                :selected="inputUser.extra_attributes.discount_application_type === VoucherDiscountApplicationTypeEnum.EACH_SESSION_IN_ORDER">
                                {{
                                    VoucherDiscountApplicationTypeLabels[VoucherDiscountApplicationTypeEnum.EACH_SESSION_IN_ORDER]
                                }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group mb-3 row">
                        <FormLabel label="Use Extension Hours Rate for Extra Hours" />
                        <select v-model="inputUser.extra_attributes.apply_extension_rate_to_extra_hours"
                            class="form-select">
                            <option :value="true"
                                :selected="inputUser.extra_attributes.apply_extension_rate_to_extra_hours === true">Yes
                            </option>
                            <option :value="false"
                                :selected="inputUser.extra_attributes.apply_extension_rate_to_extra_hours === false">No
                            </option>
                        </select>
                        <HelperText text="If yes, hours beyond the minimum will be charged at the extension rate." />
                    </div>
                </div>
                <div class="card-footer">
                    <div class="d-flex space-x-2">
                        <button class="btn btn-light" @click.prevent="$router.go(-1)" :disabled="isSubmitting">Cancel
                        </button>
                        <button class="btn btn-primary" type="submit" :disabled="isSubmitting">
                            {{ !isSubmitting ? 'Save Voucher' : 'Saving...' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </template>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotificationsStore } from '@/stores/notifications'

import VoucherService from '@/services/settings/VoucherService'
import Voucher from '@/types/Voucher'
import FormLabel from '@/components/form/FormLabel.vue'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import HelperText from '@/components/form/HelperText.vue'
import UpdateVoucherFormData from '@/types/formData/UpdateVoucherFormData'
import { $externalResults, rules as VoucherRule } from '@/rules/VoucherRule'
import { AxiosError } from 'axios'
import { useVuelidate } from '@vuelidate/core'
import useFormError from '@/composable/useFormError'
import RadioInput from '@/components/form/RadioInput.vue'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import User from '@/types/User'
import _ from 'lodash/fp'
import CustomerService from '@/services/users/CustomerService'
import { VoucherDiscountApplicationTypeEnum, VoucherDiscountApplicationTypeLabels } from '@/enums/VoucherDiscountApplicationTypeEnum'

const { addToastNotification } = useNotificationsStore()
const route = useRoute()
const router = useRouter()

const updateVoucherForm = ref<HTMLElement>()

const isFetching = ref<boolean>(false)
const setIsFetching = (value = true) => isFetching.value = value

const isSubmitting = ref(false)
const setIsSubmitting = (value = true) => isSubmitting.value = value

const scrollIntoViewForm = () => updateVoucherForm.value?.scrollIntoView()

const voucherId = Number(route.params.voucherId)

const userOptions = ref<User[]>([])

const inputUser = ref<UpdateVoucherFormData>({
    code: '',
    name: '',
    description: '',
    user_id: null,
    discount_amount: 0,
    is_fixed_discount: false,
    started_at: null,
    expired_at: null,
    extra_attributes: {
        is_available_on_holidays: false,
        is_voucher_ownership: false,
        increment_discount_each_bundle: false,
        max_uses: null,
        max_uses_per_user: null,
        min_sessions: null,
        min_hours_per_session: null,
        apply_extension_rate_to_extra_hours: false,
        discount_application_type: VoucherDiscountApplicationTypeEnum.ONCE_PER_ORDER
    }
})
const originalInputUser = ref<UpdateVoucherFormData>({})

const v$ = useVuelidate(VoucherRule, { inputUser }, { $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

const fetchVoucher = async () => {
    setIsFetching()

    try {
        const { data: { data } } = await VoucherService.show(voucherId)
        initData(data)
    } catch (e) {
        addToastNotification({
            message: `Failed to fetch voucher #${voucherId} due to internal server error. Please contact tech team.`,
            type: 'danger'
        })
    }

    setIsFetching(false)
}

fetchVoucher()

const initData = (data: any) => {

    // Init form inputs
    inputUser.value = {
        name: data.name,
        code: data.code,
        user_id: data.user?.id,
        description: data.description,
        is_fixed_discount: data.isFixedDiscount,
        discount_amount: data.isFixedDiscount ? data.discountAmount / 100 : data.discountAmount,
        started_at: data.startedAt,
        expired_at: data.expiredAt,
        extra_attributes: {
            is_available_on_holidays: data.extraAttributes?.isAvailableOnHolidays,
            is_voucher_ownership: data.extraAttributes?.isVoucherOwnership,
            increment_discount_each_bundle: data.extraAttributes?.incrementDiscountEachBundle,
            max_uses: data.extraAttributes?.maxUses,
            max_uses_per_user: data.extraAttributes?.maxUsesPerUser,
            min_sessions: data.extraAttributes?.minSessions,
            min_hours_per_session: data.extraAttributes?.minHoursPerSession,
            apply_extension_rate_to_extra_hours: data.extraAttributes?.applyExtensionRateToExtraHours,
            discount_application_type: data.extraAttributes?.discountApplicationType
        }
    }

    // Store a copy of the inputs for data formatting before submission
    originalInputUser.value = JSON.parse(JSON.stringify(inputUser.value))

    // Init user options
    if (data.user) {
        userOptions.value = [data.user]
    }
}

const save = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (!validated) {
        scrollIntoViewForm()
        return
    }

    const data = getFormattedData()

    if (!Object.keys(data).length) {
        addToastNotification({
            message: `No changes found. Voucher #${voucherId} not updated.`,
            type: 'danger'
        })
        return
    }

    setIsSubmitting()
    try {
        await VoucherService.update(voucherId, data)
        addToastNotification({
            message: `Voucher ${inputUser.value.name} updated`,
            type: 'success'
        })
        await router.push({ name: 'voucher-show', params: { voucherId: voucherId } })
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.status === 422) {
            const responseData = error.response.data as { errors?: Record<string, string[]> }
            Object.assign($externalResults.value.inputUser, responseData.errors)
            scrollIntoViewForm()
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    }
    setIsSubmitting(false)
}


const getFormattedData = () => {

    const inputs = JSON.parse(JSON.stringify(inputUser.value))

    inputs.extra_attributes.is_available_on_holidays = inputs.extra_attributes.is_available_on_holidays === 'true'
    inputs.extra_attributes.is_voucher_ownership = inputs.extra_attributes.is_voucher_ownership === 'true'
    inputs.extra_attributes.increment_discount_each_bundle = inputs.extra_attributes.increment_discount_each_bundle === 'true'
    inputs.extra_attributes.apply_extension_rate_to_extra_hours = inputs.extra_attributes.apply_extension_rate_to_extra_hours === 'true' || inputs.extra_attributes.apply_extension_rate_to_extra_hours === true

    const inputsWithChange = filterInputsWithChange(inputs, originalInputUser.value)

    // If type of discount amount is changed, pass the amount as well
    if (inputsWithChange.hasOwnProperty('is_fixed_discount')) {
        inputsWithChange.discount_amount = inputsWithChange.discount_amount ?? originalInputUser.value?.discount_amount
    }

    // If discount amount is changed and is fixed discount, convert to ringgit to cents. Pass the type of discount amount as w
    if (inputsWithChange.hasOwnProperty('discount_amount')) {

        if (inputsWithChange.is_fixed_discount === undefined) {
            inputsWithChange.is_fixed_discount = originalInputUser.value?.is_fixed_discount
        }

        if (inputsWithChange.is_fixed_discount && inputsWithChange.discount_amount) {
            inputsWithChange.discount_amount *= 100
        }
    }

    return inputsWithChange
}

const filterInputsWithChange = (inputs: UpdateVoucherFormData, original: UpdateVoucherFormData) => {
    const changes: UpdateVoucherFormData = {}

    Object.keys(inputs).forEach((key) => {
        if (typeof inputs[key] === 'object' && inputs[key] !== null) {
            const nestedChanges = filterInputsWithChange(inputs[key], original[key] || {})
            if (Object.keys(nestedChanges).length > 0) {
                changes[key] = nestedChanges
            }
        } else if (inputs[key] !== original[key]) {
            changes[key] = inputs[key]
        }
    })

    return changes
}


const handleSearch = (search: string, loading: Function) => {
    if (search.length) {
        searchUser(search, loading)
    }
}

const searchUser = _.debounce(350, async (search, loading) => {
    loading(true)

    try {

        const query: { name?: string } = {
            name: search
        }

        const response = await CustomerService.index(query)

        userOptions.value = response.data.data.map((user: { id: number, name: string }) => {
            return {
                id: user.id,
                name: user.name
            }
        })
    } catch (ex: any) {
        addToastNotification({
            message: 'Error while getting available users. Please try again.',
            type: 'danger'
        })
    }

    loading(false)
})

</script>
