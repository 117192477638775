import UserProfile from '@/types/UserProfile'
import { add } from 'lodash'

export function getMainProfile(profiles: Array<UserProfile>): UserProfile | any {
    return profiles.find(profile => profile.type === 'main')
}

export function isValidMobileNo(val: string) {
    if (!val) return false
    const mobileNo = val.split(/\D/).join('')
    return (/^\d+$/).test(mobileNo)
}

export function getValidMobileNo(val: string, dialCode: string = '') {
    if (!val) return ''

    const mobileNo = val.split(/\D/).join('')

    if (mobileNo.startsWith(dialCode)) {
        return mobileNo
    }

    return `${dialCode}${mobileNo}`
}

export function getMobileNo(val: string, dialCode: string | null = '', internationalFormat = false) {
    if (!val) return 'N/A';

    const formattedDialCode = dialCode ?? ''; 

    return internationalFormat
        ? `+${formattedDialCode}${val}`
        : `${formattedDialCode}${val}`;
}

export function getAddress(address: any): string {
    let addressString = ''

    if (address.unit_no !== null) {
        addressString += address.unit_no
    }

    if (address.floor_level !== null) {
        addressString += `, ${address.floor_level}`
    }

    if (address.block_building !== null) {
        addressString += `, ${address.block_building}`
    }

    const postcodeCityState = address.town !== address.state ? `${address.postcode} ${address.town}, ${address.state}` : `${address.postcode} ${address.state}`

    addressString += ` ${address.address_1}, ${postcodeCityState}`

    return addressString
}

export function fallbackAvatar(event: Event) {
    (event.target as HTMLInputElement).src = "../../avatar-default.png"
}
