<template>

    <BasicCard title="Countries">

        <template #actions>
            <button @click="confirmPublish" class="btn btn-primary">
                <icon name="cloud-up" />
                Publish Changes
            </button>
        </template>

        <div class="row">
            <form @submit.prevent="fetchCountries">
                <div class="row mb-3">
                    <div class="col-4">
                        <label class="form-label">Name / ISO Code </label>
                        <input class="form-control" type="text" v-model="inputFilter.search">
                    </div>
                    <div class="col-4">
                        <label class="form-label">Dial Code </label>
                        <input class="form-control" type="number" v-model="inputFilter.dial_code">
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class=" col-3 ">
                        <ButtonFilter @reset="resetFilter" :is-loading="isFetching" @filter="fetchCountries" />
                    </div>
                </div>
            </form>
        </div>
        <div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Country</th>
                            <th>ISO Code</th>
                            <th>Dial Code</th>
                            <th>Phone No Length</th>
                            <th>Status</th>
                            <th>Last Published At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="countries.length > 0">
                        <tr v-for="country in countries" :key="country.id">
                            <td>{{ country.name }}</td>
                            <td>{{ country.isoCode }}</td>
                            <td>{{ country.dialCode }}</td>
                            <td>{{ country.minPhoneNoLength }} - {{ country.maxPhoneNoLength }} digits</td>
                            <td>
                                <StatusBadge :class="country.isActive ? 'bg-green' : 'bg-red'"
                                    :label="country.isActive ? 'Active' : 'Inactive'" show />
                            </td>
                            <td>{{ formatDate(country.publishedAt, 'DD-MMM-YYYY hh:mm A') }}</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <button @click="(openEditCountryFormModal(country))"
                                        class="btn btn-icon btn-primary">
                                        <icon name="edit" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6" class="text-center">No data</td>
                        </tr>
                    </tbody>
                    <overlay-spinner :is-showing="isFetching" />
                </table>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
    </BasicCard>

</template>

<script setup lang="ts">
import useMetaPage from '@/composable/useMetaPage'
import CountryForm from '@/pages/settings/countries/CountryForm.vue'
import CountryService from '@/services/CountryService'
import Country from '@/types/Country'
import UserFilter from '@/types/Filter'
import { ref, watch, defineAsyncComponent, markRaw } from 'vue'
import StatusBadge from '@/components/StatusBadge.vue'
import BasicCard from '@/components/BasicCard.vue'
import ButtonFilter from '@/components/ButtonFilter.vue'
import { useModalStore } from '@/stores/modalStore'
import { formatDate } from '@/composable/useDate'
import { AxiosError } from 'axios'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()


const modalStore = useModalStore()
const { showModal, hideModal } = modalStore

const countries = ref<Country[]>([])
const currentCountry = ref<Country | null>(null)


const inputFilter = ref<UserFilter>({
    search: null,
    dial_code: null
})

const isFetching = ref<boolean>(false)

const fetchCountries = () => {
    isFetching.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    CountryService.index(query).then(({ data: { data, meta } }) => {
        countries.value = data
        updateMetaPage(meta)
    }).finally(() => {
        isFetching.value = false
    })
}

const resetFilter = () => {
    inputFilter.value.search = null
    inputFilter.value.dial_code = null

    fetchCountries()
}

const publish = async () => {
    try {
        isFetching.value = true
        await CountryService.publish()

        await window.Swal.fire({
            text: 'Changes have been published successfully',
            showConfirmButton: false,
            timer: 1500
        })

        await fetchCountries()
    } catch (err) {
        let errorMessage = 'Failed to publish changes'

        const error = err as AxiosError | any

        if (error.response?.data?.message) {
            errorMessage = error.response.data.message
        }

        await window.Swal.fire({
            text: errorMessage,
        })
    } finally {
        isFetching.value = false
    }
}

const confirmPublish = async () => {
    const result = await window.Swal.fire({
        text: 'Are you sure you want to publish changes?',
        showCancelButton: true,
        confirmButtonText: 'Yes, publish',
        cancelButtonText: 'Cancel',
    })

    if (result.isConfirmed) {
        await publish()
    }
}



const openEditCountryFormModal = (country: Country) => {

    currentCountry.value = country

    showModal({
        component: markRaw(defineAsyncComponent(() =>
            import('@/pages/settings/countries/CountryForm.vue')
        )),
        size: 'xl',
        props: {
            country: country,
            onUpdated: () => {
                fetchCountries()
            }
        }
    })
}

fetchCountries()

watch(
    () => metaPageTriggered.value,
    () => fetchCountries()
)
</script>