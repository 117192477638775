import httpClientV2 from '@/services/HttpClientV2'
import UserQuery from '@/types/query/UserQuery'
import {getQueryString} from '@/composable/http'

const endpoint = '/backoffice/users/leads'

export default {
    index(query: UserQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },

    customers(id: number, query: UserQuery = {}) {
        const queryString = getQueryString(query)
        return httpClientV2.get(`${endpoint}/${id}/customers?${queryString}`)
    },

    stats(id: number) {
        return httpClientV2.get(`${endpoint}/${id}/stats`)
    },

    announcementStats(id: number) {
        return httpClientV2.get(`${endpoint}/${id}/announcement-stats`)
    }
   
}
