import { CarerHandholdingStatusEnum } from "@/enums/CarerHandholdingStatusEnum"

export function getHandholdingStatusBadge(
    status: string,
    isButton: boolean = false
) {
    const prefix = isButton ? "btn" : "bg"
    let postfix

    switch (status) {
        case CarerHandholdingStatusEnum.ACTIVE:
            postfix = "primary"
            break
        case CarerHandholdingStatusEnum.ON_HOLD:
            postfix = "info"
            break
        case CarerHandholdingStatusEnum.ON_HOLD:
            postfix = "yellow"
            break    
        case CarerHandholdingStatusEnum.COMPLETED:
            postfix = "lime"
            break
        default:
            postfix = "dark"
            break
    }

    return `${prefix}-${postfix}`
}

export function getHandholdingStatusLabel(status: string) {
    let label = "-"
    switch (status) {
        case CarerHandholdingStatusEnum.ACTIVE:
            label = "Active"
            break
        case CarerHandholdingStatusEnum.ON_HOLD:
            label = "On Hold"
            break
        case CarerHandholdingStatusEnum.INACTIVE:
            label = "Inactive"
            break
        case CarerHandholdingStatusEnum.COMPLETED:
            label = "Completed"
            break
        default:
            break
    }

    return label
}